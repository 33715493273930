<template>
  <div id="createCollector">
    <div class="o-modal">
      <div class="o-modal-content">
        <div class="o-modal-header">
          <a
            @click="closeCreateCollectorModal()"
            class="close clickable"
          ><i class="far fa-times"></i></a>
          <p class="lead">Create Data Collector</p>
        </div><!-- /.o-modal-header -->
        <div class="o-modal-body pt-2">
          <!-- IMPORT/EXPORT TEMPLATE -->
          <div class="mb-2 text-right">
            <b-btn
              v-if="!$store.state.display.editMode"
              size="sm"
              class="mr-1"
              @click="importMode = true"
              variant="dark"
            ><i class="fas fa-file-import"></i> Import Settings</b-btn>
            <b-btn
              v-if="collector.name"
              size="sm"
              @click="exportSettings()"
              variant="dark"
            ><i class="fas fa-file-export"></i> Export Settings</b-btn>
            <div
              v-if="importMode"
              class="bg-white p-1 mt-2 card"
            >
              <i @click="importMode = false" class="fas fa-times-circle clickable" style="position:absolute;top:5px;right:5px;"></i>
              <label class="text-left">Upload Import File:</label>
              <input
                type="file"
                class="form-control-file"
                accept=".txt"
                @change="onImportSelected"
                id="importFileInput"
              />
            </div><!-- if import mode -->
          </div><!-- /.text-right -->
          <!-- END TEMPLATE IMPORT/EXPORT -->
          <form @submit.prevent>

            <!-- NAME -->
            <div class="form-group">
              <label>What data are you collecting? (eg. Broken Posts)</label>
              <input
                v-model="collector.name"
                class="form-control"
              />
            </div><!-- /.form-group -->

            <!-- DESCTIPTION -->
            <div class="form-group">
              <label>Provide a description of this data type</label>
              <textarea
                v-model="collector.description"
                class="form-control"
              />
            </div><!-- /.form-group -->

            <div class="form-group">

              <label>Fields</label>
              <div class="list-group">
                <div
                  v-for="(field, i) in collector.fields"
                  v-bind:key="i"
                  class="list-group-item list-group-item-action clickable"
                  @click="editField(i)"
                >
                  <p class="lead mb-1">
                    <i v-if="field.type == 'Text'" class="fas fa-text"></i>
                    <i v-if="field.type == 'Formatted Text'" class="far fa-text-size"></i>
                    <i v-if="field.type == 'Number'" class="fas fa-hashtag"></i>
                    <i v-if="field.type == 'Select'" class="far fa-ballot-check"></i>
                    <i v-if="field.type == 'Multiselect'" class="fas fa-check-double"></i>
                    <i v-if="field.type == 'Image'" class="fas fa-image"></i>
                    &nbsp;{{ field.label }}
                  </p>
                </div>
              </div><!-- list-group -->
              <b-btn
                @click="addField()"
                class="mt-2"
                variant="dark"
              ><i class="far fa-plus"></i> Add a field</b-btn>
            </div><!-- /.form-group -->

          </form>
        </div><!-- /.o-modal-body -->
        <div class="o-modal-footer">
          <div class="float-right">
            <button
              @click="saveCollector()"
              class="btn btn-dark"
            >
              Save
            </button>
            <button
              v-if="$store.state.display.editMode"
              @click="confirmDelete = true"
              class="btn btn-danger ml-1"
            >
              Delete
            </button>
          </div><!-- /.float-right -->
        </div><!-- /.o-modal-footer -->
      </div><!-- /.o-modal-content -->
    </div><!-- /. o-modal -->

    <!-- ADD FIELD MODAL -->
    <AddField :editMode="editMode" :fieldIndex="fieldIndex" v-if="$store.state.display.modal.addField == true" />

    <Confirm
      v-if="confirmDelete"
      text="DANGER! Are you sure you want to delete this collector? This cannot be undone! NOTE: The dataset associated with this data collector will also be deleted. Consider backing that up first!"
      v-on:confirmed="deleteCollector($event)"
    />
  </div><!-- /#createCollector -->
</template>

<script>
import Confirm from '../Confirm'
import AddField from './AddField'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      confirmDelete: false,
      editMode: false,
      fieldIndex: false,
      importMode: false
    }
  },
  components: {
    AddField,
    Confirm
  },
  computed: {
    collector: {
      get () {
        return this.$store.state.collector
      },
      set (value) {
        this.$store.commit('setCollector', value)
      }
    }
  },
  methods: {
    onImportSelected (e) {
      const reader = new FileReader()
      reader.readAsText(e.target.files[0])
      reader.onload = e => {
        const fileContentsData = JSON.parse(e.target.result)
        this.collector.description = fileContentsData.description
        this.collector.fields = fileContentsData.fields
        this.collector.name = fileContentsData.name
        this.importMode = false
      }
    },
    exportSettings () {
      const settingsContent = {
        description: this.collector.description,
        fields: this.collector.fields,
        name: this.collector.name
      }
      function camelize (str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase()
        }).replace(/\s+/g, '')
      }
      const fileName = camelize(this.collector.name)
      const fileData = JSON.stringify(settingsContent)
      // Get the file contents
      const fileFullName = fileName + '_collector_template.txt'
      function downloadFile (content, fileName) {
        var a = document.createElement('a')
        var file = new Blob([content], { type: 'text/plain' })
        a.href = URL.createObjectURL(file)
        a.download = fileName
        a.click()
      }
      downloadFile(fileData, fileFullName)
    },
    deleteCollector (confirmed) {
      if (confirmed) {
        this.confirmDelete = false
        fb.collectorsCollection.doc(this.collector.id).delete().then(() => {
          this.$store.commit('setEditMode', false)
          this.$store.dispatch('initAtlasMap')
          this.$store.commit('resetDrawers')
          this.$store.dispatch('fetchCollectors', this.$store.state.atlas.id)
          this.$store.commit('toggleModal', 'createCollector')
          this.$store.commit('setAlert', { active: true, message: 'Data collector successfully deleted.', level: 'alert-dark', timeout: 3000 })
          fb.datasetsCollection.doc(this.collector.datasetId).delete()
        }).catch(err => { console.log(err) })
      } else {
        this.confirmDelete = false
        return false
      }
    },
    saveCollector () {
      if (!this.collector.name) {
        alert('You must name this data collector!')
        return false
      }
      if (this.$store.state.display.editMode === true) {
        fb.collectorsCollection.doc(this.collector.id).update({
          updatedBy: this.$store.state.currentUser.uid,
          updatedOn: new Date(),
          name: this.collector.name,
          description: this.collector.description || '',
          fields: this.collector.fields,
          reCompile: true
        }).then(collectorDoc => {
          const datasetHeaders = []
          this.collector.fields.forEach(field => {
            datasetHeaders.push(field.label)
          })
          // Update the dataset to keep in sync
          return fb.datasetsCollection.doc(this.collector.datasetId).update({
            dynamic: true,
            name: this.collector.name || '',
            description: this.collector.description || '',
            headers: datasetHeaders || []
          })
        }).then(() => {
          this.$store.commit('setEditMode', false)
          this.$store.dispatch('fetchCollectors', this.$store.state.atlas.id)
          this.$store.commit('toggleModal', 'createCollector')
        }).catch(err => {
          console.log(err)
        })
      } else {
        fb.collectorsCollection.add({
          createdOn: new Date(),
          updatedOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          updatedBy: this.$store.state.currentUser.uid,
          ownerId: this.$store.state.currentUser.uid,
          name: this.collector.name,
          description: this.collector.description || '',
          fields: this.collector.fields,
          atlasId: this.$store.state.atlas.id,
          trashed: false
        }).then(collectorDoc => {
          this.collector.id = collectorDoc.id
          const datasetHeaders = []
          this.collector.fields.forEach(field => {
            datasetHeaders.push(field.label)
          })
          // Create dynamic dataset
          return fb.datasetsCollection.add({
            dynamic: true,
            collectorId: collectorDoc.id,
            createdOn: new Date(),
            updatedOn: new Date(),
            createdBy: this.$store.state.currentUser.uid,
            name: this.collector.name || '',
            description: this.collector.description || '',
            headers: datasetHeaders || [],
            atlasId: this.$store.state.atlas.id,
            file: {},
            folderId: 'root'
          })
        }).then(datasetDoc => {
          fb.compileRequestsCollection.add({
            createdBy: this.$store.state.currentUser.uid,
            createdOn: new Date(),
            collectorId: this.collector.id,
            datasetId: datasetDoc.id,
            lastUpdated: new Date(),
            atlasId: this.$store.state.atlas.id
          })
          return fb.collectorsCollection.doc(this.collector.id).update({
            datasetId: datasetDoc.id
          })
        }).then(() => {
          this.$store.commit('setEditMode', false)
          this.$store.dispatch('fetchCollectors', this.$store.state.atlas.id)
          this.$store.commit('toggleModal', 'createCollector')
        }).catch(err => {
          console.log(err)
        })
      }
    },
    addField () {
      this.editMode = false
      this.fieldToEdit = false
      this.$store.commit('toggleModal', 'addField')
    },
    editField (i) {
      this.editMode = true
      this.fieldIndex = i
      this.$store.commit('toggleModal', 'addField')
    },
    closeCreateCollectorModal () {
      this.$store.commit('toggleModal', 'createCollector')
    }
  }
}
</script>
