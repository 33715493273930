<template>
  <div id="datasetList">
    <b-form>
      <!-- Sort Option -->
      <VueFuse
        v-if="showFuse"
        :fuseOpts="fuseOptions"
        :list="$store.state.datasetGeoJson.features"
        placeholder="Search"
        class="float-right text-center fuse-form"
        @fuse-results="handleResults"
      />
      <b-form-select
        v-model="sortBy"
        :options="sortOptions"
        size="sm"
        class="mb-3 mr-2 list-filter"
        @change="sortDatapoints()"
      ></b-form-select>
    </b-form>
    <div
      class="list-group sets"
      v-if="datapointResults.length > 0"
    >
      <div
        v-for="(datapoint, i) in datapointResults"
        v-bind:key="i"
        @mouseover="$store.dispatch('highlightFeature', datapoint)"
        @mouseleave="$store.dispatch('removeFeatureHighlight')"
        class="list-container"
      >
        <a
          @click="launchDatapoint(datapoint)"
          class="list-group-item list-group-item-action clickable handle mb-2"
        >
          <p class="lead mb-1"
            v-if="$store.state.dataset.vizSettings.primaryHeader"
          >
            <span
              v-if="datapoint.properties[$store.state.dataset.vizSettings.primaryHeader]"
            >
              {{ datapoint.properties[$store.state.dataset.vizSettings.primaryHeader] | cleanTitle }}
            </span>
          </p>
          <p class="mb-1"
            v-if="$store.state.dataset.vizSettings.secondaryHeader"
          >
            <span
              v-if="datapoint.properties[$store.state.dataset.vizSettings.secondaryHeader]"
            >
              {{ datapoint.properties[$store.state.dataset.vizSettings.secondaryHeader] | cleanTitle }}
            </span>
          </p>
          <p
            class="lead mb-1"
            v-if="!$store.state.dataset.vizSettings.primaryHeader && !$store.state.dataset.vizSettings.secondaryHeader"
          >{{ datapoint.properties[collector.fields[0].label] }}</p>
          <small>Updated: {{ datapoint.properties.updatedOn | formatDate }}</small><br />
          <small>ID: {{ datapoint.properties.dataPointId }}</small>
        </a>
      </div><!-- /.list-container -->
    </div><!-- /.list -->
  </div><!-- /#datasetList -->
</template>

<script>
import VueFuse from 'vue-fuse'
import moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      datapointResults: [],
      fuseOptions: {},
      showFuse: false,
      sortBy: 'Most Recent',
      sortOptions: [
        'Most Recent',
        'Oldest',
        'A-Z',
        'Z-A'
      ]
    }
  },
  computed: {
    collector: {
      get () {
        return this.$store.state.collector
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'collector', value: value })
      }
    }
  },
  components: {
    VueFuse
  },
  created () {
    const keysArray = []
    if (this.$store.state.dataset.vizSettings.primaryHeader) {
      keysArray.push('properties.' + this.$store.state.dataset.vizSettings.primaryHeader)
      if (this.$store.state.dataset.vizSettings.secondaryHeader) {
        keysArray.push('properties.' + this.$store.state.dataset.vizSettings.secondaryHeader)
      }
    } else {
      keysArray.push('properties.' + this.collector.fields[0].label)
    }
    this.fuseOptions = {
      keys: keysArray
    }
    this.datapointResults = JSON.parse(JSON.stringify(this.$store.state.datasetGeoJson.features))
    this.showFuse = true
    setTimeout(() => {
      this.sortDatapoints()
    }, 10)
  },
  methods: {
    sortDatapoints () {
      const primaryHeader = this.$store.state.dataset.vizSettings.primaryHeader
      if (this.sortBy === 'Most Recent') {
        this.datapointResults.sort(function (a, b) {
          var textA = a.properties.updatedOn._seconds
          var textB = b.properties.updatedOn._seconds
          return (textA > textB) ? -1 : (textA < textB) ? 1 : 0
        })
      }
      if (this.sortBy === 'Oldest') {
        this.datapointResults.sort(function (a, b) {
          var textA = a.properties.updatedOn._seconds
          var textB = b.properties.updatedOn._seconds
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
      }
      if (this.sortBy === 'A-Z') {
        this.datapointResults.sort(function (a, b) {
          var textA = a.properties[primaryHeader].toUpperCase()
          var textB = b.properties[primaryHeader].toUpperCase()
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
      }
      if (this.sortBy === 'Z-A') {
        this.datapointResults.sort(function (a, b) {
          var textA = a.properties[primaryHeader].toUpperCase()
          var textB = b.properties[primaryHeader].toUpperCase()
          return (textA > textB) ? -1 : (textA < textB) ? 1 : 0
        })
      }
    },
    launchDatapoint (datapoint) {
      const mapObject = this.$store.state.mapObject
      // HIGHLIGHT THE DATAPOINT
      if (typeof mapObject.getLayer('selectedDataPoint') !== 'undefined') {
        mapObject.removeLayer('selectedDataPoint')
        mapObject.removeLayer('selectedDataPointHighlight')
        mapObject.removeSource('selectedDataPoint')
      }
      // SOURCE FOR THE SELECTED POINT
      mapObject.addSource('selectedDataPoint', {
        type: 'geojson',
        data: datapoint
      })
      // DOT HIGHLIGHT
      mapObject.addLayer({
        id: 'selectedDataPointHighlight',
        type: 'circle',
        source: 'selectedDataPoint',
        paint: {
          'circle-radius': this.$store.state.dataset.vizSettings.circleRadius + 4,
          'circle-color': 'white'
        }
      })
      // RED DOT
      mapObject.addLayer({
        id: 'selectedDataPoint',
        type: 'circle',
        source: 'selectedDataPoint',
        paint: {
          'circle-radius': this.$store.state.dataset.vizSettings.circleRadius + 2,
          'circle-color': 'red'
        }
      })
      const dataPointId = datapoint.properties.dataPointId
      // PUT AWAY ANY DATAPOINT DRAWERS
      this.$store.commit('tuckDrawer', 'datasetDetails')
      setTimeout(() => {
        // HANDLE DYNAMIC DATASETS
        if (this.$store.state.dataset.dynamic) {
          fb.collectorsCollection.doc(this.$store.state.dataset.collectorId).collection('dataPoints').doc(dataPointId).get().then(doc => {
            const dataPoint = doc.data()
            dataPoint.id = doc.id
            return this.$store.commit('setStateProperty', { property: 'dataPoint', value: dataPoint })
          }).then(() => {
            this.$store.commit('showDrawer', 'dataPointDetails')
          })
        } else {
          // HANDLE STATIC DATASETS
          const datapointProperties = datapoint.properties
          // LIST OUT PROPERTIES ARRAY IN DATAPOINT
          datapointProperties.fields = Object.keys(datapoint)
          this.$store.commit('setStateProperty', { property: 'dataPoint', value: datapoint })
          this.$store.commit('showDrawer', 'dataPointDetails')
        }
      }, 300)
    },
    handleResults (r) {
      this.datapointResults = []
      r.forEach(result => {
        this.datapointResults.push(result.item)
      })
    }
  },
  filters: {
    cleanTitle (val) {
      val = val.replace(/<\/?[^>]+>/ig, ' ')
      if (val.length <= 35) {
        return val
      } else {
        return val.substring(0, 35) + '...'
      }
    },
    formatDate (val) {
      if (!val) { return '-' }
      var t = new Date(1970, 0, 1)
      t.setSeconds(val._seconds)
      return moment(t).fromNow()
    }
  }
}
</script>
