<template>
  <div id="atlasMenu">
    <div id="atlasTopMenu">

      <b-btn
        @click="$store.commit('showDrawer', 'atlasDetails')"
        variant="dark"
        id="atlasButton"
      ><i class="fas fa-atlas"></i><span id="atlasName"> {{ $store.state.atlas.name }}</span></b-btn>

    </div><!-- /#atlasTopMenu -->
    <b-btn
      v-if="!$store.state.drawer.createBlockDrawer.show && !$store.state.drawer.createAtlas.show && !$store.state.drawer.createDataset.show"
      v-b-tooltip.hover.left="'Refresh Atlas'"
      @click="refreshAtlas()"
      id="refreshAtlas"
      variant="dark"
    ><i class="far fa-redo-alt"></i></b-btn>
    <!-- Toggle in datasets button -->
    <b-btn
      id="datasetsToggle"
      @click="showDrawer('datasets')"
      v-b-tooltip.hover.right="'Datasets'"
      variant="dark"
    >
      <i class="fal fa-database"></i>
    </b-btn>

    <!-- Toggle in user details button -->
    <b-btn
      id="userDetailsToggle"
      @click="showDrawer('userDetails')"
      v-b-tooltip.hover.right="'User Settings'"
      variant="dark"
    >
      <i class="fas fa-user"></i>
    </b-btn>

    <!-- Toggle in collectors button -->
    <b-btn
      id="collectorsToggle"
      @click="showDrawer('collectors')"
      v-b-tooltip.hover.left="'Collect Data'"
      v-if="$store.state.role == 'Administrator' || $store.state.role == 'Editor'"
      variant="dark"
    >
      <i class="far fa-clipboard-check"></i>
    </b-btn>
  </div><!-- /#atlasMenu -->
</template>

<script>
// import CreateAtlas from './CreateAtlas'
// import Collaborators from './Collaborators'

export default {
  components: {
    // CreateAtlas,
    // Collaborators
  },
  methods: {
    refreshAtlas () {
      const pathToGo = '/a/' + this.$store.state.atlas.id
      if (window.location.pathname !== pathToGo) {
        this.$router.push('/a/' + this.$store.state.atlas.id)
      }
      this.$store.commit('setStateProperty', { property: 'showKey', value: false })
      this.$store.dispatch('initAtlasMap')
      this.$store.commit('resetDrawers')
      this.$store.commit('hideAllDrawers')
    },
    // createBlock () {
    //   this.$store.commit('setEditMode', false)
    //   this.$store.commit('toggleModal', 'createBlock')
    // },
    createBlock () {
      this.$store.commit('setEditMode', false)
      // this.$store.commit('resetDrawers')
      // this.$store.commit('setStateProperty', { property: 'showCreateBlockDrawer', value: true })
      // this.$store.commit('setDrawer', { name: 'createBlockDrawer', position: 'in' })
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createBlockDrawer')
      }, 300)
    },
    editAtlas (id) {
      this.$store.commit('setEditMode', true)
      this.$store.commit('toggleModal', 'createAtlas')
    },
    goToAtlas (id) {
      window.location.href = '/a/' + id
    },
    showDrawer (drawer) {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', drawer)
      }, 300)
    }
  }
}
</script>

<style scoped>
#refreshAtlas {
  position: fixed;
  right: 10px;
  top: 102px;
}

#datasetsToggle {
  position: fixed;
  left: 10px;
  top: 53px;
}
#atlasTopMenu {
  position: fixed;
  z-index: 2;
  left: 10px;
  top: 10px;
}
#userDetailsToggle {
  position: fixed;
  z-index: 1;
  left: 10px;
  bottom: 40px;
}
#collectorsToggle {
  position: fixed;
  z-index: 1;
  right: 10px;
  bottom: 40px;
}
@media(max-width:600px) {
  #atlasName {
    display: none;
  }
  /* #atlasButton {
    width: 44px;
    height: 44px;
  } */
  #refreshAtlas {
    position: fixed;
    right: 10px;
    top: 58px;
  }
}
</style>
