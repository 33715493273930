<template>
  <div id="key">
    <div v-if="collapsed">
      <span class="float-right clickable mr-2"><i @click="collapsed = false" class="fas fa-angle-up collapse-button"></i></span>
      <p @click="collapsed = false" class="clickable mb-0 text-center"><strong>Key</strong></p>
    </div>
    <div v-else>
      <span class="float-right clickable mr-2"><i @click="collapsed = true" class="fas fa-angle-down collapse-button"></i></span>
      <p
        v-if="!heatMap && $store.state.dataset.vizSettings && $store.state.dataset.vizSettings.colorBy === 'valueBased'"
        class="mb-0 text-center"
      ><strong>{{ $store.state.dataset.vizSettings.colorHeader }}</strong></p>
      <!-- POINT MAP CATEGORICAL -->
      <div
        v-if="mode === 'categorical' && !heatMap"
        class="color-dot"
      >
        <p
          v-for="(item, i) in items"
          v-bind:key="i"
          class="mb-0"
        ><i :style="'color:' + item.color +';'" class="fas fa-circle"></i> {{ item.label }}</p>
      </div>
      <!-- HEAT MAP -->
      <div
        v-if="heatMap"
        class="color-dot"
      >
        <p
          class="mb-0"
        ><span class="heat-dot"></span> Datapoints</p>
      </div>
      <!-- RAMP GRADIENT -->
      <div
        v-if="mode === 'ramp'"
        class="color-ramp"
      >
        <div
          class="color-ramp-bar"
          :style="'width:100%;background:linear-gradient(90deg, ' + items[0].color + ' 0%, ' + items[1].color + ' 100%);'"
        ></div>
        <p class="mb-0"><span class="mr-4">{{ minValue | toFixed }}</span>
        <span class="float-right">{{ maxValue | toFixed }}</span></p>
      </div>
      <!-- ZONES -->
      <!-- <div
        v-if="mode === 'zones'"
        class="color-bar"
      >
        <div
          v-for="(item, i) in items"
          v-bind:key="i"
          class="color-bar-item"
          :style="'width:' + item.percent + '%;'"
        >
          <div class="color-bar-item-color" :style="'background-color:' + item.color +';'"></div>
          <p class="text-center ml-1 mr-1">{{ item.label }}<br><small>{{ item.min | toFixedSmall }}<br>{{ item.max | toFixedSmall }}</small></p>
        </div>
      </div> -->

      <div v-if="mode === 'zones'">
        <b-table responsive :items="items" :fields="fields">
          <template #cell(color)="data">
            <div class="dot" :style="'background-color:' + data.value +';'"></div>
          </template>
          <template #cell(min)="data">
            <span>{{ data.value | toFixedSmall }}</span>
          </template>
          <template #cell(max)="data">
            <span>{{ data.value | toFixedSmall }}</span>
          </template>
          <template #cell(area)="data">
            <span v-if="$store.state.userProfile.measurementSystem === 'Metric'">{{ data.value | toHectares }} hectares</span>
            <span v-if="$store.state.userProfile.measurementSystem === 'Imperial'">{{ data.value | toAcres }} acres</span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
const turf = require('@turf/turf')

export default {
  data () {
    return {
      items: [],
      fields: [
        {
          key: 'color',
          label: ''
        },
        {
          key: 'label',
          label: 'Zone'
        },
        {
          key: 'min',
          label: 'Min'
        },
        {
          key: 'max',
          label: 'Max'
        },
        {
          key: 'area',
          label: 'Area*'
        }
      ],
      mode: 'categorical',
      heatMap: false,
      minValue: 0,
      maxValue: 0,
      collapsed: false
    }
  },
  created () {
    if (this.$store.state.keyMode === 'blocks') {
      if (this.$store.state.farm.vizSettings) {
        const vizSettings = this.$store.state.farm.vizSettings
        console.log(vizSettings)
        if (vizSettings.colorBy === 'solid') {
          this.items.push({
            color: vizSettings.blockColor,
            percent: 100,
            label: 'Blocks'
          })
        } else if (vizSettings.colorBy === 'valueBased') {
          if (vizSettings.colorHeader.type === 'Select') {
            vizSettings.colorHeader.options.forEach((category, i) => {
              this.items.push({
                color: vizSettings.colors[i],
                label: category
              })
            })
          } else if (vizSettings.zoneClassification === 'linear') {
            this.items.push({
              color: vizSettings.minColor,
              label: 'Min'
            },
            {
              color: vizSettings.maxColor,
              label: 'Max'
            })
            this.minValue = vizSettings.minValue
            this.maxValue = vizSettings.maxValue
            this.mode = 'ramp'
          } else if (vizSettings.zoneClassification === 'equal-interval' || vizSettings.zoneClassification === 'jenks') {
            for (let i = 0; i < vizSettings.zoneStops; i++) {
              const itemObj = {
                color: vizSettings.colors[i],
                percent: 100 / vizSettings.zoneStops,
                label: 'Z' + (i + 1)
              }
              if (vizSettings.zoneClassification === 'equal-interval') {
                const stopDiff = vizSettings.maxValue - vizSettings.minValue
                const stopLength = 1 / vizSettings.zoneStops
                const stopMultiplierMin = stopLength * i
                const stopMultiplierMax = stopLength * (i + 1)
                itemObj.min = (stopDiff * stopMultiplierMin) + vizSettings.minValue
                itemObj.max = (stopDiff * stopMultiplierMax) + vizSettings.minValue
              }
              if (vizSettings.zoneClassification === 'jenks') {
                itemObj.min = vizSettings.breaks[i]
                itemObj.max = vizSettings.breaks[i + 1]
              }
              this.items.push(itemObj)
            }
            this.mode = 'zones'
          }
        }
      } else {
        this.items.push({
          color: '#6356a8',
          percent: 100,
          label: 'Blocks'
        })
      }
    } else if (this.$store.state.keyMode === 'dataset') {
      const vizSettings = this.$store.state.dataset.vizSettings
      if (vizSettings.type === 'heatMap') {
        this.heatMap = true
      } else {
        if (vizSettings.colorBy === 'solid') {
          this.items.push(
            {
              color: vizSettings.circleColor,
              percent: 100,
              label: 'Datapoints'
            }
          )
        } else if (vizSettings.colorBy === 'valueBased') {
          if (vizSettings.categorical) {
            vizSettings.featureCategories.forEach(category => {
              this.items.push({
                color: category.color,
                label: category.name
              })
            })
          } else if (vizSettings.zoneClassification === 'linear') {
            console.log('linear viz')
            this.minValue = vizSettings.minValue
            this.maxValue = vizSettings.maxValue
            this.items.push(
              {
                color: vizSettings.minColor,
                label: 'Min'
              },
              {
                color: vizSettings.maxColor,
                label: 'Max'
              }
            )
            this.mode = 'ramp'
          } else if (vizSettings.zoneClassification === 'equal-interval' || vizSettings.zoneClassification === 'jenks') {
            // Calculate total area of dataset
            const hull = turf.concave(this.$store.state.datasetGeoJson, { units: 'kilometers', maxEdge: 0.05 })
            const area = turf.area(hull)
            const totalPointsCount = this.$store.state.datasetGeoJson.features.length

            for (let i = 0; i < vizSettings.zoneStops; i++) {
              const itemObj = {
                color: vizSettings.colors[i],
                percent: 100 / vizSettings.zoneStops,
                label: i + 1
              }
              if (vizSettings.zoneClassification === 'equal-interval') {
                const stopDiff = vizSettings.maxValue - vizSettings.minValue
                const stopLength = 1 / vizSettings.zoneStops
                const stopMultiplierMin = stopLength * i
                const stopMultiplierMax = stopLength * (i + 1)
                itemObj.min = (stopDiff * stopMultiplierMin) + vizSettings.minValue
                itemObj.max = (stopDiff * stopMultiplierMax) + vizSettings.minValue
              }
              if (vizSettings.zoneClassification === 'jenks') {
                itemObj.min = vizSettings.breaks[i]
                itemObj.max = vizSettings.breaks[i + 1]
              }
              // CALCULATE AREA OF THIS ZONE
              // Create geojson with only features in this zone
              // const zoneGeojson = {
              //   type: 'FeatureCollection',
              //   features: []
              // }
              var zonePointsCount = 0
              this.$store.state.datasetGeoJson.features.forEach((feature, i) => {
                // Note this small issues: the highest value in the dataset will never be counted toward the convace hull using this if statement
                if (feature.properties[this.$store.state.dataset.vizSettings.colorHeader] >= itemObj.min && feature.properties[this.$store.state.dataset.vizSettings.colorHeader] < itemObj.max) {
                  zonePointsCount++
                }
              })
              const countPercent = zonePointsCount / totalPointsCount
              const zoneArea = area * countPercent
              // // Create a convcave hull around that geojson
              // const hull = turf.concave(zoneGeojson, { units: 'kilometers', maxEdge: 0.0091 })
              // // Calculate the area of that concave hull
              // let area = 0
              // if (hull) {
              //   area = turf.area(hull)
              // }
              itemObj.area = zoneArea
              this.items.push(itemObj)
            }
            // var areaTotal = 0
            // this.items.forEach((item, i) => {
            //   areaTotal = areaTotal + item.area
            // })
            // const acres = areaTotal * 0.000247105
            // const rounded = acres.toFixed(2)
            // if (this.$store.state.mapObject.getLayer('hullLayer')) {
            //   this.$store.state.mapObject.removeLayer('hullLayer')
            //   this.$store.state.mapObject.removeSource('hullLayer')
            // }
            // this.$store.state.mapObject.addLayer({
            //   id: 'hullLayer',
            //   type: 'fill',
            //   source: {
            //     type: 'geojson',
            //     data: hull
            //   },
            //   layout: {},
            //   paint: {
            //     'fill-color': '#6e59c7', // eslint-disable-line
            //     'fill-opacity': 0.8, // eslint-disable-line
            //   }
            // })
            // console.log(rounded)
            this.mode = 'zones'
          }
        }
      }
    }
  },
  filters: {
    toFixed (val) {
      function countDecimals (num) {
        if (Math.floor(num.valueOf()) === num.valueOf()) return 0
        return num.toString().split('.')[1].length || 0
      }
      if (val.isInteger) {
        return val
      } else {
        var decCount = countDecimals(val)
        if (decCount > 6) {
          return val.toFixed(6) + '...'
        } else {
          return val
        }
      }
    },
    toFixedSmall (val) {
      function countDecimals (num) {
        if (Math.floor(num.valueOf()) === num.valueOf()) return 0
        return num.toString().split('.')[1].length || 0
      }
      if (val.isInteger) {
        return val
      } else {
        var decCount = countDecimals(val)
        if (decCount > 5) {
          return val.toFixed(5) + '...'
        } else {
          return val
        }
      }
    },
    toAcres (val) {
      const acres = val * 0.000247105
      const rounded = acres.toFixed(2)
      return rounded
    },
    toHectares (val) {
      const hectares = val / 10000
      const rounded = hectares.toFixed(2)
      return rounded
    }
  }
}
</script>

<style scoped>
#key {
  position: absolute;
  bottom: 40px;
  right: 70px;
  max-width: 500px;
  background-color: #eee;
  border-radius: 4px;
  padding: 2px 4px;
  box-shadow: 1px 3px 6px rgba(0,0,0,0.3);
  min-width: 100px;
}

.collapse-button {
  font-size: 20px;
}

#key p {
  font-size: 14px;
}

span.heat-dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  background: radial-gradient(red, lightblue, white);
  margin-bottom: -3px;
}

.color-dot {
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 12px;
}

.color-ramp {
  position: relative;
  min-width: 100px;
  margin-top: -6px;
}

.color-ramp-bar {
  display: inline-block;
  height: 10px;
  color: #eee;
  text-align: center;
  border-radius: 20px;
}

.color-bar {
  position: relative;
  width: 100%;
}

.color-bar-item {
  display: inline-block;
  height: 20px;
  text-align: center;
  font-size: 12px;
  padding-right: 2px;
}

.color-bar-item-color {
  width: 100%;
  height: 10px;
  border-radius: 10px;
}

.dot {
  width: 18px;
  height: 18px;
  border-radius: 30px;
}

@media(max-width:800px) {
  #key {
    position: absolute;
    bottom: 60px;
    right: 65px;
    max-width: 250px;
  }
}
</style>
