<template>
  <div id="#datasetVizSettings" class="mb-5" v-if="dataset.vizSettings">
    <div v-if="dataset.dynamic">
      <p
        v-if="dataset.headers"
        class="mb-0 mt-4"
      ><strong>Primary Header</strong><br><small>Displayed when previewing datapoints.</small></p>
      <div
        v-for="(header, index) in dataset.headers"
        v-bind:key="index"
        class="btn btn-dark mt-1 mr-1 clickable"
        @click="setPrimaryHeader(header)"
        :class="{'selected':(header === dataset.vizSettings.primaryHeader)}"
      >
        {{ header }}
      </div>
      <div
        class="btn btn-dark mt-1 mr-1 clickable"
        @click="setPrimaryHeader(false)"
        :class="{'selected':!dataset.vizSettings.primaryHeader}"
      >
        None
      </div>
      <div v-if="dataset.vizSettings.primaryHeader">
        <p
          v-if="dataset.headers"
          class="mb-0 mt-4"
        ><strong>Secondary Header</strong><br><small>Another value displayed when previewing datapoints.</small></p>
        <div
          v-for="(header, index) in dataset.headers"
          v-bind:key="index + header"
          class="btn btn-dark mt-1 mr-1 clickable"
          @click="setSecondaryHeader(header)"
          :class="{'selected':(header === dataset.vizSettings.secondaryHeader)}"
        >
          {{ header }}
        </div>
        <div
          class="btn btn-dark mt-1 mr-1 clickable"
          @click="setSecondaryHeader(false)"
          :class="{'selected':!dataset.vizSettings.secondaryHeader}"
        >
          None
        </div>
      </div>
    </div><!-- /v-if dataset.dynamic -->

    <p class="mb-1 mt-4"><strong>Map Type</strong></p>

    <b-btn
      @click="changeVizType('circle')"
      variant="dark"
      :class="{ selected: dataset.vizSettings.type == 'circle' }"
    >
      Point
    </b-btn>
    <b-btn
      @click="changeVizType('polygon')"
      variant="dark"
      :class="{ selected: dataset.vizSettings.type == 'polygon' }"
      class="ml-1"
    >
      Polygon
    </b-btn>
    <b-btn
      @click="changeVizType('heatMap')"
      variant="dark"
      :class="{ selected: dataset.vizSettings.type == 'heatMap' }"
      class="ml-1"
    >
      Heat Map
    </b-btn>

    <div v-if="dataset.vizSettings.type == 'circle' || dataset.vizSettings.type == 'polygon'">
      <!-- <p class="mb-0 mt-4"><strong>Clustering</strong></p>
      <b-form-group>
        <b-form-radio
          v-on:change="changeColorBy()"
          v-model="dataset.vizSettings.cluster"
          name="clustering"
          :value="true"
        >On</b-form-radio>
        <b-form-radio
          v-on:change="changeColorBy()"
          v-model="dataset.vizSettings.cluster"
          name="clustering"
          :value="false"
        >Off</b-form-radio>
      </b-form-group> -->
      <p class="mb-0 mt-4"><strong>Color Setting</strong></p>
      <b-form-group>
        <b-form-radio
          v-on:change="changeColorBy()"
          v-model="dataset.vizSettings.colorBy"
          name="color-points-by"
          value="solid"
        >Solid</b-form-radio>
        <b-form-radio
          v-on:change="changeColorBy()"
          v-model="dataset.vizSettings.colorBy"
          name="color-points-by"
          value="valueBased"
        >Value Based</b-form-radio>
      </b-form-group>
      <!-- SOLID COLOR SETTINGS -->
      <p v-if="dataset.vizSettings.colorBy == 'solid'" class="mb-0 mt-4"><strong>Color</strong></p>
      <v-swatches
        swatches="text-advanced"
        v-model="dataset.vizSettings.circleColor"
        popover-x="left"
        v-on:input="initViz()"
        v-if="dataset.vizSettings.colorBy == 'solid'"
        v-on:open="preventSave = true"
        v-on:close="closeSwatch()"
      ></v-swatches>
      <!-- VALUE BASED COLOR SETTINGS -->
      <div v-if="dataset.vizSettings.colorBy == 'valueBased'">
        <p
          v-if="dataset.headers"
          class="mb-0 mt-4"
        ><strong>Color by Header</strong></p>
        <div
          v-for="(header, index) in dataset.headers"
          v-bind:key="index"
          class="btn btn-sm btn-dark mt-1 mr-1 clickable"
          @click="setHeader(header)"
          :class="{'selected':(header === dataset.vizSettings.colorHeader)}"
        >
          {{ header }}
        </div>
        <!-- Number Ramp Min/Max Colors -->
        <div v-if="dataset.vizSettings.number">

          <!-- ZONE SETTINGS -->
          <p class="mb-0 mt-4"><strong>Ramp Settings</strong></p>
          <div class="form-row mt-2 ml-1">
            <b-form-group id="zones-type" class="mr-1">
              <b-form-select
                id="classification"
                v-model="dataset.vizSettings.zoneClassification"
                :options="classifications"
                @change="updateZoneSettings()"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group
              v-if="dataset.vizSettings.zoneClassification != 'linear' && dataset.vizSettings.zoneClassification != 'categorical'"
              id="stops-group"
            >
              <b-form-select
                id="stops"
                v-model="dataset.vizSettings.zoneStops"
                :options="zones"
                @change="updateZoneSettings()"
                required
              ></b-form-select>
            </b-form-group>
          </div><!-- /.form-row -->
          <p
            v-if="dataset.vizSettings.zoneClassification == 'linear'"
            class="mb-1 mt-2"
          ><strong>Min/Max Colors</strong></p>
          <!-- MIN COLOR SETTING -->
          <v-swatches
            swatches="text-advanced"
            v-if="dataset.vizSettings.zoneClassification == 'linear'"
            v-model="dataset.vizSettings.minColor"
            popover-x="left"
            v-on:input="initViz()"
            v-on:open="preventSave = true"
            v-on:close="closeSwatch()"
            class="mr-1"
          ></v-swatches>
          <!-- MAX COLOR SETTING -->
          <v-swatches
            swatches="text-advanced"
            v-if="dataset.vizSettings.zoneClassification == 'linear'"
            v-model="dataset.vizSettings.maxColor"
            popover-x="left"
            v-on:input="initViz()"
            v-on:open="preventSave = true"
            v-on:close="closeSwatch()"
          ></v-swatches>
          <div v-if="dataset.vizSettings.zoneClassification != 'categorical'">
            <!-- COLOR ZONES RAMP SWATCHES -->
            <!-- THREE ZONE -->
            <p
              v-if="dataset.vizSettings.zoneClassification != 'linear'"
              class="mb-0"
            ><strong>Zone Colors</strong></p>
            <div
              v-if="dataset.vizSettings.zoneClassification != 'linear'"
              class="row"
            >
              <div
                class="col-4"
                v-for="(color, i) in dataset.vizSettings.colors.slice(0, dataset.vizSettings.zoneStops)"
                v-bind:key="i"
              >
                <p class="mb-0">Zone {{ i + 1 }}</p>
                <v-swatches
                  swatches="text-advanced"
                  v-model="dataset.vizSettings.colors[i]"
                  popover-x="left"
                  v-on:input="changeJenksColors()"
                  v-on:open="preventSave = true"
                  v-on:close="closeSwatch()"
                ></v-swatches>
              </div><!-- /.col-4 -->
            </div><!-- /.row -->
          </div><!-- Number Ramp Colors -->
        </div>
        <div v-if="dataset.vizSettings.categorical && dataset.vizSettings.colorHeader">
          <p class="mb-0 mt-4"><strong>Category Colors</strong></p>
          <div class="row">
            <div
              class="col-4"
              v-for="(category, i) in dataset.vizSettings.featureCategories"
              v-bind:key="i"
            >
              <p class="mb-0 mt-1">{{ category.name }}</p>
              <v-swatches
                swatches="text-advanced"
                v-model="dataset.vizSettings.colors[i]"
                popover-x="left"
                v-on:input="changeColorBy()"
                v-on:open="preventSave = true"
                v-on:close="closeSwatch()"
              ></v-swatches>
            </div><!-- /.col-4 -->
          </div><!-- /.row -->
        </div><!-- Categorical Colors -->
      </div><!-- /Check if value based -->
      <div v-if="dataset.vizSettings.type !== 'polygon'">
        <p class="mb-0 mt-4"><strong>Point Size Setting</strong></p>
        <b-form-group>
          <b-form-radio
            v-on:change="changeSizeBy()"
            v-model="dataset.vizSettings.sizeBy"
            name="size-points-by"
            value="fixed"
          >Fixed</b-form-radio>
          <b-form-radio
            v-on:change="changeSizeBy()"
            v-model="dataset.vizSettings.sizeBy"
            name="size-points-by"
            value="valueBased"
          >Value Based</b-form-radio>
        </b-form-group>
        <p
          v-if="dataset.vizSettings.sizeBy == 'fixed'"
          class="mb-2 mt-4"
        ><strong>Point Size:</strong> {{ dataset.vizSettings.circleRadius }}</p>
        <vue-slider
          :height="6"
          :tooltip="'none'"
          :dotSize="30"
          v-if="dataset.vizSettings.sizeBy == 'fixed'"
          v-on:drag-end="initViz()"
          v-model="dataset.vizSettings.circleRadius"
          :min="1"
          :max="50"
        />
        <div v-if="dataset.vizSettings.sizeBy == 'valueBased'">
          <p
            v-if="dataset.headers"
            class="mb-0 mt-4"
          ><strong>Size by Header</strong></p>
          <div
            v-for="(header, index) in dataset.headers"
            v-bind:key="index"
            class="btn btn-sm btn-dark mt-1 mr-1 clickable"
            @click="setSizeHeader(header)"
            :class="{'active':(header === dataset.vizSettings.sizeHeader)}"
          >
            {{ header }}
          </div>
          <p
            v-if="dataset.vizSettings.sizeCategorical"
            style="color:red;"
          >Only numerical headers can set sizes of points!</p>
          <p class="mb-2 mt-4"><strong>Min Point Size:</strong> {{ dataset.vizSettings.circleRadiusMin }}</p>
          <vue-slider
            :height="6"
            :tooltip="'none'"
            :dotSize="30"
            v-on:drag-end="initViz()"
            v-model="dataset.vizSettings.circleRadiusMin"
            :min="1"
            :max="dataset.vizSettings.circleRadiusMax"
          />

          <p class="mb-2 mt-4"><strong>Max Point Size:</strong> {{ dataset.vizSettings.circleRadiusMax }}</p>
          <vue-slider
            :height="6"
            :tooltip="'none'"
            :dotSize="30"
            v-on:drag-end="initViz()"
            v-model="dataset.vizSettings.circleRadiusMax"
            :min="dataset.vizSettings.circleRadiusMin"
            :max="50"
          />
        </div>
      </div><!-- /. if not polygon map -->
    </div><!-- if circle map -->
    <div v-if="dataset.vizSettings.type == 'heatMap'">
      <p class="mb-0 mt-2"><strong>Heat Setting</strong></p>
      <b-form-group>
        <b-form-radio
          v-on:change="changeColorBy()"
          v-model="dataset.vizSettings.heatBy"
          name="heat-by"
          value="standard"
        >Standard</b-form-radio>
        <b-form-radio
          v-on:change="changeColorBy()"
          v-model="dataset.vizSettings.heatBy"
          name="heat-by"
          value="weighted"
        >Weighted by Value</b-form-radio>
      </b-form-group>
      <!-- SETTINGS FOR WEIGHTED HEATMAPS -->
      <div v-if="dataset.vizSettings.heatBy == 'weighted'">
        <p
          v-if="dataset.headers"
          class="mb-0 mt-4"
        ><strong>Header</strong></p>
        <div
          v-for="(header, index) in dataset.headers"
          v-bind:key="index"
          class="btn btn-sm btn-dark mt-1 mr-1 clickable"
          @click="setHeader(header)"
          :class="{'active':(header === dataset.vizSettings.colorHeader)}"
        >
          {{ header }}
        </div>
        <p
          v-if="dataset.vizSettings.categorical"
          style="color:red;"
        >Only numerical headers can weight a heat map!</p>
      </div><!-- if heatBy is weighted -->
      <p class="mb-0 mt-2"><strong>Heat Radius:</strong> {{ dataset.vizSettings.heatRadius }}</p>
      <vue-slider
        :height="6"
        :tooltip="'none'"
        :dotSize="30"
        v-on:drag-end="initViz()"
        v-model="dataset.vizSettings.heatRadius"
        :min="1"
        :max="50"
      />
    </div><!-- if heat map -->
    <DatasetFilters
      v-on:initViz="initViz()"
      v-on:saveViz="saveViz()"
    />
    <div class="mobile-buffer"></div>
  </div><!-- /#datasetVizSettings -->
</template>

<script>
import VSwatches from 'vue-swatches'
import VueSlider from 'vue-slider-component'
import jenks from 'turf-jenks'
import DatasetFilters from './DatasetFilters'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      swatches: {
        complimentary: ['#F76A6A', '#AD2F2F', '#6AF792', '#87FFA9', '#38AB59']
      },
      preventSave: false,
      defaultViz: {
        type: 'circle',
        breaks: [],
        categorical: false,
        circleRadius: 4,
        circleRadiusMin: 5,
        circleRadiusMax: 20,
        circleColor: 'orange',
        colorHeader: false,
        colorBy: 'solid',
        colors: [
          '#77CCCC',
          '#777711',
          '#AA7744',
          '#AAAA44',
          '#774411',
          '#4477AA',
          '#AA4455',
          '#771155',
          '#DDDD77',
          '#117744',
          '#DDAA77',
          '#CC99BB',
          '#44AAAA',
          '#117777',
          '#77AADD',
          '#88CCAA',
          '#DD7788',
          '#114477',
          '#AA4488',
          '#771122',
          '#44AA77',
          '#77CCCC',
          '#777711',
          '#AA7744',
          '#AAAA44',
          '#774411',
          '#4477AA',
          '#AA4455',
          '#771155',
          '#DDDD77',
          '#117744',
          '#DDAA77',
          '#CC99BB',
          '#44AAAA',
          '#117777',
          '#77AADD',
          '#88CCAA',
          '#DD7788',
          '#114477',
          '#AA4488',
          '#771122',
          '#44AA77',
          '#77CCCC',
          '#777711',
          '#AA7744',
          '#AAAA44',
          '#774411',
          '#4477AA',
          '#AA4455',
          '#771155',
          '#DDDD77',
          '#117744',
          '#DDAA77',
          '#CC99BB',
          '#44AAAA',
          '#117777',
          '#77AADD',
          '#88CCAA',
          '#DD7788',
          '#114477',
          '#AA4488',
          '#771122',
          '#44AA77',
          '#77CCCC',
          '#777711',
          '#AA7744',
          '#AAAA44',
          '#774411',
          '#4477AA',
          '#AA4455',
          '#771155',
          '#DDDD77',
          '#117744',
          '#DDAA77',
          '#CC99BB',
          '#44AAAA',
          '#117777',
          '#77AADD',
          '#88CCAA',
          '#DD7788',
          '#114477',
          '#AA4488',
          '#771122',
          '#44AA77',
          '#77CCCC',
          '#777711',
          '#AA7744',
          '#AAAA44',
          '#774411',
          '#4477AA',
          '#AA4455',
          '#771155',
          '#DDDD77',
          '#117744',
          '#DDAA77',
          '#CC99BB',
          '#44AAAA',
          '#117777',
          '#77AADD',
          '#88CCAA',
          '#DD7788',
          '#114477',
          '#AA4488',
          '#771122',
          '#44AA77',
          '#77CCCC',
          '#777711',
          '#AA7744',
          '#AAAA44',
          '#774411',
          '#4477AA',
          '#AA4455',
          '#771155',
          '#DDDD77',
          '#117744',
          '#DDAA77',
          '#CC99BB',
          '#44AAAA',
          '#117777',
          '#77AADD',
          '#88CCAA',
          '#DD7788',
          '#114477',
          '#AA4488',
          '#771122',
          '#44AA77',
          '#77CCCC',
          '#777711',
          '#AA7744',
          '#AAAA44',
          '#774411',
          '#4477AA',
          '#AA4455',
          '#771155',
          '#DDDD77',
          '#117744',
          '#DDAA77',
          '#CC99BB',
          '#44AAAA',
          '#117777',
          '#77AADD',
          '#88CCAA',
          '#DD7788',
          '#114477',
          '#AA4488',
          '#771122',
          '#44AA77',
          '#77CCCC',
          '#777711',
          '#AA7744',
          '#AAAA44',
          '#774411',
          '#4477AA',
          '#AA4455',
          '#771155',
          '#DDDD77',
          '#117744',
          '#DDAA77',
          '#CC99BB',
          '#44AAAA',
          '#117777',
          '#77AADD',
          '#88CCAA',
          '#DD7788',
          '#114477',
          '#AA4488',
          '#771122',
          '#44AA77'
        ],
        featureCategories: [],
        filters: [],
        heatBy: 'standard',
        heatRadius: 30,
        minValue: false,
        maxValue: false,
        minSizeValue: false,
        maxSizeValue: false,
        minColor: 'white',
        maxColor: 'black',
        number: true,
        primaryHeader: '',
        secondaryHeader: '',
        recalculateRange: false,
        recalculateJenks: true,
        zoneClassification: 'linear',
        zoneStops: 5,
        sizeCategorical: false,
        sizeNumber: true,
        sizeHeader: false,
        sizeBy: 'fixed'
      },
      zones: [
        {
          text: '3 Zones',
          value: 3
        },
        {
          text: '4 Zones',
          value: 4
        },
        {
          text: '5 Zones',
          value: 5
        },
        {
          text: '6 Zones',
          value: 6
        },
        {
          text: '7 Zones',
          value: 7
        },
        {
          text: '8 Zones',
          value: 8
        },
        {
          text: '9 Zones',
          value: 9
        },
        {
          text: '10 Zones',
          value: 10
        }
      ],
      classifications: [
        {
          text: 'Linear',
          value: 'linear'
        },
        {
          text: 'Equal interval',
          value: 'equal-interval'
        },
        {
          text: 'Jenks',
          value: 'jenks'
        },
        {
          text: 'Categorical',
          value: 'categorical'
        }
      ]
    }
  },
  components: {
    VSwatches,
    VueSlider,
    DatasetFilters
  },
  computed: {
    dataset: {
      get () {
        return this.$store.state.dataset
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'dataset', value: value })
      }
    },
    mapObject: {
      get () {
        return this.$store.state.mapObject
      },
      set (value) {
        this.$store.commit('setMapObject', value)
      }
    }
  },
  created () {
    // Check that default viz properties all exist
    if (!this.dataset.vizSettings) {
      this.dataset.vizSettings = this.defaultViz
      // Check if we're dealing with a polygon layer and update vizSettings accordingly
      if (this.$store.state.datasetGeoJson.features[0].geometry.type === 'Polygon') {
        this.dataset.vizSettings.type = 'polygon'
      }
    } else {
      const defaultKeys = Object.keys(this.defaultViz)
      defaultKeys.forEach((key, i) => {
        if (this.dataset.vizSettings[key] === 'undefined' || (!this.dataset.vizSettings[key] && this.dataset.vizSettings[key !== false])) {
          this.dataset.vizSettings[key] = this.defaultViz[key]
        }
      })
    }
    if (this.dataset.vizSettings.recalculateJenks) {
      this.dataset.vizSettings.recalculateJenks = false
    }
    setTimeout(() => {
      this.initViz()
      this.$store.commit('resetKey', 'dataset')
    }, 1000)
  },
  methods: {
    setSecondaryHeader (header) {
      this.dataset.vizSettings.secondaryHeader = header
      this.initViz()
    },
    setPrimaryHeader (header) {
      if (!header) {
        this.dataset.vizSettings.secondaryHeader = false
      }
      this.dataset.vizSettings.primaryHeader = header
      this.initViz()
    },
    setSizeHeader (header) {
      this.dataset.vizSettings.recalculateRange = true
      this.dataset.vizSettings.sizeHeader = header
      this.initViz()
    },
    changeSizeBy () {
      const self = this
      setTimeout(function () {
        self.initViz()
      }, 100)
    },
    updateZoneSettings () {
      this.dataset.vizSettings.recalculateJenks = true
      const self = this
      setTimeout(function () {
        self.initViz()
      }, 100)
    },
    changeJenksColors () {
      this.dataset.vizSettings.recalculateJenks = false
      const self = this
      setTimeout(function () {
        self.initViz()
      }, 100)
    },
    closeSwatch () {
      this.preventSave = false
    },
    changeColorBy () {
      // Timeout lets the colorBy and heatBy values 'set' first by the radios which seem to
      // take their dandy old time
      const self = this
      setTimeout(function () {
        self.initViz()
      }, 100)
    },
    setHeader (header) {
      this.dataset.vizSettings.recalculateRange = true
      this.dataset.vizSettings.recalculateJenks = true
      this.dataset.vizSettings.colorHeader = header
      this.initViz()
    },
    changeVizType (type) {
      if (this.dataset.vizSettings.type === type) {
      } else {
        this.dataset.vizSettings.type = type
        this.initViz()
      }
    },
    saveViz () {
      this.$store.commit('setMapLoader', false)
      if (this.preventSave || this.$store.state.role !== 'Administrator') {
      } else {
        fb.datasetsCollection.doc(this.dataset.id).update({
          vizSettings: this.dataset.vizSettings
        }).then(() => {
          this.$store.commit('resetKey', 'dataset')
        }).catch(err => {
          console.log(err)
        })
      }
    },
    initViz () {
      this.$store.commit('setMapLoader', true)
      setTimeout(() => {
        if (this.mapObject.getLayer('datasetPreviewHeatMap')) {
          this.mapObject.removeLayer('datasetPreviewHeatMap')
        }
        if (this.mapObject.getLayer('datasetPreviewPolygon')) {
          this.mapObject.removeLayer('datasetPreviewPolygon')
        }
        const colorHeader = this.dataset.vizSettings.colorHeader
        let radius
        let color
        this.dataset.vizSettings.featureCategories = []
        // What type of Viz
        if (this.dataset.vizSettings.type === 'circle' || this.dataset.vizSettings.type === 'polygon') {
          if (this.dataset.vizSettings.type === 'circle') {
            this.mapObject.setLayoutProperty('datasetPreview', 'visibility', 'visible')
          } else if (this.dataset.vizSettings.type === 'polygon') {
            this.mapObject.setLayoutProperty('datasetPreview', 'visibility', 'none')
            this.mapObject.addLayer({
              id: 'datasetPreviewPolygon',
              type: 'fill',
              source: 'datasetPreviewGeojson',
              // 'layout': {},
              paint: {
                'fill-outline-color': 'white',
                'fill-opacity': 0.8
              }
            })
          }
          // Color by setting
          if (this.dataset.vizSettings.colorBy === 'solid') {
            color = this.dataset.vizSettings.circleColor
            if (this.dataset.vizSettings.type === 'circle') {
              // color = this.dataset.vizSettings.circleColor
              this.mapObject.setPaintProperty('datasetPreview', 'circle-color', color)
            } else if (this.dataset.vizSettings.type === 'polygon') {
              this.mapObject.setPaintProperty('datasetPreviewPolygon', 'fill-color', color)
            }
          }
          // Value Based settings assume that the header is numbers
          if (this.dataset.vizSettings.colorBy === 'valueBased') {
            // Range includes the min and max for numbers as well as categories
            this.dataset.vizSettings.minValue = false
            this.dataset.vizSettings.maxValue = false
            this.dataset.vizSettings.categorical = false
            this.dataset.vizSettings.number = true
            if (!this.dataset.vizSettings.colors) {
              this.dataset.vizSettings.colors = [
                '#77CCCC',
                '#777711',
                '#AA7744',
                '#AAAA44',
                '#774411',
                '#4477AA',
                '#AA4455',
                '#771155',
                '#DDDD77',
                '#117744',
                '#DDAA77',
                '#CC99BB',
                '#44AAAA',
                '#117777',
                '#77AADD',
                '#88CCAA',
                '#DD7788',
                '#114477',
                '#AA4488',
                '#771122',
                '#44AA77',
                '#77CCCC',
                '#777711',
                '#AA7744',
                '#AAAA44',
                '#774411',
                '#4477AA',
                '#AA4455',
                '#771155',
                '#DDDD77',
                '#117744',
                '#DDAA77',
                '#CC99BB',
                '#44AAAA',
                '#117777',
                '#77AADD',
                '#88CCAA',
                '#DD7788',
                '#114477',
                '#AA4488',
                '#771122',
                '#44AA77',
                '#77CCCC',
                '#777711',
                '#AA7744',
                '#AAAA44',
                '#774411',
                '#4477AA',
                '#AA4455',
                '#771155',
                '#DDDD77',
                '#117744',
                '#DDAA77',
                '#CC99BB',
                '#44AAAA',
                '#117777',
                '#77AADD',
                '#88CCAA',
                '#DD7788',
                '#114477',
                '#AA4488',
                '#771122',
                '#44AA77',
                '#77CCCC',
                '#777711',
                '#AA7744',
                '#AAAA44',
                '#774411',
                '#4477AA',
                '#AA4455',
                '#771155',
                '#DDDD77',
                '#117744',
                '#DDAA77',
                '#CC99BB',
                '#44AAAA',
                '#117777',
                '#77AADD',
                '#88CCAA',
                '#DD7788',
                '#114477',
                '#AA4488',
                '#771122',
                '#44AA77',
                '#77CCCC',
                '#777711',
                '#AA7744',
                '#AAAA44',
                '#774411',
                '#4477AA',
                '#AA4455',
                '#771155',
                '#DDDD77',
                '#117744',
                '#DDAA77',
                '#CC99BB',
                '#44AAAA',
                '#117777',
                '#77AADD',
                '#88CCAA',
                '#DD7788',
                '#114477',
                '#AA4488',
                '#771122',
                '#44AA77',
                '#77CCCC',
                '#777711',
                '#AA7744',
                '#AAAA44',
                '#774411',
                '#4477AA',
                '#AA4455',
                '#771155',
                '#DDDD77',
                '#117744',
                '#DDAA77',
                '#CC99BB',
                '#44AAAA',
                '#117777',
                '#77AADD',
                '#88CCAA',
                '#DD7788',
                '#114477',
                '#AA4488',
                '#771122',
                '#44AA77',
                '#77CCCC',
                '#777711',
                '#AA7744',
                '#AAAA44',
                '#774411',
                '#4477AA',
                '#AA4455',
                '#771155',
                '#DDDD77',
                '#117744',
                '#DDAA77',
                '#CC99BB',
                '#44AAAA',
                '#117777',
                '#77AADD',
                '#88CCAA',
                '#DD7788',
                '#114477',
                '#AA4488',
                '#771122',
                '#44AA77',
                '#77CCCC',
                '#777711',
                '#AA7744',
                '#AAAA44',
                '#774411',
                '#4477AA',
                '#AA4455',
                '#771155',
                '#DDDD77',
                '#117744',
                '#DDAA77',
                '#CC99BB',
                '#44AAAA',
                '#117777',
                '#77AADD',
                '#88CCAA',
                '#DD7788',
                '#114477',
                '#AA4488',
                '#771122',
                '#44AA77'
              ]
            }
            this.$store.state.datasetGeoJson.features.forEach((feature, i) => {
              let featureValue = feature.properties[colorHeader]
              // Check to see if the property can be considered a number
              const notNumber = isNaN(featureValue)
              if (notNumber) {
                // If any feature is not a number, set categorical to true
                this.dataset.vizSettings.categorical = true
                this.dataset.vizSettings.number = false
              } else {
                // It is a number, now check to see if the property is stored
                // as a string and if so, convert to number
                if (typeof featureValue === 'string') {
                  featureValue = Number(featureValue)
                  this.$store.state.datasetGeoJson.features[i].properties[colorHeader] = featureValue
                }
                if (this.dataset.vizSettings.minValue === false) {
                  this.dataset.vizSettings.minValue = featureValue
                  this.dataset.vizSettings.maxValue = featureValue
                }
                if (featureValue < this.dataset.vizSettings.minValue) {
                  this.dataset.vizSettings.minValue = featureValue
                }
                if (featureValue > this.dataset.vizSettings.maxValue) {
                  this.dataset.vizSettings.maxValue = featureValue
                }
              }
            })

            // Check to see if we have a filter for the primary colorHeader
            if (this.dataset.vizSettings.filters.length > 0) {
              this.dataset.vizSettings.filters.forEach((filter, index) => {
                if (filter.header === this.dataset.vizSettings.colorHeader) {
                  this.dataset.vizSettings.minValue = this.dataset.vizSettings.filters[index].min
                  this.dataset.vizSettings.maxValue = this.dataset.vizSettings.filters[index].max
                }
              })
            }

            // if (this.dataset.vizSettings.categorical) {
            const categoryCounts = {}
            let categoryValue
            for (let i = 0; i < this.$store.state.datasetGeoJson.features.length; i++) {
              categoryValue = this.$store.state.datasetGeoJson.features[i].properties[colorHeader]
              if (typeof categoryCounts[categoryValue] === 'undefined') {
                categoryCounts[categoryValue] = 1
              } else {
                categoryCounts[categoryValue]++
              }
            }
            const entries = Object.entries(categoryCounts)
            entries.forEach((entry, i) => {
              if (i < 100) {
                this.dataset.vizSettings.featureCategories.push({
                  name: entry[0],
                  count: entry[1],
                  color: this.dataset.vizSettings.colors[i]
                })
              }
            })
            // }
            this.dataset.vizSettings.recalculateRange = false
            // if (this.dataset.vizSettings.categorical) {
            color = [
              'match',
              ['get', colorHeader]
            ]
            this.dataset.vizSettings.featureCategories.forEach((category, i) => {
              let name = category.name
              if (this.dataset.vizSettings.number) {
                name = Number(category.name)
              }
              color.push(name)
              color.push(this.dataset.vizSettings.colors[i])
            })
            color.push('#ccc')
            // } else {
            // ZONE SETTINGS
            // LINEAR
            if (this.dataset.vizSettings.zoneClassification === 'linear' && !this.dataset.vizSettings.categorical) {
              color = [
                'interpolate',
                ['linear'],
                ['to-number', ['get', colorHeader]],
                this.dataset.vizSettings.minValue,
                this.dataset.vizSettings.minColor,
                this.dataset.vizSettings.maxValue,
                this.dataset.vizSettings.maxColor
              ]
            }
            // EQUAL INTERVAL
            if (this.dataset.vizSettings.zoneClassification === 'equal-interval' && !this.dataset.vizSettings.categorical) {
              color = [
                'step',
                ['to-number', ['get', colorHeader]],
                'black',
                this.dataset.vizSettings.minValue, this.dataset.vizSettings.colors[0]
              ]
              const stopDiff = this.dataset.vizSettings.maxValue - this.dataset.vizSettings.minValue
              const stopLength = 1 / this.dataset.vizSettings.zoneStops
              for (let i = 1; i <= this.dataset.vizSettings.zoneStops; i++) {
                const stopMultiplier = stopLength * i
                const stopFormula = (stopDiff * stopMultiplier) + this.dataset.vizSettings.minValue
                color.push(stopFormula)
                color.push(this.dataset.vizSettings.colors[i])
              }
            }
            // JENKS
            if (this.dataset.vizSettings.zoneClassification === 'jenks' && !this.dataset.vizSettings.categorical) {
              if (this.dataset.vizSettings.recalculateJenks) {
                this.dataset.vizSettings.breaks = jenks(this.$store.state.datasetGeoJson, colorHeader, this.dataset.vizSettings.zoneStops)
              }
              if (this.dataset.vizSettings.breaks[0] === undefined) {
                this.dataset.vizSettings.breaks[0] = this.dataset.vizSettings.minValue
              }
              this.dataset.vizSettings.breaks.forEach((breakItem, i) => {
                if (typeof breakItem === 'string') {
                  this.dataset.vizSettings.breaks[i] = Number(this.dataset.vizSettings.breaks[i])
                }
              })
              color = [
                'step',
                ['to-number', ['get', colorHeader]],
                'black'
              ]
              this.dataset.vizSettings.breaks.forEach((breakItem, i) => {
                color.push(this.dataset.vizSettings.breaks[i])
                color.push(this.dataset.vizSettings.colors[i])
              })
            }
            // CATEGORICAL NUMBERS
            if (this.dataset.vizSettings.zoneClassification === 'categorical') {
              this.dataset.vizSettings.categorical = true
            }
            // }
          }
          // Check for sizing options
          if (this.dataset.vizSettings.sizeBy === 'fixed') {
            radius = this.dataset.vizSettings.circleRadius
          } else {
            this.dataset.vizSettings.minSizeValue = false
            this.dataset.vizSettings.maxSizeValue = false
            this.dataset.vizSettings.sizeCategorical = false
            this.dataset.vizSettings.sizeNumber = true
            // loop through and get min/max size values
            const sizeHeader = this.dataset.vizSettings.sizeHeader
            this.$store.state.datasetGeoJson.features.forEach((feature, i) => {
              let featureSizeValue = feature.properties[sizeHeader]
              // Check to see if the property can be considered a number
              const notNumber = isNaN(featureSizeValue)
              if (notNumber) {
                // If any feature is not a number, set categorical to true
                this.dataset.vizSettings.sizeCategorical = true
                this.dataset.vizSettings.sizeNumber = false
              } else {
                // It is a number, now check to see if the property is stored
                // as a string and if so, convert to number
                if (typeof featureSizeValue === 'string') {
                  featureSizeValue = Number(featureSizeValue)
                  this.$store.state.datasetGeoJson.features[i].properties[sizeHeader] = featureSizeValue
                }
                if (!this.dataset.vizSettings.minSizeValue) {
                  this.dataset.vizSettings.minSizeValue = featureSizeValue
                  this.dataset.vizSettings.maxSizeValue = featureSizeValue
                }
                if (featureSizeValue < this.dataset.vizSettings.minSizeValue) {
                  this.dataset.vizSettings.minSizeValue = featureSizeValue
                }
                if (featureSizeValue > this.dataset.vizSettings.maxSizeValue) {
                  this.dataset.vizSettings.maxSizeValue = featureSizeValue
                }
              }
            })
            if (this.dataset.vizSettings.sizeNumber) {
              // Set up the radius setting
              radius = [
                'interpolate',
                ['linear'],
                ['to-number', ['get', this.dataset.vizSettings.sizeHeader]],
                this.dataset.vizSettings.minSizeValue, // Min Value
                this.dataset.vizSettings.circleRadiusMin, // Min Render
                this.dataset.vizSettings.maxSizeValue, // Max Value
                this.dataset.vizSettings.circleRadiusMax // Max Render
              ]
            } else {
              radius = this.dataset.vizSettings.circleRadius
            }
          }
          if (this.dataset.vizSettings.filters.length > 0) {
            // Filter Dataset Map with filters
            const filters = ['all']
            this.dataset.vizSettings.filters.forEach(filter => {
              if (filter.header) {
                filters.push(['>=', ['number', ['get', filter.header]], filter.min])
                filters.push(['<=', ['number', ['get', filter.header]], filter.max])
              }
            })
            this.mapObject.setFilter('datasetPreview', filters)
            this.mapObject.setFilter('datasetPreviewPolygon', filters)
          } else {
            this.mapObject.setFilter('datasetPreview', undefined)
            if (this.mapObject.getLayer('datasetPreviewPolygon')) {
              this.mapObject.setFilter('datasetPreviewPolygon', undefined)
            }
          }
          this.mapObject.setPaintProperty('datasetPreview', 'circle-radius', radius)
          this.mapObject.setPaintProperty('datasetPreview', 'circle-color', color)
          if (this.mapObject.getLayer('datasetPreviewPolygon')) {
            this.mapObject.setPaintProperty('datasetPreviewPolygon', 'fill-color', color)
          }
        }
        // HEAT MAP SETTINGS
        if (this.dataset.vizSettings.type === 'heatMap') {
          let paintSettings
          // Weighted settings assume that the header is numbers
          if (this.dataset.vizSettings.heatBy === 'weighted') {
            this.dataset.vizSettings.minValue = false
            this.dataset.vizSettings.maxValue = false
            this.dataset.vizSettings.categorical = false
            this.dataset.vizSettings.number = true
            this.$store.state.datasetGeoJson.features.forEach(feature => {
              let featureValue = feature.properties[colorHeader]
              // Check to see if the property can be considered a number
              const notNumber = isNaN(featureValue)
              if (notNumber) {
                // If any feature is not a number, set categorical to true
                this.dataset.vizSettings.categorical = true
                this.dataset.vizSettings.number = false
              } else {
                // It is a number, now check to see if the property is stored
                // as a string and if so, convert to number
                if (typeof featureValue === 'string') {
                  featureValue = Number(featureValue)
                }
                if (this.dataset.vizSettings.minValue === false) {
                  this.dataset.vizSettings.minValue = featureValue
                  this.dataset.vizSettings.maxValue = featureValue
                }
                if (featureValue < this.dataset.vizSettings.minValue) {
                  this.dataset.vizSettings.minValue = featureValue
                }
                if (featureValue > this.dataset.vizSettings.maxValue) {
                  this.dataset.vizSettings.maxValue = featureValue
                }
              }
            })
            if (this.dataset.vizSettings.number) {
              paintSettings = {
                'heatmap-weight': [
                  'interpolate',
                  ['linear'],
                  ['to-number', ['get', colorHeader]],
                  this.dataset.vizSettings.minValue, // Min Value
                  0, // Min Render
                  this.dataset.vizSettings.maxValue, // Max Value
                  1 // Max Render
                ],
                'heatmap-radius': this.dataset.vizSettings.heatRadius
              }
            } else {
              paintSettings = { 'heatmap-radius': this.dataset.vizSettings.heatRadius }
            }
          } else {
            paintSettings = { 'heatmap-radius': this.dataset.vizSettings.heatRadius }
          }
          this.mapObject.setLayoutProperty('datasetPreview', 'visibility', 'none')
          if (!this.mapObject.getLayer('datasetPreviewHeatMap')) {
            this.mapObject.addLayer({
              id: 'datasetPreviewHeatMap',
              type: 'heatmap',
              source: 'datasetPreviewGeojson',
              // 'layout': {},
              paint: paintSettings
            })
          }
          if (this.dataset.vizSettings.filters.length > 0) {
            // Filter Dataset Map with filters
            const filters = ['all']
            this.dataset.vizSettings.filters.forEach(filter => {
              if (filter.header) {
                filters.push(['>=', ['number', ['get', filter.header]], filter.min])
                filters.push(['<=', ['number', ['get', filter.header]], filter.max])
              }
            })
            this.mapObject.setFilter('datasetPreviewHeatMap', filters)
          } else {
            this.mapObject.setFilter('datasetPreviewHeatMap', undefined)
          }
        }
        this.saveViz()
      }, 1000) // timeout
    }
  }
}
</script>

<style scoped>
.mobile-buffer {
  height: 150px;
}
</style>
