/* eslint-disable */
export default [
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.1.0 - Trail 3',
    type: 'polygons',
    years: '1836-1863',
    geoJson: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "coordinates": [
              [
                [
                  -79.76194381713867,
                  42.26981463033415
                ],
                [
                  -79.76211547851562,
                  42.243006247803805
                ],
                [
                  -79.7303581237793,
                  42.259651655997686
                ],
                [
                  -79.70512390136719,
                  42.268925435483496
                ],
                [
                  -79.69482421875,
                  42.27591162854855
                ],
                [
                  -79.67336654663086,
                  42.28416704016481
                ],
                [
                  -79.65019226074219,
                  42.294960946434664
                ],
                [
                  -79.63920593261719,
                  42.29661161608281
                ],
                [
                  -79.59903717041016,
                  42.30968846925995
                ],
                [
                  -79.57826614379883,
                  42.322254923531425
                ],
                [
                  -79.5241928100586,
                  42.3520743375646
                ],
                [
                  -79.51028823852539,
                  42.354611507112295
                ],
                [
                  -79.48162078857422,
                  42.364251817286835
                ],
                [
                  -79.4340705871582,
                  42.37845589430018
                ],
                [
                  -79.3846321105957,
                  42.40609390438587
                ],
                [
                  -79.36832427978516,
                  42.41559958024758
                ],
                [
                  -79.34703826904295,
                  42.41914799659737
                ],
                [
                  -79.31356430053711,
                  42.437393823243326
                ],
                [
                  -79.26551826277857,
                  42.45707263863167
                ],
                [
                  -79.22943306192035,
                  42.469404386019946
                ],
                [
                  -79.11678599315277,
                  42.51197644865508
                ],
                [
                  -79.08840632633832,
                  42.52116856102215
                ],
                [
                  -79.05555725097656,
                  42.53714498204725
                ],
                [
                  -79.07341003417969,
                  42.54372195086164
                ],
                [
                  -79.0799331665039,
                  42.54776897194192
                ],
                [
                  -79.0957260131836,
                  42.557379595507584
                ],
                [
                  -79.09778594970702,
                  42.56420729713456
                ],
                [
                  -79.1067123413086,
                  42.56724159136565
                ],
                [
                  -79.11083221435547,
                  42.57128708742757
                ],
                [
                  -79.13177490234375,
                  42.56673588590953
                ],
                [
                  -79.13829803466797,
                  42.57027573801005
                ],
                [
                  -79.2660140991211,
                  42.53360325035187
                ],
                [
                  -79.33708190917969,
                  42.50070614986493
                ],
                [
                  -79.36077117919922,
                  42.4931120519424
                ],
                [
                  -79.38446044921875,
                  42.47159043311705
                ],
                [
                  -79.47166442871092,
                  42.41965489682774
                ],
                [
                  -79.52693939208984,
                  42.38416198494277
                ],
                [
                  -79.76194381713867,
                  42.26981463033415
                ]
              ]
            ],
            "type": "Polygon"
          }
        }
      ]
    }
  },
  {
    name: '4.1.1 - Cleveland, Black Rock, Niagara Falls, and Lewiston',
    type: 'flashLights',
    years: '1855-1863',
    geoJson: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -81.683349609375,
              41.49623534616764
            ]
          }
        },
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.90157699584961,
              42.93305011502799
            ]
          }
        },
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -79.05744552612305,
              43.10875337930414
            ]
          }
        },
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -79.0492057800293,
              43.17451245481122
            ]
          }
        }
      ]
    }
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.2.0 - All Data',
    type: 'allData',
    years: '1836-1863'
  },
  {
    name: '4.3.0 - Show Sugar Grove PA, Rochester, and Syracuse',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.365234375,
                  41.95336258301847
                ],
                [
                  -75.95947265625,
                  41.95336258301847
                ],
                [
                  -75.95947265625,
                  43.229195113965005
                ],
                [
                  -79.365234375,
                  43.229195113965005
                ],
                [
                  -79.365234375,
                  41.95336258301847
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.4.0 - Hardy County WV to Sugar Grove PA',
    type: 'flashLights',
    years: '1863',
    geoJson: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -78.97247314453124,
              39.06184913429154
            ]
          }
        },
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -79.34188842773438,
              41.98246303636425
            ]
          }
        }
      ]
    }
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.5.0 - Trail 3',
    type: 'polygons',
    years: '1836-1863',
    geoJson: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "coordinates": [
              [
                [
                  -79.76194381713867,
                  42.26981463033415
                ],
                [
                  -79.76211547851562,
                  42.243006247803805
                ],
                [
                  -79.7303581237793,
                  42.259651655997686
                ],
                [
                  -79.70512390136719,
                  42.268925435483496
                ],
                [
                  -79.69482421875,
                  42.27591162854855
                ],
                [
                  -79.67336654663086,
                  42.28416704016481
                ],
                [
                  -79.65019226074219,
                  42.294960946434664
                ],
                [
                  -79.63920593261719,
                  42.29661161608281
                ],
                [
                  -79.59903717041016,
                  42.30968846925995
                ],
                [
                  -79.57826614379883,
                  42.322254923531425
                ],
                [
                  -79.5241928100586,
                  42.3520743375646
                ],
                [
                  -79.51028823852539,
                  42.354611507112295
                ],
                [
                  -79.48162078857422,
                  42.364251817286835
                ],
                [
                  -79.4340705871582,
                  42.37845589430018
                ],
                [
                  -79.3846321105957,
                  42.40609390438587
                ],
                [
                  -79.36832427978516,
                  42.41559958024758
                ],
                [
                  -79.34703826904295,
                  42.41914799659737
                ],
                [
                  -79.31356430053711,
                  42.437393823243326
                ],
                [
                  -79.26551826277857,
                  42.45707263863167
                ],
                [
                  -79.22943306192035,
                  42.469404386019946
                ],
                [
                  -79.11678599315277,
                  42.51197644865508
                ],
                [
                  -79.08840632633832,
                  42.52116856102215
                ],
                [
                  -79.05555725097656,
                  42.53714498204725
                ],
                [
                  -79.07341003417969,
                  42.54372195086164
                ],
                [
                  -79.0799331665039,
                  42.54776897194192
                ],
                [
                  -79.0957260131836,
                  42.557379595507584
                ],
                [
                  -79.09778594970702,
                  42.56420729713456
                ],
                [
                  -79.1067123413086,
                  42.56724159136565
                ],
                [
                  -79.11083221435547,
                  42.57128708742757
                ],
                [
                  -79.13177490234375,
                  42.56673588590953
                ],
                [
                  -79.13829803466797,
                  42.57027573801005
                ],
                [
                  -79.2660140991211,
                  42.53360325035187
                ],
                [
                  -79.33708190917969,
                  42.50070614986493
                ],
                [
                  -79.36077117919922,
                  42.4931120519424
                ],
                [
                  -79.38446044921875,
                  42.47159043311705
                ],
                [
                  -79.47166442871092,
                  42.41965489682774
                ],
                [
                  -79.52693939208984,
                  42.38416198494277
                ],
                [
                  -79.76194381713867,
                  42.26981463033415
                ]
              ]
            ],
            "type": "Polygon"
          }
        }
      ]
    }
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.5.1 - James Petit',
    type: 'pointsCollection',
    years: '1836-1863',
    dataPoints: [
      'Pz7KBJv29d9ysRsH8GcR'
    ]
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.6.0 - The Petits',
    type: 'pointsCollection',
    years: '1836-1863',
    dataPoints: [
      'Pz7KBJv29d9ysRsH8GcR',
      'ZvASV7ROC7wY2NyS3DFD',
      'ntCYscy77nGc0o8Gvo1r'
    ]
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.7.0 - Trail 3',
    type: 'polygons',
    years: '1840-1845',
    geoJson: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "coordinates": [
              [
                [
                  -79.76194381713867,
                  42.26981463033415
                ],
                [
                  -79.76211547851562,
                  42.243006247803805
                ],
                [
                  -79.7303581237793,
                  42.259651655997686
                ],
                [
                  -79.70512390136719,
                  42.268925435483496
                ],
                [
                  -79.69482421875,
                  42.27591162854855
                ],
                [
                  -79.67336654663086,
                  42.28416704016481
                ],
                [
                  -79.65019226074219,
                  42.294960946434664
                ],
                [
                  -79.63920593261719,
                  42.29661161608281
                ],
                [
                  -79.59903717041016,
                  42.30968846925995
                ],
                [
                  -79.57826614379883,
                  42.322254923531425
                ],
                [
                  -79.5241928100586,
                  42.3520743375646
                ],
                [
                  -79.51028823852539,
                  42.354611507112295
                ],
                [
                  -79.48162078857422,
                  42.364251817286835
                ],
                [
                  -79.4340705871582,
                  42.37845589430018
                ],
                [
                  -79.3846321105957,
                  42.40609390438587
                ],
                [
                  -79.36832427978516,
                  42.41559958024758
                ],
                [
                  -79.34703826904295,
                  42.41914799659737
                ],
                [
                  -79.31356430053711,
                  42.437393823243326
                ],
                [
                  -79.26551826277857,
                  42.45707263863167
                ],
                [
                  -79.22943306192035,
                  42.469404386019946
                ],
                [
                  -79.11678599315277,
                  42.51197644865508
                ],
                [
                  -79.08840632633832,
                  42.52116856102215
                ],
                [
                  -79.05555725097656,
                  42.53714498204725
                ],
                [
                  -79.07341003417969,
                  42.54372195086164
                ],
                [
                  -79.0799331665039,
                  42.54776897194192
                ],
                [
                  -79.0957260131836,
                  42.557379595507584
                ],
                [
                  -79.09778594970702,
                  42.56420729713456
                ],
                [
                  -79.1067123413086,
                  42.56724159136565
                ],
                [
                  -79.11083221435547,
                  42.57128708742757
                ],
                [
                  -79.13177490234375,
                  42.56673588590953
                ],
                [
                  -79.13829803466797,
                  42.57027573801005
                ],
                [
                  -79.2660140991211,
                  42.53360325035187
                ],
                [
                  -79.33708190917969,
                  42.50070614986493
                ],
                [
                  -79.36077117919922,
                  42.4931120519424
                ],
                [
                  -79.38446044921875,
                  42.47159043311705
                ],
                [
                  -79.47166442871092,
                  42.41965489682774
                ],
                [
                  -79.52693939208984,
                  42.38416198494277
                ],
                [
                  -79.76194381713867,
                  42.26981463033415
                ]
              ]
            ],
            "type": "Polygon"
          }
        }
      ]
    }
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.8.0 - Olney',
    type: 'pointsCollection',
    years: '1840-1845',
    dataPoints: [
      'kiJBZSZ2X1i47tkMWmQD',
      'BC2L4AQfks2sjqp7N983'
    ]
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.9.0 - All Data',
    type: 'allData',
    years: '1836-1875'
  },
  {
    name: '4.9.1 - Ripley and Westfield',
    type: 'fly',
    years: '1836-1875',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.73876953125,
                  42.247835139120575
                ],
                [
                  -79.57122802734375,
                  42.247835139120575
                ],
                [
                  -79.57122802734375,
                  42.351440029174164
                ],
                [
                  -79.73876953125,
                  42.351440029174164
                ],
                [
                  -79.73876953125,
                  42.247835139120575
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.10.0 - North East PA',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.86785888671875,
                  42.1817234984124
                ],
                [
                  -79.68246459960936,
                  42.1817234984124
                ],
                [
                  -79.68246459960936,
                  42.28746890196628
                ],
                [
                  -79.86785888671875,
                  42.28746890196628
                ],
                [
                  -79.86785888671875,
                  42.1817234984124
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.11.0 - Ripley and Westfield',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.73876953125,
                  42.247835139120575
                ],
                [
                  -79.57122802734375,
                  42.247835139120575
                ],
                [
                  -79.57122802734375,
                  42.351440029174164
                ],
                [
                  -79.73876953125,
                  42.351440029174164
                ],
                [
                  -79.73876953125,
                  42.247835139120575
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.12.0 - Westfield and Portland',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.64950561523438,
                  42.26917949243506
                ],
                [
                  -79.41192626953125,
                  42.26917949243506
                ],
                [
                  -79.41192626953125,
                  42.402164470921285
                ],
                [
                  -79.64950561523438,
                  42.402164470921285
                ],
                [
                  -79.64950561523438,
                  42.26917949243506
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.13.0 - Dunkirk, Pomfret, Sheridan, and Arkwright',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.43939208984375,
                  42.38644427600166
                ],
                [
                  -79.19288635253906,
                  42.38644427600166
                ],
                [
                  -79.19288635253906,
                  42.52424199254517
                ],
                [
                  -79.43939208984375,
                  42.52424199254517
                ],
                [
                  -79.43939208984375,
                  42.38644427600166
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.13.1 - Dunkirk',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.36248779296874,
                  42.46399280017058
                ],
                [
                  -79.30412292480469,
                  42.46399280017058
                ],
                [
                  -79.30412292480469,
                  42.49893427617076
                ],
                [
                  -79.36248779296874,
                  42.49893427617076
                ],
                [
                  -79.36248779296874,
                  42.46399280017058
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: 'Clear Map',
    type: 'noData',
  },
  {
    name: '4.14.0 - Dunkirk to Fort Malden and Detroit',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -83.232421875,
                  41.85319643776675
                ],
                [
                  -78.7060546875,
                  41.85319643776675
                ],
                [
                  -78.7060546875,
                  43.068887774169625
                ],
                [
                  -83.232421875,
                  43.068887774169625
                ],
                [
                  -83.232421875,
                  41.85319643776675
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.15.0 - All Data',
    type: 'allData',
    years: '1840-1845'
  },
  {
    name: '4.15.1 - Dunkirk',
    type: 'fly',
    years: '1840-1845',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.36248779296874,
                  42.46399280017058
                ],
                [
                  -79.30412292480469,
                  42.46399280017058
                ],
                [
                  -79.30412292480469,
                  42.49893427617076
                ],
                [
                  -79.36248779296874,
                  42.49893427617076
                ],
                [
                  -79.36248779296874,
                  42.46399280017058
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.16.0 - Dunkirk, Pomfret, Sheridan, and Arkwright',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.43939208984375,
                  42.38644427600166
                ],
                [
                  -79.19288635253906,
                  42.38644427600166
                ],
                [
                  -79.19288635253906,
                  42.52424199254517
                ],
                [
                  -79.43939208984375,
                  42.52424199254517
                ],
                [
                  -79.43939208984375,
                  42.38644427600166
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.16.1 - Dunkirk, Pomfret, Sheridan, Arkwright, and Forestville',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.37416076660156,
                  42.384922757848045
                ],
                [
                  -79.14344787597656,
                  42.384922757848045
                ],
                [
                  -79.14344787597656,
                  42.50450285299051
                ],
                [
                  -79.37416076660156,
                  42.50450285299051
                ],
                [
                  -79.37416076660156,
                  42.384922757848045
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.16.2 - Laona to Perry',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.34326171875,
                  42.370720143531976
                ],
                [
                  -77.991943359375,
                  42.370720143531976
                ],
                [
                  -77.991943359375,
                  42.72280375732727
                ],
                [
                  -79.34326171875,
                  42.72280375732727
                ],
                [
                  -79.34326171875,
                  42.370720143531976
                ]
              ]
            ]
          }
        }
      ]
    }
  },
  {
    name: '4.17.0 - Dunkirk, Pomfret, Sheridan, and Arkwright',
    type: 'fly',
    years: '1836-1863',
    bounds: {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -79.43939208984375,
                  42.38644427600166
                ],
                [
                  -79.19288635253906,
                  42.38644427600166
                ],
                [
                  -79.19288635253906,
                  42.52424199254517
                ],
                [
                  -79.43939208984375,
                  42.52424199254517
                ],
                [
                  -79.43939208984375,
                  42.38644427600166
                ]
              ]
            ]
          }
        }
      ]
    }
  }
]