import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'
const fb = require('../firebaseConfig.js')

Vue.use(Vuex)

// handle page reload
fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('fetchUserProfile')
    fb.usersCollection.doc(user.uid).onSnapshot(doc => {
      store.commit('setUserProfile', doc.data())
    })
  }
})

export const store = new Vuex.Store({
  state: {
    revision: {},
    revisions: [],
    blocksGeoJson: {},
    geocoderControl: {},
    selectMarker: false,
    zoomControl: {},
    locateControl: {},
    showKey: false,
    keyMode: 'blocks',
    moveMenuOpen: false,
    breadcrumbs: [
      {
        id: 'root',
        name: 'Root'
      }
    ],
    collectors: [],
    collector: {
      fields: []
    },
    createFolderPrompt: false,
    defaults: {
      pointStops: {
        stops: [[15, 1], [16, 2], [17, 3], [18, 6], [20, 20], [23, 50]]
      }
    },
    drawer: {
      atlases: {
        show: false,
        position: 'out'
      },
      atlasDetails: {
        show: false,
        position: 'out'
      },
      blockDetails: {
        show: false,
        position: 'out'
      },
      collaborators: {
        show: false,
        position: 'out'
      },
      collectors: {
        show: false,
        position: 'out'
      },
      collector: {
        show: false,
        position: 'out'
      },
      copyDataset: {
        show: false,
        position: 'out'
      },
      createAtlas: {
        show: false,
        position: 'out'
      },
      createBlockDrawer: {
        show: false,
        position: 'out'
      },
      createDataset: {
        show: false,
        position: 'out'
      },
      createDatasetDrawer: {
        show: false,
        position: 'out'
      },
      createMapDrawer: {
        show: false,
        position: 'out'
      },
      dataPointDetails: {
        show: false,
        position: 'out'
      },
      datasetDetails: {
        show: false,
        position: 'out'
      },
      datasets: {
        show: false,
        position: 'out'
      },
      listDrawer: {
        show: false,
        position: 'out'
      },
      notifications: {
        show: false,
        position: 'out'
      },
      plugins: {
        show: false,
        position: 'out'
      },
      previewRevision: {
        show: false,
        position: 'out'
      },
      revisions: {
        show: false,
        position: 'out'
      },
      userDetails: {
        show: false,
        position: 'out'
      },
      userSettings: {
        show: false,
        position: 'out'
      }
    },
    drawControlsObject: false,
    loadingMap: false,
    paginate: false,
    lastVisibleDataset: {},
    showDatasetVizSettings: false,
    showCreateBlockDrawer: false,
    printingClass: 'no-print',
    alert: {
      active: false,
      message: 'This is a basic alert',
      level: 'alert-dark'
    },
    userLocation: [],
    dataPoint: {},
    atlas: {},
    atlases: [],
    datasets: [],
    hiddenDatasets: [],
    folder: {
      id: 'root',
      name: 'Root'
    },
    folders: [],
    block: {},
    blocks: [],
    selectedBlocks: [],
    selectBlocksListenersAdded: false,
    dataset: {},
    datasetGeoJson: {},
    notifications: [],
    mapClickable: false,
    plugin: {
      name: '',
      url: '',
      arg1: ''
    },
    sortBy: 'Most Recent',
    filterBy: '',
    mapObject: {},
    role: '',
    currentUser: null,
    userProfile: {},
    rangeZones: [],
    display: {
      mapClass: 'out',
      processingDataset: false,
      showMap: false,
      fullscreen: false,
      unreadNotifications: 0,
      datasetReady: false,
      drawer: {},
      modal: {
        addBlockField: false,
        createBlock: false,
        createDataset: false,
        createMap: false,
        createAtlas: false,
        createCollector: false,
        firstAtlasModal: false,
        addField: false,
        dataset: false,
        map: false,
        cropLoadCalculator: false,
        pluginModal: false,
        collaborators: false,
        settings: false,
        feedback: false
      },
      editMode: false
    },
    viz: {
      header: false,
      min: 0,
      max: 1,
      interpolatedMin: 0,
      interpolatedMax: 1,
      colorSetting: 'linear',
      zones: 5,
      classification: 'jenks',
      interpolated: false
    }
  },
  mutations: mutations,
  actions: actions
})
