<template>
  <div id="atlases">

    <!--Atlases drawer -->
    <b-card
      id="atlasesCard"
      class="blockDrawer large"
      :class="$store.state.drawer.atlases.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'atlases')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">My Atlases</h4>
        <b-btn
          v-if="$store.state.role == 'Administrator' || $store.state.role == 'Editor'"
          @click="createAtlas()"
          class="mb-2"
          variant="dark"
        >
          <i class="far fa-plus"></i> New Atlas
        </b-btn>
      </template>

      <div class="list-group">
        <div
          v-for="(atlas, i) in $store.state.atlases"
          v-bind:key="i"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex w-100 justify-content-between">
            <small
              @click="launchAtlas(atlas.id)"
              class="clickable"
            >
              <strong>{{ atlas.role }}</strong>
            </small>
            <div class="card-right-group">
              <small
                v-if="atlas.id == $store.state.userProfile.defaultAtlas"
                v-b-tooltip.hover
                title="Default Atlas"
              >
                <i class="fas fa-heart"></i>
              </small>
              <small
                v-else
                v-b-tooltip.hover
                title="Set Default Atlas"
                @click="setDefaultAtlas(atlas.id)"
              >
                <i class="far fa-heart"></i>
              </small>
            </div><!-- /.card-right-group -->
          </div>
          <p @click="launchAtlas(atlas.id)" class="lead mb-1 clickable">{{ atlas.name }}</p>
        </div>
      </div><!-- /.list-group -->
    </b-card>
  </div><!-- /#atlases -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  methods: {
    setDefaultAtlas (id) {
      fb.usersCollection.doc(this.$store.state.currentUser.uid).update({
        defaultAtlas: id
      }).then(() => {
        this.$store.dispatch('fetchUserProfile')
      })
    },
    launchAtlas (id) {
      window.location.href = '/a/' + id
    },
    createAtlas () {
      this.$store.commit('hideDrawer', 'atlases')
      setTimeout(() => {
        this.$store.commit('setEditMode', false)
        this.$store.commit('showDrawer', 'createAtlas')
        // this.$store.commit('toggleModal', 'createAtlas')
      }, 300)
    }
  }
}
</script>

<style scoped>
  /* #mapsCard {
    overflow-y: scroll;
  } */
</style>
