<template>
  <div id="createDatasetDrawer">
    <!-- Create Dataset -->
    <b-card
      id="createDatasetDrawerCard"
      class="blockDrawer large"
      :class="$store.state.drawer.createDataset.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeCreateDatasetDrawer()"
        >
          <i class="far fa-times"></i>
        </a>

        <h4 v-if="$store.state.display.editMode == false" id="datasetTitle"><i class="fas fa-file-upload"></i> Upload Dataset</h4>
        <h4 v-if="$store.state.display.editMode" id="datasetTitle">Dataset Settings</h4>
      </template>
      <p v-if="$store.state.dataset.dynamic"><strong>Dynamic Dataset:</strong> These settings will also update your data collector settings!</p>
      <form @submit.prevent>
        <!-- NAME -->
        <div class="form-group">
          <label for="datasetName">Name</label>
          <input
            v-model="tempDataset.name"
            id="datasetName"
            class="form-control"
          />
        </div><!-- /.form-group -->

        <!-- DESCRIPTION -->
        <div class="form-group">
          <label for="datasetDescription">Description</label>
          <quill-editor
            v-model="tempDataset.description"
            :options="editorOptions"
          ></quill-editor>
          <!-- <textarea
            v-model="tempDataset.description"
            id="datasetDescription"
            class="form-control"
          /> -->
        </div><!-- /.form-group -->

        <!-- PROCCESSING LOADER -->
        <div
          v-if="$store.state.display.processingDataset"
          class="processing-spinner-container"
        >
          <div class="processing-spinner"><i class="fad fa-server"></i> Processing data...</div><!-- /.procesing-spinner -->
        </div><!-- /.processing-spinner-container -->

        <!-- UPLOAD FILE -->
        <div
          v-if="$store.state.display.editMode == false"
          class="form-group"
        >
          <label for="dataFilesInput">Upload Data File<br><small>Can be a .txt, .csv, .geojson, .tif, .kmz, .kml, or zipped shapefiles file with 'LAT' and 'LNG' or 'Latitude' and 'Longitude' headers.</small></label>
          <DataFilesInput
            :atlas="$store.state.atlas"
            v-on:dataFilesDelivery="dataFilesDelivery($event)"
          ></DataFilesInput>
        </div><!-- /.form-group -->
        <div class="float-right" style="width:100%;">
          <button
            v-if="$store.state.display.editMode == false && $store.state.display.datasetReady"
            @click="createDataset()"
            class="btn btn-dark mr-1"
          >
            Submit
          </button>
          <button
            v-if="$store.state.display.editMode"
            @click="createDataset()"
            class="btn btn-dark mr-1"
          >
            Save
          </button>
          <button
            @click="closeCreateDatasetDrawer()"
            class="btn btn-light mr-1"
          >
            Cancel
          </button>
          <button
            v-if="$store.state.display.editMode && !dataset.dynamic && $store.state.folder.id == 'trash'"
            @click="confirmDelete = true"
            class="btn btn-danger mr-1"
          >
            Delete
          </button>
          <button
            v-if="$store.state.display.editMode && dataset.dynamic && $store.state.folder.id == 'trash'"
            @click="confirmDeleteDynamic = true"
            class="btn btn-danger mr-1"
          >
            Delete
          </button>
          <button
            v-if="$store.state.display.editMode && !dataset.dynamic && $store.state.folder.id != 'trash'"
            @click="confirmTrash = true"
            class="btn btn-danger mr-1"
          >
            Trash
          </button>
          <button
            v-if="$store.state.display.editMode && dataset.dynamic && $store.state.folder.id != 'trash'"
            @click="confirmTrashDynamic = true"
            class="btn btn-danger mr-1"
          >
            Trash
          </button>
        </div><!-- /.float-right -->
      </form>
    </b-card>
    <Confirm
      v-if="confirmTrash"
      text="Are you sure you want to trash this dataset? You can restore items from the trash."
      v-on:confirmed="trashDataset($event)"
    />
    <Confirm
      v-if="confirmTrashDynamic"
      text="Are you sure you want to trash this dynamic dataset? This will also deactivate this collector. You can restore items from the trash."
      v-on:confirmed="trashDataset($event)"
    />
  </div><!-- /#createMapDrawer -->
</template>

<script>
import Confirm from '../Confirm'
import firebase from 'firebase'
import DataFilesInput from './DataFilesInput.vue'
import axios from 'axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      confirmTrash: false,
      confirmTrashDynamic: false,
      confirmDelete: false,
      confirmDeleteDynamic: false,
      selectedFile: null,
      headers: [],
      captured: {
        utcStart: null,
        utcEnd: null
      },
      geoJsonFileUrl: '',
      jpgFileUrl: '',
      drawControl: {},
      drawEventsLog: [],
      tempDataset: {},
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, false] }],
            [{ align: [] }],
            ['link', 'clean']
          ]
        }
      }
    }
  },
  components: {
    Confirm,
    DataFilesInput,
    quillEditor
  },
  computed: {
    dataset: {
      get () {
        return this.$store.state.dataset
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'dataset', value: value })
      }
    }
  },
  mounted () {
    if (this.$store.state.display.editMode === true) {
      this.tempDataset = this.dataset
      this.getFileUrl()
    } else {
      this.dataset = {}
      this.tempDataset = {
        name: '',
        description: '',
        headers: [],
        file: false
      }
      this.$store.dispatch('removeAllLayers')
      this.$store.commit('setDatasetReady', false)
    }
  },
  methods: {
    enableMapEditing () {
      const map = this.$store.state.mapObject
      // DRAW SETTINGS
      this.drawControl = new MapboxDraw({ // eslint-disable-line
        displayControlsDefault: false,
        controls: {
          trash: true
        }
      })
      map.addControl(this.drawControl)
      axios.get(this.geoJsonFileUrl).then(response => {
        this.originalGeoJson = response.data
        this.drawControl.add(response.data)
        // When A Feature is created, write it to this.block.footprint
        const self = this
        map.on('draw.selectionchange', function (e) {
          if (e.features.length > 0) {
            const drawEvent = { type: e.type, geometry: e.features[0].geometry, dataPointId: e.features[0].properties.dataPointId || false }
            self.drawEventsLog.push(drawEvent)
          }
        })
        map.on('draw.create', function (e) {
          const drawEvent = { type: e.type, geometry: e.features[0].geometry, dataPointId: e.features[0].properties.dataPointId || false }
          self.drawEventsLog.push(drawEvent)
        })
        map.on('draw.delete', function (e) {
          const drawEvent = { type: e.type, geometry: e.features[0].geometry, dataPointId: e.features[0].properties.dataPointId || false }
          self.drawEventsLog.push(drawEvent)
        })
        map.on('draw.update', function (e) {
          const drawEvent = { type: e.type, geometry: e.features[0].geometry, dataPointId: e.features[0].properties.dataPointId || false }
          self.drawEventsLog.push(drawEvent)
        })
      })
    },
    trashDataset (confirmed) {
      if (confirmed) {
        this.confirmTrash = false
        this.confirmTrashDynamic = false
        fb.datasetsCollection.doc(this.$store.state.dataset.id).update({
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid,
          folderId: 'trash'
        }).then(() => {
          this.dataset = {}
          this.$router.push('/a/' + this.$store.state.atlas.id)
          this.$store.dispatch('initAtlasMap')
          this.$store.commit('hideDrawer', 'createDataset')
          this.$store.commit('setAlert', { active: true, message: 'Dataset successfully trashed.', level: 'alert-dark', timeout: 3000 })
        })
        if (this.dataset.dynamic) {
          fb.collectorsCollection.doc(this.dataset.collectorId).update({
            updatedOn: new Date(),
            updatedBy: this.$store.state.currentUser.uid,
            trashed: true
          })
        }
      } else {
        this.confirmTrash = false
        this.confirmTrashDynamic = false
      }
    },
    getFileUrl () {
      const self = this
      // Get GeoJson File URL
      const geoJsonPath = this.tempDataset.file.geoJsonPath
      const geoJsonStorageRef = firebase.storage().ref(geoJsonPath)
      geoJsonStorageRef.getDownloadURL().then(geoFileUrl => {
        self.geoJsonFileUrl = geoFileUrl
        self.$store.dispatch('removeAllLayers')
        self.enableMapEditing()
        // self.renderMapPreview()
      }).catch(err => {
        console.log(err)
      })
    },
    renderMapPreview () {
      const self = this
      mapboxgl.accessToken = 'pk.eyJ1IjoidGhlbWFwcGluZ3NlcnZpY2UiLCJhIjoiY2t1aWJ2c3hsMWkwYzJ1cGhyNjRndWhzZiJ9.Dm5XwBzvVMaXNK0BpiQLHA' // eslint-disable-line
      const map = this.$store.state.mapObject

      if (self.geoJsonFileUrl) {
        // map.addSource('geoJsonSource', {
        //   type: 'geojson',
        //   data: self.geoJsonFileUrl
        // })
        // map.addLayer({
        //   id: 'geojsonMapPreview',
        //   type: 'circle',
        //   source: 'geoJsonSource',
        //   layout: {},
        //   paint: {
        //     'circle-radius': self.$store.state.defaults.pointStops,
        //     'circle-color': '#ffc107'
        //   }
        // })
        axios.get(self.geoJsonFileUrl).then(response => {
          map.addSource('geoJsonSource', {
            type: 'geojson',
            data: response.data
          })
          if (response.data.features[0].geometry.type === 'Polygon') {
            map.addLayer({
              id: 'geojsonMapPreview',
              type: 'fill',
              source: 'geoJsonSource',
              // 'layout': {},
              paint: {
                'fill-outline-color': 'white',
                'fill-opacity': 0.8,
                'fill-color': '#ffc107'
              }
            })
          } else {
            map.addLayer({
              id: 'geojsonMapPreview',
              type: 'circle',
              source: 'geoJsonSource',
              layout: {},
              paint: {
                'circle-radius': self.$store.state.defaults.pointStops,
                'circle-color': '#ffc107'
              }
            })
          }
          map.fitBounds(turf.bbox(response.data), { // eslint-disable-line
            padding: 20,
            animate: false
          })
          // Get Capture Dates and Times
          const allFeatures = response.data.features
          // self.dataset.captured = {}
          // GET HEADERS
          self.tempDataset.headers = Object.keys(allFeatures[0].properties)
        })
      }

      if (self.jpgFileUrl) {
        if (map.getLayer('rasterLayer')) {
          map.removeLayer('rasterLayer')
          map.removeSource('rasterSource')
        }
        map.addSource('rasterSource', {
          type: 'image',
          url: self.jpgFileUrl,
          coordinates: [
            [self.tempDataset.file.xMin, self.tempDataset.file.yMax], // top left
            [self.tempDataset.file.xMax, self.tempDataset.file.yMax], // top right
            [self.tempDataset.file.xMax, self.tempDataset.file.yMin], // bottom right
            [self.tempDataset.file.xMin, self.tempDataset.file.yMin] // bottom left
          ]
        })
        this.$store.state.mapObject.addLayer({
          id: 'rasterLayer',
          source: 'rasterSource',
          type: 'raster',
          paint: {
            'raster-opacity': 0.85
          }
        })
      }
    },
    closeCreateDatasetDrawer () {
      if (this.$store.state.display.editMode) {
        const map = this.$store.state.mapObject
        map.removeControl(this.drawControl)
        this.drawEventsLog = []
      } else {
        this.$store.dispatch('removeAllLayers')
        // this.$store.commit('setDrawer', { name: 'createDatasetDrawer', position: 'out' })
        // Delete the files
        if (this.tempDataset.file) {
          // Original file
          const mediaPath = this.tempDataset.file.path
          const fileRef = firebase.storage().ref(mediaPath)
          fileRef.delete()

          if (this.tempDataset.file.geoJsonPath) {
            // GeoJson File
            const geoJsonMediaPath = this.tempDataset.file.geoJsonPath
            const geoJsonFileRef = firebase.storage().ref(geoJsonMediaPath)
            geoJsonFileRef.delete()
          }

          if (this.tempDataset.file.jpgPath) {
            // JPG File
            const jpgMediaPath = this.tempDataset.file.jpgPath
            const jpgFileRef = firebase.storage().ref(jpgMediaPath)
            jpgFileRef.delete()
          }
        }
      }
      this.$store.commit('hideDrawer', 'createDataset')
      const self = this
      setTimeout(() => {
        self.$store.commit('setEditMode', false)
        fb.datasetsCollection.doc(self.$store.state.dataset.id).get().then(newDoc => {
          self.dataset = newDoc.data()
          self.dataset.id = newDoc.id
          self.$store.dispatch('removeAllLayers')
          self.$store.dispatch('mapDataset', self.dataset)
        })
      }, 300)
    },
    dataFilesDelivery (dataFilesPayload) {
      const self = this
      self.tempDataset.file = dataFilesPayload
      if (dataFilesPayload.raster) {
        // Go get the download URL for jpg file
        const jpgPath = self.tempDataset.file.jpgPath
        const jpgStorageRef = firebase.storage().ref(jpgPath)
        this.$store.state.mapObject.fitBounds([
          [dataFilesPayload.xMin, dataFilesPayload.yMin],
          [dataFilesPayload.xMax, dataFilesPayload.yMax]
        ], {
          animate: false
        })
        jpgStorageRef.getDownloadURL().then(fileUrl => {
          self.jpgFileUrl = fileUrl
          self.renderMapPreview()
          self.$store.commit('setDatasetReady', true)
        }).catch(function (error) { console.log(error) })
      } else {
        // Go get the download URL for geojson file
        const geoJsonPath = self.tempDataset.file.geoJsonPath
        const geoJsonStorageRef = firebase.storage().ref(geoJsonPath)
        geoJsonStorageRef.getDownloadURL().then(fileUrl => {
          self.geoJsonFileUrl = fileUrl
          self.renderMapPreview()
          self.$store.commit('setDatasetReady', true)
        }).catch(function (error) { console.log(error) })
      }
    },
    createDataset () {
      if (!this.$store.state.display.editMode) {
        fb.datasetsCollection.add({
          name: this.tempDataset.name || '',
          description: this.tempDataset.description || '',
          headers: this.tempDataset.headers || [],
          atlasId: this.$store.state.atlas.id,
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid,
          file: this.tempDataset.file || {},
          folderId: this.$store.state.folder.id
        }).then(doc => {
          this.$store.dispatch('fetchDatasets', this.$store.state.atlas.id)
          // this.$store.commit('toggleModal', 'createDataset')
          this.dataset.id = doc.id
          fb.datasetsCollection.doc(doc.id).get().then(newDoc => {
            this.dataset = newDoc.data()
            this.dataset.id = newDoc.id
            this.$store.dispatch('removeAllLayers')
            this.$store.dispatch('mapDataset', this.dataset)
          })
        }).catch(err => {
          console.log(err)
        })
      } else {
        const map = this.$store.state.mapObject
        map.removeControl(this.drawControl)
        fb.datasetsCollection.doc(this.$store.state.dataset.id).update({
          name: this.tempDataset.name || '',
          description: this.tempDataset.description || '',
          headers: this.tempDataset.headers || [],
          atlasId: this.$store.state.atlas.id,
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid,
          file: this.tempDataset.file || {}
        }).then(() => {
          let collectorId
          if (this.$store.state.dataset.dynamic) {
            collectorId = this.$store.state.dataset.collectorId
            fb.collectorsCollection.doc(collectorId).update({
              description: this.tempDataset.description || '',
              name: this.tempDataset.name || '',
              updatedBy: this.$store.state.currentUser.uid,
              updatedOn: new Date(),
              reCompile: true
            })
          }
          if (this.drawEventsLog.length > 0) {
            if (this.$store.state.dataset.dynamic) {
              return true
            } else {
              return fb.editRequestsCollection.add({
                createdOn: new Date(),
                createdBy: this.$store.state.currentUser.uid,
                atlasId: this.$store.state.atlas.id,
                datasetId: this.$store.state.dataset.id,
                edits: this.drawEventsLog
              })
            }
          } else {
            return true
          }
        }).then(() => {
          if (this.$store.state.dataset.dynamic) {
            // Loop through edits and adjust dataPoints
            const finalEdits = {}
            this.drawEventsLog.forEach((drawEvent, i) => {
              if (drawEvent.type === 'draw.delete') {
                finalEdits[drawEvent.dataPointId] = false
              }
              if (drawEvent.type === 'draw.update') {
                finalEdits[drawEvent.dataPointId] = drawEvent.geometry
              }
            })
            const editKeys = Object.keys(finalEdits)
            editKeys.forEach(dataPointId => {
              if (finalEdits[dataPointId] === false) {
                fb.collectorsCollection.doc(this.$store.state.dataset.collectorId).collection('dataPoints').doc(dataPointId).delete()
              } else {
                const geoPoint = new firebase.firestore.GeoPoint(finalEdits[dataPointId].coordinates[1], finalEdits[dataPointId].coordinates[0])
                fb.collectorsCollection.doc(this.$store.state.dataset.collectorId).collection('dataPoints').doc(dataPointId).update({
                  geolocation: geoPoint,
                  updatedBy: this.$store.state.currentUser.uid,
                  updatedOn: new Date()
                })
              }
            })
            this.$store.dispatch('removeAllLayers')
            this.$store.commit('resetDrawers')
            setTimeout(() => {
              this.$store.commit('setAlert', { active: true, message: 'Dataset being edited! Hold tight!', level: 'alert-dark' })
              this.$store.commit('hideDrawer', 'createDataset')
              // this.$store.commit('setStateProperty', { property: 'showCreateDatasetDrawer', value: false })
            }, 300)
            setTimeout(() => {
              this.$store.commit('setAlert', { active: false, message: 'Dataset being edited! Hold tight!', level: 'alert-dark' })
              this.$store.dispatch('mapDataset', this.$store.state.dataset).then(() => {
                let newData = true
                fb.datasetsCollection.doc(this.$store.state.dataset.id).onSnapshot(snapshot => {
                  const datasetDoc = snapshot.data()
                  datasetDoc.id = snapshot.id
                  const filePath = this.$store.state.dataset.file.geoJsonPath || ''
                  const newFilePath = datasetDoc.file.geoJsonPath
                  // Only rerender if new geoJson file
                  if (filePath !== newFilePath && newData === true) {
                    newData = false
                    this.$store.commit('setDataset', datasetDoc)
                    const self = this
                    setTimeout(function () {
                      self.$store.commit('setAlert', { active: false })
                      self.$store.dispatch('removeAllLayers')
                      self.$store.dispatch('mapDataset', datasetDoc)
                    }, 1000)
                  }
                })
              })
            }, 5000)
          } else {
            if (this.drawEventsLog.length < 1) {
              this.$store.dispatch('fetchDatasets', this.$store.state.atlas.id)
              // this.$store.commit('toggleModal', 'createDataset')
              this.$store.dispatch('removeAllLayers')
              this.$store.dispatch('mapDataset', this.$store.state.dataset)
            } else {
              // OTHERWISE WAIT FOR THE EDITS TO BE COMPLETE
              this.$store.dispatch('removeAllLayers')
              // this.$store.commit('resetDrawers')
              // SUbscribe to the dataset document and listen for change
              let newData = true
              fb.datasetsCollection.doc(this.$store.state.dataset.id).onSnapshot(snapshot => {
                const datasetDoc = snapshot.data()
                datasetDoc.id = snapshot.id
                const filePath = this.$store.state.dataset.file.geoJsonPath || ''
                const newFilePath = datasetDoc.file.geoJsonPath
                // Only rerender if new geoJson file
                if (filePath !== newFilePath && newData === true) {
                  newData = false
                  this.$store.commit('setDataset', datasetDoc)
                  const self = this
                  setTimeout(function () {
                    self.$store.commit('setAlert', { active: false })
                    self.$store.dispatch('removeAllLayers')
                    self.$store.dispatch('mapDataset', datasetDoc)
                  }, 1000)
                }
              })
              setTimeout(() => {
                this.$store.commit('setAlert', { active: true, message: 'Dataset being edited! Hold tight!', level: 'alert-dark' })
                this.$store.commit('hideDrawer', 'createDataset')
                // this.$store.commit('setStateProperty', { property: 'showCreateDatasetDrawer', value: false })
              }, 300)
            }
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>
