<template>
  <div id="moveMenu">
    <div
      class="confirm"
    >
      <p><strong>Move '{{ moveItem.name }}'</strong></p>
      <div
        v-if="breadcrumbs.length > 0"
        class="mb-2"
      >
        <span
          v-for="(crumb, i) in breadcrumbs"
          v-bind:key="i"
        >
          <b-badge
            pill
            variant="dark"
            @click="launchFolder(crumb, true)"
            class="clickable"
          >{{ crumb.name }}</b-badge><span v-if="(i + 1) !== breadcrumbs.length"> / </span>
        </span>
      </div>
      <div
        v-for="(folder, i) in $store.state.folders"
        v-bind:key="i"
      >
        <p
          v-if="folder.id !== moveItem.id"
          class="clickable"
          @click="launchFolder(folder, false)"
        >
          <i class="fas fa-folder"></i> {{ folder.name }} <i class="fas fa-arrow-alt-to-right"></i>
        </p>
      </div>
      <button
        class="btn btn-success mr-1 mt-3"
        @click="updateItemLocation()"
      >
        Move Here
      </button>
      <button
        class="btn btn-light mt-3"
        @click="$store.commit('setStateProperty', { property: 'moveMenuOpen', value: false })"
      >
        Cancel
      </button>
    </div><!-- /.confirm -->
  </div><!-- /#confirm -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      folderName: '',
      folderDescription: ''
    }
  },
  props: [
    'moveType',
    'moveItem'
  ],
  computed: {
    breadcrumbs: {
      get () {
        return this.$store.state.breadcrumbs
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'breadcrumbs', value: value })
      }
    }
  },
  methods: {
    launchFolder (folder, fromCrumb) {
      if (this.breadcrumbs.length < 1) {
        this.breadcrumbs = [{ name: 'Root', id: 'root' }]
      }
      if (fromCrumb) {
        const newBreadcrumbs = []
        this.breadcrumbs.some(crumb => {
          if (crumb.id !== folder.id) {
            newBreadcrumbs.push(crumb)
          } else {
            newBreadcrumbs.push(crumb)
            return true
          }
        })
        this.breadcrumbs = newBreadcrumbs
      } else {
        this.breadcrumbs.push(folder)
      }
      if (folder.id === 'root') {
        this.breadcrumbs = []
      }
      this.$store.commit('setStateProperty', { property: 'folder', value: folder })
      this.$store.dispatch('fetchDatasets', this.$store.state.atlas.id).then(() => {
        this.$store.dispatch('fetchFolders', this.$store.state.atlas.id)
      })
    },
    updateItemLocation () {
      if (this.moveType === 'dataset') {
        fb.datasetsCollection.doc(this.moveItem.id).update({
          createdOn: new Date(),
          updatedOn: new Date(),
          folderId: this.$store.state.folder.id
        }).then(() => {
          this.$store.commit('setStateProperty', { property: 'moveMenuOpen', value: false })
          this.$store.dispatch('fetchDatasets', this.$store.state.atlas.id).then(() => {
            this.$store.dispatch('fetchFolders', this.$store.state.atlas.id)
          })
        })
      } else if (this.moveType === 'folder') {
        fb.foldersCollection.doc(this.moveItem.id).update({
          createdOn: new Date(),
          updatedOn: new Date(),
          folderId: this.$store.state.folder.id
        }).then(() => {
          this.$store.commit('setStateProperty', { property: 'moveMenuOpen', value: false })
          this.$store.dispatch('fetchDatasets', this.$store.state.atlas.id).then(() => {
            this.$store.dispatch('fetchFolders', this.$store.state.atlas.id)
          })
        })
      }
    }
  }
}
</script>

<style scoped>
#moveMenu {
  position: absolute;
  padding: 20px;
  z-index: 999;
  top: 120px;
  left: 80px;
  width: 300px;
  background-color: white;
  border: 1px solid black;
}
</style>
