<template>
  <div id="app" :class="$store.state.printingClass">
    <Alert
      v-if="$store.state.alert.active == true"
    />
    <router-view />
    <cookie-consent
      v-if="$store.state.display.fullscreen == false"
      id="cookieConsent"
    />
  </div>
</template>

<script>
import Alert from './components/Alert'

export default {
  components: {
    Alert
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
