<template>
  <div id="totals">
    <h2>
      <span class="pr-5"><i class="fas fa-users"></i> {{ usersTotal }}</span>
      <span class="pr-5"><i class="fas fa-atlas"></i> {{ atlasesTotal }}</span>
      <i class="fas fa-database"></i> {{ datasetsTotal }}
    </h2>
  </div><!-- /#totals -->
</template>

<script>
export default {
  props: [
    'usersTotal',
    'atlasesTotal',
    'datasetsTotal'
  ]
}
</script>

<style scoped>
#totals {
  text-align: center;
}

h2 {
  font-size: 18px;
}
</style>
