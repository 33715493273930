<template>
  <div id="createAtlas">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.createAtlas.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeCreateAtlas()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">Create Atlas</h4>
      </template>
      <p>*Click on the map to select a location for your atlas.</p>
      <div class="form-group">
        <label for="atlasName"><strong>Atlas Name</strong></label>
        <input
          v-model="atlas.name"
          id="atlasName"
          class="form-control"
        />
      </div><!-- /.form-group -->

      <!-- <div class="form-group">
        <label><strong>Block Fields (Advanced)</strong></label>
        <div class="list-group">
          <div
            v-for="(field, i) in atlas.blockFields"
            v-bind:key="i"
            class="list-group-item list-group-item-action clickable"
            @click="editBlockField(i)"
          >
            <p class="lead mb-1">
              <i v-if="field.type == 'Text'" class="fas fa-text"></i>
              <i v-if="field.type == 'Number'" class="fas fa-hashtag"></i>
              <i v-if="field.type == 'Select'" class="far fa-ballot-check"></i>
              <i v-if="field.type == 'Image'" class="fas fa-image"></i>
              &nbsp;{{ field.label }}
            </p>
          </div>
        </div>
        <b-btn
          @click="addBlockField()"
          class="mt-2"
          variant="dark"
        ><i class="far fa-plus"></i> Add a Block Field</b-btn>
      </div> -->

      <div class="float-right mt-3 mb-5" style="width:100%;">
        <button
          @click="saveAtlas()"
          class="btn btn-dark mr-1"
        >Save</button>
        <button
          @click="closeCreateAtlas()"
          class="btn btn-light"
        >
          Cancel
        </button>
        <button
          v-if="$store.state.display.editMode == true && $store.state.role == 'Administrator'"
          @click="confirmDelete = true"
          class="btn btn-danger mr-1"
        >Delete</button>
      </div><!-- /.float-right -->
    </b-card>
    <!-- ADD BLOCK FIELD MODAL -->
    <!-- <AddBlockField :editMode="editMode" :fieldIndex="fieldIndex" v-if="$store.state.display.modal.addBlockField" /> -->
    <Confirm
      v-if="confirmDelete"
      text="DANGER! Are you sure you want to delete this atlas? This will also delete any datasets that belong to this atlas. This cannot be undone!"
      v-on:confirmed="deleteAtlas($event)"
    />
  </div><!-- /#createAtlas -->
</template>

<script>
// import AddBlockField from './AddBlockField'
import Confirm from '../Confirm'
import firebase from 'firebase'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      confirmDelete: false,
      editMode: false,
      fieldIndex: false
    }
  },
  components: {
    // AddBlockField,
    Confirm
  },
  computed: {
    atlas: {
      get () {
        return this.$store.state.atlas
      },
      set (value) {
        this.$store.commit('setAtlas', value)
      }
    },
    geocoderControl: {
      get () {
        return this.$store.state.geocoderControl
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'geocoderControl', value: value })
      }
    },
    selectMarker: {
      get () {
        return this.$store.state.selectMarker
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'selectMarker', value: value })
      }
    }
  },
  mounted () {
    // if (!this.atlas.blockFields) {
    //   this.atlas.blockFields = []
    // }
    if (this.$store.state.display.editMode === false) {
      this.atlas.name = ''
      this.atlas.geolocation = null
    }
    this.initMapControls()
  },
  destroyed () {
    if (this.selectMarker) {
      this.selectMarker.remove()
      this.$store.commit('setStateProperty', { property: 'selectMarker', value: false })
    }
    this.$store.commit('setEditMode', false)
    this.$store.state.mapObject.removeControl(this.$store.state.geocoderControl)
    this.$store.state.mapObject.off('click', this.addMarker)
  },
  methods: {
    // addBlockField () {
    //   this.editMode = false
    //   this.fieldToEdit = false
    //   this.$store.commit('toggleModal', 'addBlockField')
    // },
    // editBlockField (i) {
    //   this.editMode = true
    //   this.fieldIndex = i
    //   this.$store.commit('toggleModal', 'addBlockField')
    // },
    closeCreateAtlas () {
      this.$store.commit('hideDrawer', 'createAtlas')
      const self = this
      setTimeout(function () {
        self.$store.commit('showDrawer', 'atlasDetails')
      }, 300)
    },
    deleteAtlas (confirmed) {
      const atlasId = this.$store.state.atlas.id
      if (confirmed) {
        this.confirmDelete = false
        if (atlasId === this.$store.state.userProfile.defaultAtlas) {
          fb.usersCollection.doc(this.$store.state.currentUser.uid).update({
            defaultAtlas: false
          })
        }
        // Get all Blocks with this Atlas ID
        fb.blocksCollection.where('atlasId', '==', atlasId).get().then(blocks => {
          // Perform Block Delete process for each
          blocks.forEach(block => {
            fb.blocksCollection.doc(block.id).delete()
          })
          return true
        }).then(() => {
          // Get all Datasets  with this Atlas ID
          return fb.datasetsCollection.where('atlasId', '==', atlasId).get()
        }).then(datasets => {
          // Perform Dataset Delete process for each
          datasets.forEach(dataset => {
            // Delete this datasets files
            const rawPath = dataset.data().file.path
            const rawFileRef = fb.storage.ref(rawPath)
            rawFileRef.delete()
            const geoPath = dataset.data().file.geoJsonPath
            const geoFileRef = fb.storage.ref(geoPath)
            geoFileRef.delete()
            // Delete this datasets document
            fb.datasetsCollection.doc(dataset.id).delete()
          })
          return true
        }).then(() => {
          if (this.atlas.blocksGeoJsonPath) {
            // Delete the Atlas Blocks geoJson and atlas Document
            const blocksPath = this.atlas.blocksGeoJsonPath
            const blocksFileRef = fb.storage.ref(blocksPath)
            blocksFileRef.delete()
          }
          return fb.atlasesCollection.doc(atlasId).delete()
        }).then(() => {
          window.location.href = '/'
        }).catch(err => { console.log(err.message) })
      } else {
        this.confirmDelete = false
        return false
      }
    },
    // initMap () {
    //   const self = this
    //   mapboxgl.accessToken = '' // eslint-disable-line
    //   // If in edit mode, use current atlas coordinates
    //   let center = [-95, 40]
    //   let zoom = 3
    //   let lat
    //   let lng
    //   if (this.$store.state.display.editMode === true && this.$store.state.atlas.geolocation) {
    //     if (this.$store.state.atlas.geolocation.latitude) {
    //       // Get Atlas Coordinates
    //       lat = this.$store.state.atlas.geolocation.latitude
    //       lng = this.$store.state.atlas.geolocation.longitude
    //       center = [lng, lat]
    //       zoom = 16
    //     }
    //   }
    //   /* eslint-disable */
    //   let map = new mapboxgl.Map({
    //     container: 'atlasLocationMap',
    //     style: 'mapbox://styles/mapbox/satellite-v9',
    //     center: center,
    //     zoom: zoom
    //   })
    //   map.scrollZoom.disable()
    //
    //   const geocoder = new MapboxGeocoder({
    //     accessToken: mapboxgl.accessToken,
    //     mapboxgl: mapboxgl,
    //     marker: false
    //   })
    //
    //   map.addControl(geocoder)
    //
    //   geocoder.on('result', function(results) {
    //     // console.log(results)
    //   })
    //
    //   map.addControl(new mapboxgl.NavigationControl())
    //
    //   // CLICK TO ADD MARKER
    //   let selectMarker = null
    //   function addMarker(e) {
    //     if (selectMarker != null) {
    //       selectMarker.remove()
    //     }
    //     let markerOptions = e
    //     markerOptions.color = 'orange'
    //     // markerOptions.draggable = true
    //     selectMarker = new mapboxgl.Marker(markerOptions)
    //       .setLngLat(e.lngLat)
    //       .addTo(map)
    //     self.atlas.geolocation = new firebase.firestore.GeoPoint(e.lngLat.lat, e.lngLat.lng)
    //   }
    //   map.on('click', addMarker)
    //
    //   if (this.$store.state.display.editMode && this.$store.state.atlas.geolocation) {
    //     if (this.$store.state.atlas.geolocation.latitude) {
    //       addMarker({lngLat: {
    //         lng: lng,
    //         lat: lat
    //       }})
    //     }
    //   }
    //
    // },
    addMarker (e) {
      if (this.selectMarker) {
        this.selectMarker.remove()
      }
      const markerOptions = e
      markerOptions.color = '#333'
      // markerOptions.draggable = true
      this.selectMarker = new mapboxgl.Marker(markerOptions) // eslint-disable-line
        .setLngLat(e.lngLat)
        .addTo(this.$store.state.mapObject)
      this.atlas.geolocation = new firebase.firestore.GeoPoint(e.lngLat.lat, e.lngLat.lng)
    },
    initMapControls () {
      const map = this.$store.state.mapObject
      // Check for map ready
      if (map.boxZoom) {
        // Map is ready
        map.removeControl(this.$store.state.zoomControl)
        map.removeControl(this.$store.state.locateControl)
        this.geocoderControl = new MapboxGeocoder({ // eslint-disable-line
          accessToken: mapboxgl.accessToken, // eslint-disable-line
          mapboxgl: mapboxgl, // eslint-disable-line
          marker: false,
          zoom: 16,
          flyTo: {
            animate: false
          }
        })
        map.addControl(this.geocoderControl)
        map.addControl(this.$store.state.zoomControl)
        map.addControl(this.$store.state.locateControl)
        // Setup click marker controls
        map.on('click', this.addMarker)
      } else {
        const self = this
        setTimeout(() => {
          self.initMapControls()
        }, 500)
      }
    },
    saveAtlas () {
      if (!this.atlas.geolocation) {
        alert('You must select a location for your atlas by searching and/or clicking on the map!')
        return false
      }
      const editMode = this.$store.state.display.editMode
      if (editMode === true) {
        fb.atlasesCollection.doc(this.$store.state.atlas.id).update({
          // blockFields: this.atlas.blockFields || [],
          name: this.atlas.name || '',
          geolocation: this.atlas.geolocation || '',
          updatedOn: new Date()
        }).then(() => {
          this.closeCreateAtlas()
        }).catch(err => {
          console.log(err)
        })
      } else {
        // Create the atlas
        fb.atlasesCollection.add({
          // blockFields: this.atlas.blockFields || [],
          name: this.atlas.name || '',
          geolocation: this.atlas.geolocation || '',
          createdOn: new Date(),
          updatedOn: new Date(),
          ownerId: this.$store.state.currentUser.uid,
          users: [],
          permissions: {}
        }).then(ref => {
          window.location.href = '/a/' + ref.id
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>
