import axios from 'axios'
import router from '../router'
import firebase from 'firebase'
const fb = require('../firebaseConfig.js')

export default {
  removeFeatureHighlight ({ commit, state }) {
    const map = state.mapObject
    if (map.getLayer('featureHighlightLayer')) {
      map.removeLayer('featureHighlightBorderLayer')
      map.removeLayer('featureHighlightLayer')
      map.removeSource('featureHighlightSource')
    }
  },
  highlightFeature ({ commit, state }, feature) {
    const map = state.mapObject
    if (map.getLayer('featureHighlightLayer')) {
      map.removeLayer('featureHighlightBorderLayer')
      map.removeLayer('featureHighlightLayer')
      map.removeSource('featureHighlightSource')
    }
    map.addSource('featureHighlightSource', {
      type: 'geojson',
      data: feature
    })
    // DOT HIGHLIGHT
    map.addLayer({
      id: 'featureHighlightBorderLayer',
      type: 'circle',
      source: 'featureHighlightSource',
      paint: {
        'circle-radius': state.dataset.vizSettings.circleRadius + 4,
        'circle-color': 'white'
      }
    })
    // RED DOT
    map.addLayer({
      id: 'featureHighlightLayer',
      type: 'circle',
      source: 'featureHighlightSource',
      paint: {
        'circle-radius': state.dataset.vizSettings.circleRadius + 2,
        'circle-color': 'red'
      }
    })
  },
  renderAtlasMap ({ commit, state }) {
    state.dataset = {}
    // state.display.showMapSettings = false
    state.viz.header = ''
    state.display.showMap = false
    setTimeout(function () {
      state.display.showMap = true
    }, 100)
    setTimeout(() => {
      mapboxgl.accessToken = 'pk.eyJ1IjoidGhlbWFwcGluZ3NlcnZpY2UiLCJhIjoiY2t1aWJ2c3hsMWkwYzJ1cGhyNjRndWhzZiJ9.Dm5XwBzvVMaXNK0BpiQLHA' // eslint-disable-line
      state.mapObject = new mapboxgl.Map({ // eslint-disable-line
        container: 'map',
        style: 'mapbox://styles/themappingservice/ckuibhxr005nf18pk5yl9dnay',
        center: [-95, 40],
        zoom: 3
      })

      // NICK ONLY HARDCODE LIDAR TEMPORARY
      if (state.atlas.id === 'ZuvlomlTzDc9Lm6EtKeu') {
        state.mapObject.on('load', () => {
          const layers = state.mapObject.getStyle().layers
          // Find the index of the first symbol layer in the map style.
          let firstSymbolId
          for (const layer of layers) {
            if (layer.type === 'symbol') {
              firstSymbolId = layer.id
              break
            }
          }
          state.mapObject.addSource(
            'lidarSource',
            {
              type: 'raster',
              tiles: [
                'https://elevation.nationalmap.gov/arcgis/services/3DEPElevation/ImageServer/WMSServer?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=TRUE&width=256&height=256&layers=3DEPElevation:Hillshade Gray'
              ],
              tileSize: 256
            }
          )
          state.mapObject.addLayer(
            {
              id: 'lidarLayer',
              type: 'raster',
              source: 'lidarSource',
              paint: {}
            },
            firstSymbolId
          )
          // state.mapObject.addLayer({
          //   id: 'streets',
          //   type: 'fill',
          //   source: {
          //     type: 'vector',
          //     url: 'mapbox://styles/themappingservice/clgayjzt400e401mkjr9xhedc'
          //   }
          // })
        })
      }
      // END LIDAR

      const navControl = new mapboxgl.NavigationControl() // eslint-disable-line
      commit('setStateProperty', { property: 'zoomControl', value: navControl })
      state.mapObject.addControl(state.zoomControl) // eslint-disable-line

      const geoLocateUser = new mapboxgl.GeolocateControl({ // eslint-disable-line
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
      commit('setStateProperty', { property: 'locateControl', value: geoLocateUser })
      // Add geolocate control to the map.
      state.mapObject.addControl(state.locateControl)

      geoLocateUser.on('geolocate', function (e) {
        state.userLocation = e.coords
      })

      state.mapObject.on('click', function (e) {
        if (state.drawer.copyDataset.position === 'in') {
          // TAKE NO ACTION WHEN USING THE COPY DATASET FUNCTION
        } else {
          // Remove highlighed block layer
          if (state.mapObject.getLayer('blockHighlightLayer')) {
            state.mapObject.removeLayer('blockHighlightLayer')
            state.mapObject.removeSource('blockHighlightLayer')
          }
        }
        // state.display.drawer.blockDetails = 'out'
        // commit('hideDrawer', 'blockDetails')
      })

      if (state.atlas.blocksGeoJsonPath) {
        state.mapObject.on('load', function () {
          // VARS
          const bounds = new mapboxgl.LngLatBounds() // eslint-disable-line
          // Get blocks geoJson File
          const blocksGeoJsonPath = state.atlas.blocksGeoJsonPath
          const blocksGeoJsonStorageRef = firebase.storage().ref(blocksGeoJsonPath)
          blocksGeoJsonStorageRef.getDownloadURL().then(blocksGeoJsonUrl => {
            return axios.get(blocksGeoJsonUrl)
          }).then(response => {
            state.blocksGeoJson = response.data
            // ADD LAYERS
            state.mapObject.addLayer({
              id: 'blocksLayer',
              type: 'fill',
              source: {
                type: 'geojson',
                data: state.blocksGeoJson
              },
              layout: {},
              paint: {
                'fill-color': '#6e59c7', // eslint-disable-line
                'fill-opacity': 0.8, // eslint-disable-line
              }
            })
            state.mapObject.addLayer({
              id: 'blocksLayerOutline',
              type: 'line',
              source: {
                type: 'geojson',
                data: state.blocksGeoJson
              },
              layout: {},
              paint: {
                'line-width': 2,
                'line-color': '#eee'
              }
            })
            // CLICK EVENTS
            state.mapObject.on('click', 'blocksLayer', function (e) {
              // Close drawers
              commit('hideAllDrawers')
              // state.drawer.datasets = {
              //   show: false,
              //   position: 'out'
              // }
              // state.display.drawer = {
              //   blockDetails: 'out',
              //   datasetDetails: 'out',
              //   datasets: 'out',
              //   dataPointDetails: 'out',
              //   copyDatasetDrawer: 'out',
              //   createBlockDrawer: 'out',
              //   createMapDrawer: 'out',
              //   createDatasetDrawer: 'out',
              //   // maps: 'out',
              //   // mapDetails: 'out',
              //   atlases: 'out',
              //   userDetails: 'out',
              //   notifications: 'out',
              //   plugins: 'out',
              //   collectors: 'out',
              //   collector: 'out'
              // }
              // Add highlighted block layer
              if (state.mapObject.getLayer('blockHighlightLayer')) {
                state.mapObject.removeLayer('blockHighlightLayer')
                state.mapObject.removeSource('blockHighlightLayer')
              }
              state.mapObject.addLayer({
                id: 'blockHighlightLayer',
                type: 'fill',
                source: {
                  type: 'geojson',
                  data: e.features[0]
                },
                layout: {},
                paint: {
                  'fill-color': '#ffc107', // eslint-disable-line
                  'fill-opacity': 0.8, // eslint-disable-line
                }
              })
              // Get block data and open block drawer
              fb.blocksCollection.doc(e.features[0].properties.blockId).get().then(doc => {
                state.block = doc.data()
                state.block.id = doc.id
                // state.display.drawer.blockDetails = 'in'
                setTimeout(() => {
                  commit('showDrawer', 'blockDetails')
                }, 300)
              }).catch(err => {
                console.log(err.message)
              })
            })
            // MOUSE ENTER AND LEAVE POINTERS
            state.mapObject.on('mouseenter', 'blocksLayer', function () {
              state.mapObject.getCanvas().style.cursor = 'pointer'
            })
            state.mapObject.on('mouseleave', 'blocksLayer', function () {
              state.mapObject.getCanvas().style.cursor = ''
            })
            // BOUNDS UPDATE ONLY IF THERE ARE FEATURES
            if (state.blocksGeoJson.features.length > 0) {
              state.blocksGeoJson.features.forEach(feature => {
                feature.geometry.coordinates[0].forEach(coordinatePair => {
                  if (isNaN(coordinatePair[0]) || isNaN(coordinatePair[1])) {
                  } else {
                    bounds.extend(coordinatePair)
                  }
                })
              })
              state.mapObject.fitBounds(bounds, {
                padding: 50,
                animate: false
              })
            } else {
              const lat = state.atlas.geolocation.latitude
              const lng = state.atlas.geolocation.longitude
              state.mapObject.flyTo({
                center: [lng, lat],
                zoom: 15,
                animate: false
              })
            }
            state.loadingMap = false
          }) // axios get blocksGeoJson
        }) // map.on load
      } else {
        const lat = state.atlas.geolocation.latitude
        const lng = state.atlas.geolocation.longitude
        state.mapObject.flyTo({
          center: [lng, lat],
          zoom: 15,
          animate: false
        })
        state.loadingMap = false
      }
      // commit('resetKey', 'blocks')
    }, 300)
  },
  initAtlasMap ({ commit, state, dispatch }) {
    commit('setStateProperty', { property: 'selectBlocksListenersAdded', value: false })
    if (state.loadingMap === false) {
      commit('setStateProperty', { property: 'loadingMap', value: true })
    }
    if (state.atlas.recompileBlocks === true) {
      commit('setStateProperty', { property: 'alert', value: { active: true, message: 'Changes have been made to your blocks. Recompiling now...', level: 'alert-dark' } })
      fb.compileBlocksRequestsCollection.add({
        complete: false,
        createdBy: state.currentUser.uid,
        createdOn: new Date(),
        atlasId: state.atlas.id,
        oldGeoJsonPath: state.atlas.blocksGeoJsonPath || false
      }).then(doc => {
        const requestId = doc.id
        fb.compileBlocksRequestsCollection.doc(requestId).onSnapshot(updatedDoc => {
          const updatedRequest = updatedDoc.data()
          updatedRequest.id = updatedDoc.id
          if (updatedRequest.complete) {
            fb.atlasesCollection.doc(state.atlas.id).update({
              recompileBlocks: false,
              updatedBy: state.currentUser.uid,
              updatedOn: new Date()
            }).then(() => {
              return fb.atlasesCollection.doc(state.atlas.id).get()
            }).then(doc => {
              const updatedAtlas = doc.data()
              updatedAtlas.id = doc.id
              commit('setStateProperty', { property: 'atlas', value: updatedAtlas })
              // commit('initAtlasMap')
              dispatch('renderAtlasMap')
              commit('setStateProperty', { property: 'alert', value: { active: false, message: 'Changes have been made to your blocks. Recompiling now...', level: 'alert-dark' } })
            }).catch(err => {
              console.log(err.message)
            })
          }
        })
      })
    } else {
      // commit('initAtlasMap')
      dispatch('renderAtlasMap')
    }
  },
  collect ({ commit, state }, collector) {
    if (!state.display.editMode) {
      commit('setStateProperty', { property: 'dataPoint', value: {} })
    }
    console.log(state.dataPoint)
    const collectorObj = collector
    collectorObj.collecting = true
    collectorObj.selectMarker = null
    commit('setStateProperty', { property: 'collector', value: collectorObj })
    const map = state.mapObject
    // CLICK TO ADD MARKER
    map.on('click', e => {
      if (state.collector.collecting) {
        if (state.collector.selectMarker != null) {
          state.collector.selectMarker.remove()
        }
        const markerOptions = e
        markerOptions.color = 'black'
        // markerOptions.draggable = true
        collectorObj.selectMarker = new mapboxgl.Marker(markerOptions) // eslint-disable-line
          .setLngLat(e.lngLat)
          .addTo(map)

        collectorObj.selectedLocation = new firebase.firestore.GeoPoint(e.lngLat.lat, e.lngLat.lng)
        commit('setStateProperty', { property: 'collector', value: collectorObj })
        console.log(state.collector)
        axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + e.lngLat.lng + ',' + e.lngLat.lat + '.json?access_token=pk.eyJ1IjoidGhlbWFwcGluZ3NlcnZpY2UiLCJhIjoiY2t1aWJ2c3hsMWkwYzJ1cGhyNjRndWhzZiJ9.Dm5XwBzvVMaXNK0BpiQLHA').then(({ data }) => {
          new mapboxgl.Popup({ closeOnClick: true, offset: 35, closeButton: false }) // eslint-disable-line
            .setLngLat([e.lngLat.lng, e.lngLat.lat])
            .setHTML(data.features[0].place_name)
            .addTo(map)
        }).catch(err => {
          console.log(err)
        })
      }
    })
    // commit('setDrawer', { name: 'collector', position: 'in' })
    commit('showDrawer', 'collector')
    // setTimeout(function () {
    //   const geolocateButton = document.getElementsByClassName('mapboxgl-ctrl-geolocate')
    //   geolocateButton[0].click()
    // }, 300)
  },
  removeAllLayers ({ commit, state }) {
    state.viz = {}
    if (state.mapObject.getLayer('blocksLayer')) {
      state.mapObject.removeLayer('blocksLayer')
      state.mapObject.removeLayer('blocksLayerOutline')
      state.mapObject.removeSource('blocksLayer')
      state.mapObject.removeSource('blocksLayerOutline')
    }
    if (state.mapObject.getLayer('blockHighlightLayer')) {
      state.mapObject.removeLayer('blockHighlightLayer')
    }
    if (state.mapObject.getLayer('blocksGreyedLayer')) {
      state.mapObject.removeLayer('blocksGreyedLayer')
      state.mapObject.removeSource('blocksGreyedLayer')
    }
    if (state.mapObject.getLayer('Footprint')) {
      state.mapObject.removeLayer('Footprint')
    }
    if (state.mapObject.getLayer('geojsonMapPreview')) {
      state.mapObject.removeLayer('geojsonMapPreview')
    }
    if (state.mapObject.getSource('geoJsonSource')) {
      state.mapObject.removeSource('geoJsonSource')
    }
    if (state.mapObject.getLayer('interpolation')) {
      state.mapObject.removeLayer('interpolation')
    }
    if (state.mapObject.getSource('interpolatedGeoJsonSource')) {
      state.mapObject.removeSource('interpolatedGeoJsonSource')
    }
    // Remove preview if already exists
    if (state.mapObject.getLayer('datasetPreview')) {
      state.mapObject.removeLayer('datasetPreview')
    }
    if (state.mapObject.getLayer('datasetPreviewHeatMap')) {
      state.mapObject.removeLayer('datasetPreviewHeatMap')
    }
    if (state.mapObject.getLayer('datasetPreviewPolygon')) {
      state.mapObject.removeLayer('datasetPreviewPolygon')
    }
    if (state.mapObject.getSource('datasetPreviewGeojson')) {
      state.mapObject.removeSource('datasetPreviewGeojson')
    }
    if (state.mapObject.getLayer('selectedDataPoint')) {
      state.mapObject.removeLayer('selectedDataPoint')
      state.mapObject.removeLayer('selectedDataPointHighlight')
      state.mapObject.removeSource('selectedDataPoint')
    }
    if (state.mapObject.getLayer('rasterLayer')) {
      state.mapObject.removeLayer('rasterLayer')
      state.mapObject.removeSource('rasterSource')
    }
  },
  mapDataset ({ commit, state, dispatch }, datasetItem) {
    commit('setStateProperty', { property: 'dataset', value: {} })
    dispatch('removeAllLayers')
    let showDrawer = true
    if (datasetItem.doc) {
      showDrawer = datasetItem.drawer
      datasetItem = datasetItem.doc
    }
    commit('setStateProperty', { property: 'showDatasetVizSettings', value: false })
    if (datasetItem.dynamic) {
      // Get the collector
      fb.collectorsCollection.doc(datasetItem.collectorId).get().then(collectorDoc => {
        const collectorData = collectorDoc.data()
        collectorData.id = collectorDoc.id
        commit('setStateProperty', { property: 'collector', value: collectorData })
        const collectorUpdated = collectorData.updatedOn.toDate().getTime()
        const dataSetUpdated = datasetItem.updatedOn.toDate().getTime()
        if (collectorUpdated > dataSetUpdated) {
          commit('setAlert', { active: true, message: 'There is new data available in this dataset. Compiling now...', level: 'alert-dark' })
          fb.compileRequestsCollection.add({
            createdBy: state.currentUser.uid,
            createdOn: new Date(),
            collectorId: collectorData.id,
            datasetId: datasetItem.id,
            lastUpdated: datasetItem.updatedOn,
            atlasId: state.atlas.id
          }).then(() => {
            return fb.datasetsCollection.doc(datasetItem.id).update({
              updatedOn: new Date()
            }).then(() => {
            })
          }).catch(err => {
            console.log(err)
          })
        } else if (!datasetItem.file.geoJsonPath) {
          commit('setAlert', { active: true, message: 'No data in this dataset. You must collect data before viewing.', level: 'alert-dark' })
          return false
        }
      }).catch(err => { console.log(err) })
    }
    commit('setMapLoader', true)
    commit('hideAllDrawers')
    commit('setDataset', datasetItem)
    const mapObject = state.mapObject

    // CLICK ON DATAPOINTS
    function onClickFunction (e) {
      if (state.drawer.copyDataset.position === 'in' || state.drawer.collector.position === 'in') {
        return false
      } else {
        // HIGHLIGHT THE DATAPOINT
        const features = mapObject.queryRenderedFeatures(e.point, { layers: ['datasetPreview'] })
        if (!features.length) { return }
        if (typeof mapObject.getLayer('selectedDataPoint') !== 'undefined') {
          mapObject.removeLayer('selectedDataPoint')
          mapObject.removeLayer('selectedDataPointHighlight')
          mapObject.removeSource('selectedDataPoint')
        }
        const feature = features[0]
        // SOURCE FOR THE SELECTED POINT
        mapObject.addSource('selectedDataPoint', {
          type: 'geojson',
          data: feature.toJSON()
        })
        // DOT HIGHLIGHT
        mapObject.addLayer({
          id: 'selectedDataPointHighlight',
          type: 'circle',
          source: 'selectedDataPoint',
          paint: {
            'circle-radius': state.dataset.vizSettings.circleRadius + 4,
            'circle-color': 'white'
          }
        })
        // RED DOT
        mapObject.addLayer({
          id: 'selectedDataPoint',
          type: 'circle',
          source: 'selectedDataPoint',
          paint: {
            'circle-radius': state.dataset.vizSettings.circleRadius + 2,
            'circle-color': 'red'
          }
        })
        const dataPointId = e.features[0].properties.dataPointId
        // PUT AWAY ANY DATAPOINT DRAWERS
        // commit('setDrawer', { name: 'dataPointDetails', position: 'out' })
        commit('tuckDrawer', 'datasetDetails')
        commit('hideDrawer', 'dataPointDetails')
        commit('hideDrawer', 'datasets')
        commit('hideDrawer', 'revisions')
        setTimeout(() => {
          // commit('setStateProperty', { property: 'showDataPointDetails', value: false })
          // commit('setDrawer', { name: 'datasetDetails', position: 'out' })
          // HANDLE DYNAMIC DATASETS
          if (state.dataset.dynamic) {
            fb.collectorsCollection.doc(state.dataset.collectorId).collection('dataPoints').doc(dataPointId).get().then(doc => {
              const dataPoint = doc.data()
              dataPoint.id = doc.id
              return commit('setStateProperty', { property: 'dataPoint', value: dataPoint })
            }).then(() => {
              // commit('setStateProperty', { property: 'showDataPointDetails', value: true })
              // commit('setDrawer', { name: 'dataPointDetails', position: 'in' })
              commit('showDrawer', 'dataPointDetails')
            })
          } else {
            // HANDLE STATIC DATASETS
            const dataPoint = features[0].properties
            // LIST OUT PROPERTIES ARRAY IN DATAPOINT
            dataPoint.fields = Object.keys(dataPoint)
            commit('setStateProperty', { property: 'dataPoint', value: dataPoint })
            // commit('setStateProperty', { property: 'showDataPointDetails', value: true })
            // commit('setDrawer', { name: 'dataPointDetails', position: 'in' })
            commit('showDrawer', 'dataPointDetails')
          }
        }, 300)
      }
    }

    mapObject.off('click', 'datasetPreview', onClickFunction)

    // Add GeoJSON dataset preview
    if (datasetItem.file.geoJsonPath) {
      const geoJsonPath = datasetItem.file.geoJsonPath
      const geoJsonStorageRef = firebase.storage().ref(geoJsonPath)
      geoJsonStorageRef.getDownloadURL().then(geoFileUrl => {
        if (state.mapObject.getLayer('datasetPreview')) {
          state.mapObject.removeLayer('datasetPreview')
        }
        if (state.mapObject.getSource('datasetPreviewGeojson')) {
          state.mapObject.removeSource('datasetPreviewGeojson')
        }
        mapObject.addSource('datasetPreviewGeojson', {
          type: 'geojson',
          data: geoFileUrl
        })
        let circlePaint = { 'circle-radius': state.defaults.pointStops, 'circle-color': '#ffc107' }
        if (state.dataset.dynamic) {
          circlePaint = { 'circle-radius': 10, 'circle-color': '#ffc107' }
        }
        mapObject.addLayer({
          id: 'datasetPreview',
          type: 'circle',
          source: 'datasetPreviewGeojson',
          layout: {},
          paint: circlePaint
        })
        mapObject.on('click', 'datasetPreview', onClickFunction)
        // Change the cursor to a pointer when the mouse is over the places layer.
        mapObject.on('mouseenter', 'datasetPreview', function () {
          mapObject.getCanvas().style.cursor = 'pointer'
        })
        // Change it back to a pointer when it leaves.
        mapObject.on('mouseleave', 'datasetPreview', function () {
          mapObject.getCanvas().style.cursor = ''
        })
        axios.get(geoFileUrl).then(response => {
          commit('setStateProperty', { property: 'datasetGeoJson', value: response.data })
          commit('setStateProperty', { property: 'showDatasetVizSettings', value: true })
          if (showDrawer) {
            commit('showDrawer', 'datasetDetails')
          }
          setTimeout(() => {
            mapObject.fitBounds(turf.bbox(response.data), { // eslint-disable-line
              animate: true,
              padding: 20,
              duration: 490
            })
          }, 1000)
        })
      })
    }
    // HANDLE RASTER DATASETS
    if (datasetItem.file.raster) {
      // Go get the download URL for jpg file
      const jpgPath = datasetItem.file.jpgPath
      const jpgStorageRef = firebase.storage().ref(jpgPath)
      mapObject.fitBounds([
        [datasetItem.file.xMin, datasetItem.file.yMin],
        [datasetItem.file.xMax, datasetItem.file.yMax]
      ], {
        animate: false
      })
      jpgStorageRef.getDownloadURL().then(fileUrl => {
        mapObject.addSource('rasterSource', {
          type: 'image',
          url: fileUrl,
          coordinates: [
            [datasetItem.file.xMin, datasetItem.file.yMax], // top left
            [datasetItem.file.xMax, datasetItem.file.yMax], // top right
            [datasetItem.file.xMax, datasetItem.file.yMin], // bottom right
            [datasetItem.file.xMin, datasetItem.file.yMin] // bottom left
          ]
        })
        mapObject.addLayer({
          id: 'rasterLayer',
          source: 'rasterSource',
          type: 'raster',
          paint: {
            'raster-opacity': 0.85
          }
        })
        if (showDrawer) {
          setTimeout(function () {
            commit('showDrawer', 'datasetDetails')
          }, 300)
        }
        commit('setStateProperty', { property: 'loadingMap', value: false })
      }).catch(err => {
        console.log(err.message)
      })
    }
    if (router.currentRoute.params.layerId !== state.dataset.id) {
      router.push({
        name: 'Atlas',
        params: {
          id: state.atlas.id,
          layerType: 'dataset',
          layerId: state.dataset.id
        }
      })
    }
  },
  clearUserData ({ commit }) {
    commit('setCurrentUser', null)
    commit('setUserProfile', {})
  },
  fetchUserProfile ({ commit, state }) {
    fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
      commit('setUserProfile', res.data())
    }).catch(err => {
      console.log(err)
    })
  },
  fetchAtlas ({ commit, state }, atlasId) {
    let atlas
    fb.atlasesCollection.doc(atlasId).onSnapshot(doc => {
      atlas = doc.data()
      atlas.id = doc.id
      const userId = state.currentUser.uid
      if (atlas.permissions[userId]) {
        commit('setStateProperty', { property: 'role', value: atlas.permissions[userId] })
      } else if (atlas.ownerId === userId) {
        commit('setStateProperty', { property: 'role', value: 'Administrator' })
      } else {
        commit('setStateProperty', { property: 'role', value: '' })
      }
      // Get atlas owner details
      fb.usersCollection.doc(atlas.ownerId).get().then(user => {
        atlas.ownerData = user.data()
        atlas.ownerData.id = user.id
      })
      // Get collaborators' details and push to the atlas object
      atlas.collaboratorData = []
      for (let i = 0; i < atlas.users.length; i++) {
        const user = atlas.users[i].id
        fb.usersCollection.doc(user).get().then(user => {
          const userData = user.data()
          userData.id = user.id
          atlas.collaboratorData.push(userData)
        })
      }
      return commit('setAtlas', atlas)
    })
  },
  fetchAtlases ({ commit, state }) {
    const userId = state.currentUser.uid
    const atlases = []
    // First get atlases I own
    return fb.atlasesCollection.where('ownerId', '==', userId).orderBy('createdOn', 'desc').limit(300).get().then(snapshots => {
      snapshots.forEach(doc => {
        const item = doc.data()
        item.id = doc.id
        item.role = 'Administrator'
        atlases.push(item)
      })
      // Then get atlases I collaborate on
      return fb.atlasesCollection.where('users', 'array-contains', { id: userId }).orderBy('createdOn', 'desc').limit(300).get()
      // return true
    }).then(snapshots => {
      snapshots.forEach(function (doc) {
        const item = doc.data()
        item.id = doc.id
        item.role = item.permissions[userId]
        atlases.push(item)
      })
      return commit('setAtlases', atlases)
    }).catch(err => {
      console.log(err)
    })
  },
  fetchBlock ({ commit, state }, blockId) {
    let block
    fb.blocksCollection.doc(blockId).get().then(doc => {
      block = doc.data()
      block.id = doc.id
      return commit('setBlock', block)
    })
  },
  fetchBlocks ({ commit, state }, atlasId) {
    const blocks = []
    fb.blocksCollection.where('atlasId', '==', atlasId).orderBy('createdOn', 'desc').limit(300).get().then(snapshots => {
      snapshots.forEach(doc => {
        const item = doc.data()
        item.id = doc.id
        blocks.push(item)
      })
      return commit('setBlocks', blocks)
    }).catch(err => {
      console.log(err)
    })
  },
  fetchCollectors ({ commit, state }, atlasId) {
    fb.collectorsCollection.where('atlasId', '==', atlasId).where('trashed', '==', false).orderBy('createdOn', 'desc').limit(300).onSnapshot(snapshots => {
      const collectors = []
      snapshots.forEach(doc => {
        const item = doc.data()
        item.id = doc.id
        collectors.push(item)
      })
      return commit('setCollectors', collectors)
    })
  },
  fetchFolders ({ commit, state }, atlasId) {
    fb.foldersCollection.where('atlasId', '==', atlasId).where('folderId', '==', state.folder.id).orderBy('name', 'asc').onSnapshot(docs => {
      const folders = []
      docs.forEach(doc => {
        const folder = doc.data()
        folder.id = doc.id
        folders.push(folder)
      })
      commit('setStateProperty', { property: 'folders', value: folders })
    })
  },
  fetchDatasets ({ commit, state }, atlasId) {
    let order = 'createdOn'
    let direction = 'desc'
    // const tagFilter = state.filterBy
    if (state.paginate === false) {
      commit('setDatasets', [])
    }
    if (state.sortBy === 'Most Recent') {
      order = 'updatedOn'
      direction = 'desc'
    } else if (state.sortBy === 'Oldest') {
      order = 'updatedOn'
      direction = 'asc'
    } else if (state.sortBy === 'Name A-Z') {
      order = 'name'
      direction = 'asc'
    } else if (state.sortBy === 'Name Z-A') {
      order = 'name'
      direction = 'desc'
    }
    let datasetsQuery
    if (state.datasets.length < 1) {
      datasetsQuery = fb.datasetsCollection.where('atlasId', '==', atlasId).where('folderId', '==', state.folder.id).orderBy(order, direction).limit(30)
      // if (state.filterBy === '') {
      //   datasetsQuery = fb.datasetsCollection.where('atlasId', '==', atlasId).where('folderId', '==', state.folder.id).orderBy(order, direction).limit(30)
      // } else {
      //   datasetsQuery = fb.datasetsCollection.where('atlasId', '==', atlasId).where('folderId', '==', state.folder.id).where('tags', 'array-contains', tagFilter).orderBy(order, direction).limit(30)
      // }
    } else {
      const lastVisible = state.lastVisibleDataset
      datasetsQuery = fb.datasetsCollection.where('atlasId', '==', atlasId).where('folderId', '==', state.folder.id).orderBy(order, direction).startAfter(lastVisible).limit(30)
      // if (state.filterBy === '') {
      //   datasetsQuery = fb.datasetsCollection.where('atlasId', '==', atlasId).where('folderId', '==', state.folder.id).orderBy(order, direction).startAfter(lastVisible).limit(30)
      // } else {
      //   datasetsQuery = fb.datasetsCollection.where('atlasId', '==', atlasId).where('folderId', '==', state.folder.id).where('tags', 'array-contains', tagFilter).orderBy(order, direction).startAfter(lastVisible).limit(30)
      // }
    }

    datasetsQuery.onSnapshot(snapshots => {
      if (state.paginate === false) {
        commit('setDatasets', [])
      }
      commit('setStateProperty', { property: 'lastVisibleDataset', value: snapshots.docs[snapshots.docs.length - 1] })
      const datasets = state.datasets
      // const datasetTags = state.datasetTags
      snapshots.forEach(doc => {
        const item = doc.data()
        item.id = doc.id
        datasets.push(item)
      })
      commit('setStateProperty', { property: 'paginate', value: false })
      // commit('setDatasetTags', datasetTags)
      return commit('setDatasets', datasets)
    })
  }
  // fetchMaps ({ commit, state }, atlasId) {
  //   let order = 'createdOn'
  //   let direction = 'desc'
  //   const tagFilter = state.filterBy
  //   if (state.sortBy === 'Most Recent') {
  //     order = 'createdOn'
  //     direction = 'desc'
  //   } else if (state.sortBy === 'Oldest') {
  //     order = 'createdOn'
  //     direction = 'asc'
  //   } else if (state.sortBy === 'Name A-Z') {
  //     order = 'name'
  //     direction = 'asc'
  //   } else if (state.sortBy === 'Name Z-A') {
  //     order = 'name'
  //     direction = 'desc'
  //   }
  //   let mapsQuery
  //   if (state.filterBy === '') {
  //     mapsQuery = fb.mapsCollection.where('atlasId', '==', atlasId).orderBy(order, direction).limit(300)
  //   } else {
  //     mapsQuery = fb.mapsCollection.where('atlasId', '==', atlasId).where('tags', 'array-contains', tagFilter).orderBy(order, direction).limit(300)
  //   }
  //   mapsQuery.onSnapshot(snapshots => {
  //     const maps = []
  //     const tags = []
  //     snapshots.forEach(doc => {
  //       const item = doc.data()
  //       item.id = doc.id
  //       maps.push(item)
  //       if (item.tags) {
  //         item.tags.forEach(tag => {
  //           if (!tags.includes(tag)) {
  //             tags.push(tag)
  //           }
  //         })
  //       }
  //       commit('setTags', tags)
  //     })
  //     return commit('setMaps', maps)
  //   })
  // }
}
