<template>
  <div id="datasetFilters">
    <p class="mb-1 mt-4"><strong>Filters</strong></p>
    <button
      class="btn btn-dark"
      @click="addFilter()"
    ><i class="fas fa-plus"></i> Add Filter</button>

    <div class="mt-3">
      <!-- Filter Selection -->
      <button
        v-for="(filter, i) in dataset.vizSettings.filters"
        v-bind:key="i"
        class="btn btn-sm btn-dark mr-1"
        :class="{ selected: (i === selectedFilter) }"
        @click="selectFilter(i)"
      ><i class="fal fa-filter"></i> Filter {{ i + 1 }}<span v-if="filter.header">: {{ filter.header }}</span></button>
    </div>

    <div
      v-if="dataset.vizSettings.filters.length > 0"
      class="filter"
    >
      <!-- Remove Filter -->
      <button
        class="btn btn-danger btn-sm float-right"
        @click="removeFilter(selectedFilter)"
      >Remove</button>

      <!-- Filter Header Seection -->
      <p
        v-if="dataset.headers"
        class="mb-0"
      >
        <strong>Filter {{ selectedFilter + 1 }}<span v-if="dataset.vizSettings.filters[selectedFilter].header">: {{ dataset.vizSettings.filters[selectedFilter].header }}</span></strong>
      </p>
      <div
        v-for="(header, index) in dataset.headers"
        v-bind:key="index"
        class="btn btn-sm btn-dark mt-1 mr-1 clickable"
        @click="selectHeader(header, selectedFilter)"
        :class="{ selected: (header === dataset.vizSettings.filters[selectedFilter].header) }"
      >
        {{ header }}
      </div>

      <!-- HISTOGRAM SLIDER -->
      <HistogramSlider
        v-if="dataset.vizSettings.filters[selectedFilter].type == 'number' && showHist && dataset.vizSettings.filters[selectedFilter].header"
        style="margin: 20px auto"
        :width="histWidth"
        :bar-height="200"
        :data="histValues"
        :forceEdges="false"
        :colors="['white', 'black']"
        :step=".01"
        :min="dataset.vizSettings.filters[selectedFilter].min"
        :max="dataset.vizSettings.filters[selectedFilter].max"
        :transitionDuration="500"
        :primaryColor="'black'"
        :labelColor="'black'"
        :holderColor="'#dc3545'"
        :gridTextColor="'black'"
        :grid="false"
        v-on:change="changeHist($event, selectedFilter)"
        :histSliderGap="30"
      />

      <!-- MIN MAX VALUES -->
      <div
        class="form-group mt-2"
        v-if="dataset.vizSettings.filters[selectedFilter].type == 'number' && showHist && dataset.vizSettings.filters[selectedFilter].header"
      >
        <div class="form-row ml-1 mr-1 mb-3">
          <div class="col">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Min</span>
              </div><!-- /.input-group-prepend -->
              <input
                v-model.number="dataset.vizSettings.filters[selectedFilter].min"
                type="number"
                class="form-control"
                v-on:change="changeMinMaxForm(selectedFilter)"
              />
            </div><!-- /.input-group -->
          </div><!-- /.col -->
          <div class="col">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Max</span>
              </div><!-- /.input-group-prepend -->
              <input
                v-model.number="dataset.vizSettings.filters[selectedFilter].max"
                type="number"
                class="form-control"
                v-on:change="changeMinMaxForm(selectedFilter)"
              />
            </div><!-- /.input-group -->
          </div><!-- /.col -->
        </div><!-- /.form-row -->
        <div class="form-row mt-2 ml-1">
          <div class="col-10">
            <button
              @click="trimData()"
              class="btn btn-dark w-100"
            >
              <i class="fas fa-cut"></i> Trim Data
            </button>
          </div><!-- /.col -->
          <div class="col">
            <button
              @click="resetRange(selectedFilter)"
              class="btn btn-light"
              v-b-tooltip.hover.top="'Reset Trim'"
            >
              <i class="fas fa-sync-alt"></i>
            </button>
          </div><!-- /.col -->
        </div><!-- /.form-row -->
      </div><!-- /.form-group -->

    </div><!-- /.filter -->
  </div><!-- /#datasetFilters -->
</template>

<script>
import HistogramSlider from 'vue-histogram-slider'
import 'vue-histogram-slider/dist/histogram-slider.css'

export default {
  data () {
    return {
      showHist: false,
      selectedFilter: 0,
      histValues: [],
      lastChangeHist: 0,
      histWidth: 400
    }
  },
  components: {
    HistogramSlider
  },
  computed: {
    dataset: {
      get () {
        return this.$store.state.dataset
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'dataset', value: value })
      }
    }
  },
  created () {
    const self = this
    setTimeout(function () {
      if (self.dataset.vizSettings.filters.length > 0) {
        self.getHistValues(0)
      }
    }, 2000)
  },
  methods: {
    addFilter () {
      if (!this.dataset.vizSettings.filters) {
        this.dataset.vizSettings.filters = []
      }
      this.dataset.vizSettings.filters.push({
        header: false,
        type: 'number',
        min: false,
        max: false
      })
      this.selectedFilter = this.dataset.vizSettings.filters.length - 1
      this.$emit('saveViz')
    },
    removeFilter (i) {
      this.showHist = false
      this.dataset.vizSettings.filters.splice(i, 1)
      this.selectedFilter = 0
      this.$emit('initViz')
      const self = this
      if (this.dataset.vizSettings.filters.length > 0) {
        setTimeout(() => {
          self.getHistValues(0)
        }, 100)
      }
    },
    selectFilter (i) {
      this.showHist = false
      this.selectedFilter = i
      const self = this
      setTimeout(function () {
        self.getHistValues(i)
      }, 100)
    },
    selectHeader (header, i) {
      // Set header
      this.dataset.vizSettings.filters[i].header = header
      // Make sure to hide histogram
      this.showHist = false
      // Reset min and max
      this.dataset.vizSettings.filters[i].min = false
      this.dataset.vizSettings.filters[i].max = false
      // Make sure the header is numbers
      this.$store.state.datasetGeoJson.features.forEach((feature, index) => {
        let featureValue = feature.properties[header]
        // Check to see if the property can be considered a number
        const notNumber = isNaN(featureValue)
        if (notNumber) {
          // If any feature is not a number, return function and throw a warning
          console.log('Not number')
          this.dataset.vizSettings.filters[i].type = 'category'
        } else {
          // It is a number, now check to see if the property is stored
          // as a string and if so, convert to number
          if (typeof featureValue === 'string') {
            featureValue = Number(featureValue)
            this.$store.state.datasetGeoJson.features[index].properties[header] = featureValue
          }
          if (this.dataset.vizSettings.filters[i].min === false) {
            this.dataset.vizSettings.filters[i].min = featureValue
            this.dataset.vizSettings.filters[i].max = featureValue
          }
          if (featureValue < this.dataset.vizSettings.filters[i].min) {
            this.dataset.vizSettings.filters[i].min = featureValue
          }
          if (featureValue > this.dataset.vizSettings.filters[i].max) {
            this.dataset.vizSettings.filters[i].max = featureValue
          }
        }
      })
      const self = this
      setTimeout(function () {
        self.getHistValues(i)
      }, 300)
      this.$emit('initViz')
    },
    getHistValues (i) {
      // Clear Hist Values
      this.histValues = []
      const header = this.dataset.vizSettings.filters[i].header
      // Check screen width
      const viewportWidth = window.innerWidth
      if (viewportWidth < 500) {
        this.histWidth = viewportWidth - 100
      }

      this.$store.state.datasetGeoJson.features.forEach(feature => {
        const value = feature.properties[header]
        let passFilters = true
        // Does this datapoint pass all filters?
        this.dataset.vizSettings.filters.forEach(filter => {
          const filterProperty = filter.header
          const featureValue = feature.properties[filterProperty]
          if (featureValue < filter.min || featureValue > filter.max) {
            passFilters = false
          }
        })
        if (passFilters) {
          this.histValues.push(value)
        }
      })
      this.showHist = true
    },
    changeHist (e, i) {
      this.dataset.vizSettings.filters[i].min = e.from
      this.dataset.vizSettings.filters[i].max = e.to
      const now = new Date().getTime()
      const change = now - this.lastChangeHist
      if (change > 300) {
        this.lastChangeHist = now
        this.highlightTrim(i)
      }
    },
    highlightTrim (i) {
      const map = this.$store.state.mapObject
      const filter = this.dataset.vizSettings.filters[i]
      const toTrimLayer = map.getLayer('toTrim')
      if (toTrimLayer) {
        map.removeLayer('toTrim')
      }
      map.addLayer({
        id: 'toTrim',
        type: 'circle',
        source: 'datasetPreviewGeojson',
        layout: {},
        paint: {
          'circle-radius': this.dataset.vizSettings.circleRadius,
          'circle-color': '#dc3545'
        }
      })
      const filterMin = ['<=', ['number', ['get', filter.header]], filter.min]
      const filterMax = ['>=', ['number', ['get', filter.header]], filter.max]
      map.setFilter('toTrim', ['any', filterMin, filterMax])
    },
    trimData () {
      const map = this.$store.state.mapObject
      const toTrimLayer = map.getLayer('toTrim')
      if (toTrimLayer) {
        map.removeLayer('toTrim')
      }
      this.showHist = false
      // Check to see if this filters header is the colorHeader. If so, set primary minValue and maxValue
      // if (this.dataset.vizSettings.filters[this.selectedFilter].header == this.dataset.vizSettings.colorHeader) {
      //   this.dataset.vizSettings.minValue = this.dataset.vizSettings.filters[this.selectedFilter].min
      //   this.dataset.vizSettings.maxValue = this.dataset.vizSettings.filters[this.selectedFilter].max
      // }
      this.$emit('initViz')
      const self = this
      setTimeout(function () {
        self.getHistValues(self.selectedFilter)
      }, 100)
    },
    resetRange () {
      this.showHist = false
      const map = this.$store.state.mapObject
      const toTrimLayer = map.getLayer('toTrim')
      if (toTrimLayer) {
        map.removeLayer('toTrim')
      }
      // this.dataset.vizSettings.filters[this.selectedFilter].min = false
      // this.dataset.vizSettings.filters[this.selectedFilter].max = false
      const self = this
      setTimeout(function () {
        self.selectHeader(self.dataset.vizSettings.filters[self.selectedFilter].header, self.selectedFilter)
      }, 100)
    }
  }
}
</script>

<style scoped>
.filter {;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}
</style>
