<template>
  <div id="previewRevision">
    <!-- Data Point Details -->
    <b-card
      id="revisionDetailsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.previewRevision.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="hideDataPointDetails()"
        >
          <i class="far fa-times"></i>
        </a>
        <!-- Data Point Title -->
        <h4 class="text-warning">Data Point (Past Version)</h4>
      </template>
      <div v-if="$store.state.dataset.dynamic">
        <!-- FIELDS -->
        <div
          v-for="(field, i) in $store.state.collector.fields"
          v-bind:key="i"
        >
          <div v-if="$store.state.revision[field.machine_name]">
            <!-- TEXT FIELDS -->
            <p
              v-if="field.type !== 'Image' && field.type !== 'Multiselect' && field.type !== 'Formatted Text' && $store.state.revision[field.machine_name].length > 0"
            ><strong>{{ field.label }}:</strong> {{ $store.state.revision[field.machine_name] }}</p>

            <!-- FORMATTED TEXT FIELDS -->
            <p
              v-if="field.type === 'Formatted Text' && $store.state.revision[field.machine_name].length > 0"
              class="mb-0"
            ><strong>{{ field.label }}:</strong></p>
            <div
              v-if="field.type === 'Formatted Text' && $store.state.revision[field.machine_name].length > 0"
              v-html="$store.state.revision[field.machine_name]"
            ></div>

            <!-- MULTISELECT FIELD -->
            <p
              v-if="field.type === 'Multiselect' && $store.state.revision[field.machine_name].length > 0"
            >
              <strong>{{ field.label }}:</strong><br>
              <b-badge
                pill
                variant="dark"
                v-for="(val, i) in $store.state.revision[field.machine_name]"
                v-bind:key="i"
                class="mr-1"
              >{{ val }}</b-badge>
            </p>

            <!-- IMAGE GALLERY -->
            <b-carousel
              v-if="field.type === 'Image' && slideshowsReady && !fullscreenSlides && $store.state.revision[field.machine_name].length > 0"
              :id="field.machine_name"
              controls
              indicators
              class="mb-4"
              :interval="0"
            >
              <b-carousel-slide
                v-for="(image, i) in slideshows[field.machine_name].slides"
                v-bind:key="i"
                :img-src="image.url"
                :text="image.caption"
              ></b-carousel-slide>
              <b-btn
                @click="fullscreenSlides = true"
                class="fullscreenButton"
                variant="dark"
              ><i class="fas fa-expand"></i></b-btn>
            </b-carousel>
            <!-- FULL SCREEN GALLERY -->
            <div
              class="fullscreenSlideshow"
              v-if="field.type === 'Image' && slideshowsReady && fullscreenSlides"
            >
              <b-carousel
                :id="field.machine_name + '_fullscreen'"
                controls
                indicators
                :interval="0"
              >
                <b-carousel-slide
                  v-for="(image, i) in slideshows[field.machine_name].slides"
                  v-bind:key="i"
                  :img-src="image.url"
                  :text="image.caption"
                >
                  <b-btn
                    @click="downloadImage(image)"
                    class="downloadImageBtn"
                    variant="dark"
                  ><i class="fas fa-download"></i> Download Image</b-btn>
                </b-carousel-slide>
              </b-carousel>
              <b-btn
                @click="fullscreenSlides = false"
                class="closeButton"
                variant="dark"
              ><i class="fas fa-times"></i> Close</b-btn>
            </div><!-- /FULLSCREEN SLIDESHOW -->
          </div><!-- /.check for field data -->
        </div><!-- /FIELDS -->
        <p v-if="$store.state.revision.updatedOn != undefined"><strong>Updated:</strong> {{ $store.state.revision.updatedOn | dateTime }}</p>
        <p v-if="$store.state.revision.createdOn != undefined"><strong>Created:</strong> {{ $store.state.revision.createdOn | dateTime }}</p>
      </div><!-- if dynamic -->
      <div v-else>
        <p
          v-for="(field, i) in $store.state.revision.fields"
          v-bind:key="i"
        ><strong>{{ field }}:</strong> {{ $store.state.revision[field] }}</p>
      </div><!-- if dynamic -->
    </b-card>
  </div><!-- /.revisionDetails -->
</template>

<script>
import moment from 'moment'
import firebase from 'firebase'

export default {
  data () {
    return {
      slideshows: {},
      slideshowsReady: false,
      fullscreenSlides: false
    }
  },
  created () {
    this.getImageUrls()
  },
  methods: {
    downloadImage (image) {
      firebase.storage().ref(image.path).getDownloadURL().then(url => {
        window.open(url, '_blank')
      })
    },
    getImageUrls () {
      this.$store.state.collector.fields.forEach(field => {
        if (this.$store.state.revision[field.machine_name] && field.type === 'Image') {
          this.slideshows[field.machine_name] = {
            slides: [],
            show: false
          }
          this.$store.state.revision[field.machine_name].forEach(image => {
            firebase.storage().ref(image.large).getDownloadURL().then(url => {
              this.slideshows[field.machine_name].slides.push({
                path: image.path,
                url: url,
                caption: image.caption
              })
              if (this.slideshows[field.machine_name].slides.length === this.$store.state.revision[field.machine_name].length) {
                this.slideshows[field.machine_name].show = true
                this.$store.state.collector.fields.forEach(field => {
                  let readyToShowSlideshows = true
                  if (field.type === 'Image' && !this.slideshows[field.machine_name].show) {
                    readyToShowSlideshows = false
                  }
                  this.slideshowsReady = readyToShowSlideshows
                })
              }
            })
          })
        } else if (this.$store.state.revision[field.machine_name] && field.type === 'Formatted Text') {
          const content = this.$store.state.revision[field.machine_name]
          const fixParenthesis = content.replace(/�s/g, '\'s')
          const removeSpecial = fixParenthesis.replace(/�/g, '"')
          this.$store.state.revision[field.machine_name] = removeSpecial
        }
      })
    },
    hideDataPointDetails () {
      this.$store.commit('hideDrawer', 'previewRevision')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'revisions')
      }, 300)
    }
  },
  filters: {
    dateTime (val) {
      if (val === {}) { return '-' }
      const date = val.toDate()
      return moment(date).fromNow()
    }
  }
}
</script>
