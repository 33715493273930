<template>
  <div id="blockDetails">
    <!-- Block Details -->
    <b-card
      id="blockDetailsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.blockDetails.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="hideBlockDetails()"
        >
          <i class="far fa-times"></i>
        </a>
        <!-- Block Title -->
        <h4 id="blockTitle">{{ $store.state.block.name }}</h4>
        <!-- Block Settings Button -->
        <!-- <b-btn
          v-if="$store.state.role == 'Administrator' || $store.state.role == 'Editor'"
          @click="editBlockSettings()"
          id="blockSettings"
          class="mb-2"
        >
          <i class="fas fa-cog"></i> Block Settings
        </b-btn> -->
        <b-btn
          v-if="$store.state.role == 'Administrator' || $store.state.role == 'Editor'"
          @click="editBlockSettings()"
          id="blockSettings"
          class="mb-2"
        >
          <i class="fas fa-cog"></i> Block Settings
        </b-btn>
      </template>
      <p>{{ $store.state.block.description }}</p>
      <p v-if="$store.state.userProfile.measurementSystem === 'Metric'"><strong>Area:</strong> {{ $store.state.block.area | toHectares }} hectares</p>
      <p v-if="$store.state.userProfile.measurementSystem === 'Imperial'"><strong>Area:</strong> {{ $store.state.block.area | toAcres }} acres</p>
      <div v-if="$store.state.atlas.blockFields && $store.state.atlas.blockFields.length > 0">
        <!-- FIELDS -->
        <div
          v-for="(field, i) in $store.state.atlas.blockFields"
          v-bind:key="i"
        >
          <!-- TEXT FIELDS -->
          <p
            v-if="field.type !== 'Image'"
          ><strong>{{ field.label }}:</strong> {{ $store.state.block[field.machine_name] }} <span v-if="$store.state.atlas.blockFields[i].suffix">{{ $store.state.atlas.blockFields[i].suffix }}</span></p>
          <!-- IMAGE GALLERY -->
          <p
            v-if="field.type === 'Image' && slideshowsReady"
          ><strong>{{ field.label }}</strong></p>
          <b-carousel
            v-if="field.type === 'Image' && slideshowsReady && !fullscreenSlides"
            :id="field.machine_name"
            controls
            indicators
            class="mb-4"
            :interval="0"
          >
            <b-carousel-slide
              v-for="(image, i) in slideshows[field.machine_name].slides"
              v-bind:key="i"
              :img-src="image.url"
            ></b-carousel-slide>
            <b-btn
              @click="fullscreenSlides = true"
              class="fullscreenButton"
            ><i class="fas fa-expand"></i></b-btn>
          </b-carousel>
          <!-- FULL SCREEN GALLERY -->
          <div
            class="fullscreenSlideshow"
            v-if="field.type === 'Image' && slideshowsReady && fullscreenSlides"
          >
            <b-carousel
              :id="field.machine_name + '_fullscreen'"
              controls
              indicators
              :interval="0"
            >
              <b-carousel-slide
                v-for="(image, i) in slideshows[field.machine_name].slides"
                v-bind:key="i"
                :img-src="image.url"
              >
                <b-btn @click="downloadImage(image)" class="downloadImageBtn"><i class="fas fa-download"></i> Download Image</b-btn>
              </b-carousel-slide>
            </b-carousel>
            <b-btn
              @click="fullscreenSlides = false"
              class="closeButton"
            ><i class="fas fa-times"></i> Close</b-btn>
          </div><!-- /FULLSCREEN SLIDESHOW -->
        </div><!-- /FIELDS -->
        <p v-if="$store.state.dataPoint.updatedOn != undefined"><strong>Updated:</strong> {{ $store.state.dataPoint.updatedOn | dateTime }}</p>
        <p v-if="$store.state.dataPoint.createdOn != undefined"><strong>Created:</strong> {{ $store.state.dataPoint.createdOn | dateTime }}</p>
      </div><!-- if dynamic -->
    </b-card>
  </div><!-- /#blockDetails -->
</template>

<script>
import firebase from 'firebase'

export default {
  data () {
    return {
      slideshows: {},
      slideshowsReady: false,
      fullscreenSlides: false
    }
  },
  created () {
    this.getImageUrls()
  },
  methods: {
    downloadImage (image) {
      firebase.storage().ref(image.path).getDownloadURL().then(url => {
        window.open(url, '_blank')
      })
    },
    getImageUrls () {
      this.$store.state.atlas.blockFields.forEach(field => {
        if (field.type === 'Image') {
          this.slideshows[field.machine_name] = {
            slides: [],
            show: false
          }
          this.$store.state.block[field.machine_name].forEach(image => {
            firebase.storage().ref(image.large).getDownloadURL().then(url => {
              this.slideshows[field.machine_name].slides.push({
                path: image.path,
                url: url
              })
              if (this.slideshows[field.machine_name].slides.length === this.$store.state.block[field.machine_name].length) {
                this.slideshows[field.machine_name].show = true
                this.$store.state.atlas.blockFields.forEach(field => {
                  let readyToShowSlideshows = true
                  if (field.type === 'Image' && !this.slideshows[field.machine_name].show) {
                    readyToShowSlideshows = false
                  }
                  this.slideshowsReady = readyToShowSlideshows
                })
              }
            })
          })
        }
      })
    },
    hideBlockDetails () {
      // Turn all block layers Purple
      // this.$store.state.blocks.forEach(block => {
      //   const thisLayerId = 'Footprint-' + block.id
      //   this.$store.state.mapObject.setPaintProperty(thisLayerId, 'fill-color', '#6e59c7')
      // })
      if (this.$store.state.mapObject.getLayer('blockHighlightLayer')) {
        this.$store.state.mapObject.removeLayer('blockHighlightLayer')
        this.$store.state.mapObject.removeSource('blockHighlightLayer')
      }
      // Hide the details
      this.$store.commit('hideDrawer', 'blockDetails')
      // this.$store.commit('setDrawer', { name: 'blockDetails', position: 'out' })
    },
    // editBlockSettings () {
    //   this.$store.commit('setDrawer', { name: 'blockDetails', position: 'out' })
    //   this.$store.commit('setEditMode', true)
    //   const self = this
    //   setTimeout(function () {
    //     self.$store.commit('toggleModal', 'createBlock')
    //   }, 300)
    // },
    editBlockSettings () {
      // this.$store.commit('setDrawer', { name: 'blockDetails', position: 'out' })
      this.$store.commit('hideDrawer', 'blockDetails')
      this.$store.commit('setEditMode', true)
      const self = this
      setTimeout(function () {
        // self.$store.commit('toggleModal', 'createBlock')
        // self.$store.commit('setStateProperty', { property: 'showCreateBlockDrawer', value: true })
        // self.$store.commit('setDrawer', { name: 'createBlockDrawer', position: 'in' })
        self.$store.commit('showDrawer', 'createBlockDrawer')
      }, 300)
    }
  },
  filters: {
    toAcres (val) {
      const acres = val * 0.000247105
      const rounded = acres.toFixed(2)
      return rounded
    },
    toHectares (val) {
      const hectares = val / 10000
      const rounded = hectares.toFixed(2)
      return rounded
    }
  }
}
</script>
