<template>
  <div class="dataFiles-input">
    <input
      type="file"
      class="form-control-file"
      accept=".txt,.zip,.geojson,.tif,.kmz,.kml"
      @change="onFileSelected"
      id="dataFilesInput"
    />
    <div class="progress" style="height: 2px;">
      <div class="progress-bar" id="progressBar" role="progressbar" style="width: 0%;" aria-valuemin="0" aria-valuemax="100"></div>
    </div><!-- /.progress -->
    <div
      id="fileIcons"
      v-if="$store.state.display.editMode == true"
    >
      {{ $store.state.dataset.file.originalFileName }}
    </div><!-- /#fileIcons -->
    <div
      v-if="$store.state.display.editMode == false && $store.state.dataset.file"
      id="fileIcons"
    >
      {{ $store.state.dataset.file.originalFileName }}
    </div><!-- /#fileIcons -->
  </div><!-- /.dataFiles-input -->
</template>

<script>
import { mapState } from 'vuex'
import firebase from 'firebase'
import geoblaze from 'geoblaze'
import proj4 from 'proj4'
const epsg = require('epsg')

export default {
  data () {
    return {
      dataFilesPayload: ''
    }
  },
  props: ['atlas'],
  computed: {
    ...mapState([
      'currentUser'
    ])
  },
  methods: {
    async onFileSelected (e) {
      const self = this
      self.$store.commit('setProcessingDataset', true)
      // Setup file name
      function getFormattedTime () {
        var today = new Date()
        var y = today.getFullYear()
        var m = today.getMonth() + 1
        var d = today.getDate()
        return y + '-' + m + '-' + d + '-'
      }
      function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      const dataFilesId = getFormattedTime() + s4()
      // Get the file
      const file = e.target.files[0]
      // Get the file extension
      const lastDot = e.target.files[0].name.lastIndexOf('.')
      const fileExt = e.target.files[0].name.substring(lastDot + 1)
      // Cunstruct the path and storage reference using the atlas id
      const dataFilesPath = 'datasets/' + self.atlas.id + '/' + dataFilesId + '-' + file.name
      const storageRef = firebase.storage().ref(dataFilesPath)
      // The Object to be delivered
      const dataFileObject = {}

      if (fileExt === 'tif') {
        const raster = await geoblaze.load(file)
        const firstProjection = epsg['EPSG:' + raster.projection]
        const secondProjection = epsg['EPSG:4326']
        const rasterMins = proj4(firstProjection, secondProjection, [raster.xmin, raster.ymin])
        const rasterMaxs = proj4(firstProjection, secondProjection, [raster.xmax, raster.ymax])
        dataFileObject.xMin = rasterMins[0]
        dataFileObject.xMax = rasterMaxs[0]
        dataFileObject.yMin = rasterMins[1]
        dataFileObject.yMax = rasterMaxs[1]
      }
      // Upload the raw file
      const task = storageRef.put(file)
      // Update the progressBar
      task.on('state_changed', function progress (snapshot) {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '%'
        document.getElementById('progressBar').style.width = percentage
      },
      function error (err) {
        console.log('errors: ' + err)
      },
      function complete () {
        // original storage info
        const dataFilePath = 'datasets/' + self.atlas.id + '/' + dataFilesId + '-' + file.name
        // Set path and original file name in the storage object
        dataFileObject.path = dataFilePath
        dataFileObject.originalFileName = file.name
        // If this is already geojson, just pass the dataFilePath in, othersise
        // get the geoJson Info
        if (fileExt === 'geojson') {
          dataFileObject.geoJsonPath = dataFilePath
          self.dataFilesPayload = dataFileObject
          self.$emit('dataFilesDelivery', self.dataFilesPayload)
          document.getElementById('progressBar').style.width = 0
          self.$store.commit('setProcessingDataset', false)
        } else if (fileExt === 'tif') {
          dataFileObject.raster = true
          self.dataFilesPayload = dataFileObject
          getJpgInfo()
        } else {
          self.dataFilesPayload = dataFileObject
          getGeoJsonInfo()
        }
        // document.getElementById('progressBar').style.width = 0
      })
      function getGeoJsonInfo () {
        // Get the geojson info and assign to the payload
        const geoJsonFileName = file.name.replace(/\.[^/.]+$/, '')
        const geoJsonDataFilePath = 'datasets/' + self.atlas.id + '/' + dataFilesId + '-' + geoJsonFileName + '.geojson'
        const geoJsonStorageRef = firebase.storage().ref(geoJsonDataFilePath)
        dataFileObject.geoJsonPath = geoJsonDataFilePath
        setTimeout(function () {
          geoJsonStorageRef.getDownloadURL().then(geoJsonDataFileUrl => {
            if (geoJsonDataFileUrl) {
              self.dataFilesPayload = dataFileObject
              // Deliver the goods
              self.$emit('dataFilesDelivery', self.dataFilesPayload)
              document.getElementById('progressBar').style.width = 0
              self.$store.commit('setProcessingDataset', false)
            } else {
              getGeoJsonInfo()
            }
          }).catch(function (error) {
            console.log(error)
            if (error) {
              getGeoJsonInfo()
            }
          })
        }, 3000)
      }
      function getJpgInfo () {
        // Get the geojson info and assign to the payload
        const jpgFileName = file.name.replace(/\.[^/.]+$/, '')
        const jpgFilePath = 'datasets/' + self.atlas.id + '/' + dataFilesId + '-' + jpgFileName + '.jpg'
        const jpgStorageRef = firebase.storage().ref(jpgFilePath)
        dataFileObject.jpgPath = jpgFilePath
        setTimeout(function () {
          jpgStorageRef.getDownloadURL().then(jpgFileUrl => {
            if (jpgFileUrl) {
              self.dataFilesPayload = dataFileObject
              // Deliver the goods
              self.$emit('dataFilesDelivery', self.dataFilesPayload)
              document.getElementById('progressBar').style.width = 0
              self.$store.commit('setProcessingDataset', false)
            } else {
              getJpgInfo()
            }
          }).catch(function (error) {
            console.log(error)
            if (error) {
              getJpgInfo()
            }
          })
        }, 3000)
      }
    }
  }
}
</script>
