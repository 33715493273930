import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'
import Login from '@/components/user/Login'
import Atlas from '@/components/atlas/Atlas'
// import Settings from '@/components/user/Settings'
import Script from '@/components/Script'
import Hs2Script from '@/components/Hs2Script'
import Admin from '@/components/admin/Admin'

// PROJECTS
import Ugrr from '@/projects/ugrr/Ugrr'
import Hs2 from '@/projects/hs2/Hs2'
import UgrrOperaHouse from '@/projects/ugrr/opera_house/Stage.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/a'
    },
    {
      path: '/login/:mode?',
      name: 'Login',
      component: Login
    },
    {
      path: '/script',
      name: 'Script',
      component: Script
    },
    {
      path: '/hs2-script',
      name: 'Hs2Script',
      component: Hs2Script,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/a/:id?/:layerType?/:layerId?/:mode?',
      name: 'Atlas',
      component: Atlas,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      meta: {
        requiresAuth: true
      }
    },
    // PROJECTS
    {
      path: '/project/ugrr',
      name: 'Ugrr',
      component: Ugrr
    },
    {
      path: '/project/ugrr_opera_house',
      name: 'UgrrOperaHouse',
      component: UgrrOperaHouse
    },
    {
      path: '/project/hs2',
      name: 'Hs2',
      component: Hs2
    }
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = firebase.auth().currentUser

  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})

export default router
