<template>
  <div id="userDetails">
    <!-- User Details -->
    <b-card
      id="userDetailsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.userDetails.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="hideUserDetails()"
        >
          <i class="far fa-times"></i>
        </a>
        <!-- User Email -->
        <h4>{{ $store.state.userProfile.email }}</h4>
        <!-- My Atlases -->
        <b-btn
          @click="showAtlases()"
          id="blockSettings"
          class="mb-2 mr-1"
          variant="dark"
        >
          <i class="fas fa-atlas"></i> My Atlases
        </b-btn>
        <!-- User Settings Button -->
        <b-btn
          @click="editUserSettings()"
          id="blockSettings"
          class="mb-2 mr-1"
          variant="dark"
        >
          <i class="fas fa-cog"></i> User Settings
        </b-btn>
        <b-btn
          @click="logout()"
          class="mb-2"
          variant="dark"
        >
          <i class="far fa-sign-out-alt"></i> Logout
        </b-btn>
      </template>
      <p class="lead">
        <span v-if="$store.state.userProfile.firstName">{{ $store.state.userProfile.firstName }}</span> <span v-if="$store.state.userProfile.lastName">{{ $store.state.userProfile.lastName }}</span>
      </p>
      <p v-if="$store.state.userProfile.company">{{ $store.state.userProfile.company }}</p>
    </b-card>

    <!-- Settings Modal -->
    <!-- <Settings v-if="$store.state.display.modal.settings" /> -->

  </div><!-- /#userDetails -->
</template>

<script>
// import Settings from './Settings'
const fb = require('../../firebaseConfig.js')

export default {
  // components: {
  //   Settings
  // },
  methods: {
    logout () {
      this.$store.commit('hideAllDrawers')
      fb.auth.signOut().then(() => {
        this.$store.dispatch('clearUserData')
        this.$router.push('/login')
      }).catch(err => {
        console.log(err)
      })
    },
    showAtlases () {
      this.$store.commit('hideDrawer', 'userDetails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'atlases')
      }, 300)
    },
    hideUserDetails () {
      // Hide the details
      // this.$store.commit('setDrawer', { name: 'userDetails', position: 'out' })
      this.$store.commit('hideDrawer', 'userDetails')
    },
    editUserSettings () {
      // this.$store.commit('setDrawer', { name: 'userDetails', position: 'out' })
      this.$store.commit('hideDrawer', 'userDetails')
      // const self = this
      setTimeout(() => {
        this.$store.commit('showDrawer', 'userSettings')
        // self.$store.commit('toggleModal', 'settings')
      }, 300)
    }
  }
}
</script>

<style scoped>
  #userDetailsToggle {
    position: fixed;
    z-index: 1;
    left: 10px;
    bottom: 50px;
  }
</style>
