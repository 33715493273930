// import axios from 'axios'
// import firebase from 'firebase'
// const fb = require('../firebaseConfig.js')

export default {
  resetKey (state, mode) {
    state.showKey = false
    state.keyMode = mode
    setTimeout(() => {
      state.showKey = true
    }, 100)
  },
  showDrawer (state, drawer) {
    state.drawer[drawer].show = true
    setTimeout(() => {
      state.drawer[drawer].position = 'in'
    }, 10)
    setTimeout(() => {
      const map = state.mapObject
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      if (vw >= 800) {
        map.easeTo({
          padding: { left: 500 },
          duration: 300
        })
      } else {
        const mapAdjustment = vh * 0.6
        map.easeTo({
          padding: { bottom: mapAdjustment },
          duration: 300
        })
      }
    }, 10)
  },
  hideDrawer (state, drawer) {
    state.drawer[drawer].position = 'out'
    const map = state.mapObject
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    if (vw >= 800) {
      map.easeTo({
        padding: { left: 0 },
        duration: 300
      })
    } else {
      map.easeTo({
        padding: { bottom: 0 },
        duration: 300
      })
    }
    setTimeout(() => {
      state.drawer[drawer].show = false
    }, 300)
  },
  tuckDrawer (state, drawer) {
    state.drawer[drawer].position = 'out'
    const map = state.mapObject
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    if (vw >= 800) {
      map.easeTo({
        padding: { left: 0 },
        duration: 300
      })
    } else {
      map.easeTo({
        padding: { bottom: 0 },
        duration: 300
      })
    }
  },
  hideAllDrawers (state) {
    const allDrawers = Object.keys(state.drawer)
    allDrawers.forEach(drawer => {
      state.drawer[drawer].position = 'out'
      setTimeout(() => {
        state.drawer[drawer].show = false
      }, 300)
    })
  },
  setStateProperty (state, options) {
    state[options.property] = options.value
  },
  setAlert (state, value) {
    state.alert = value
    if (value.timeout > 0) {
      setTimeout(function () {
        state.alert.active = false
      }, value.timeout)
    }
  },
  setMapClass (state, value) {
    state.display.mapClass = value
  },
  setMapGeoJsonUrl (state, value) {
    state.map.geoJsonUrl = value
  },
  // setSelectBlocksListenersAdded (state, value) {
  //   state.selectBlocksListenersAdded = value
  // },
  // setSelectedBlocks (state, value) {
  //   state.selectedBlocks = value
  // },
  resetDrawers (state) {
    // REMEMBER: This is also manually setup in the initAtlasMap when a block
    // is clicked and in the setDrawer mutation
    state.display.drawer = {
      blockDetails: 'out',
      datasetDetails: 'out',
      datasets: 'out',
      dataPointDetails: 'out',
      copyDatasetDrawer: 'out',
      createBlockDrawer: 'out',
      createMapDrawer: 'out',
      createDatasetDrawer: 'out',
      // maps: 'out',
      // mapDetails: 'out',
      atlases: 'out',
      userDetails: 'out',
      notifications: 'out',
      plugins: 'out',
      collectors: 'out',
      collector: 'out'
    }
  },
  setDrawer (state, drawer) {
    let initialPos = false
    if (drawer.position === 'toggle') {
      initialPos = state.display.drawer[drawer.name]
    }

    state.display.drawer = {
      blockDetails: 'out',
      datasetDetails: 'out',
      datasets: 'out',
      dataPointDetails: 'out',
      copyDatasetDrawer: 'out',
      createBlockDrawer: 'out',
      createMapDrawer: 'out',
      createDatasetDrawer: 'out',
      // maps: 'out',
      // mapDetails: 'out',
      atlases: 'out',
      userDetails: 'out',
      notifications: 'out',
      plugins: 'out',
      collectors: 'out',
      collector: 'out'
    }

    setTimeout(() => {
      function resizeMap (direction) {
        const map = state.mapObject
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        if (vw >= 800) {
          if (direction === 'in') {
            map.easeTo({
              padding: { left: 500 },
              duration: 1000
            })
          } else {
            map.easeTo({
              padding: { left: 0 },
              duration: 1000
            })
          }
        } else {
          const mapAdjustment = vh * 0.6
          if (direction === 'in') {
            map.easeTo({
              padding: { bottom: mapAdjustment },
              duration: 1000
            })
          } else {
            map.easeTo({
              padding: { bottom: 0 },
              duration: 1000
            })
          }
        }
      }
      if (drawer.position === 'toggle') {
        if (initialPos === 'in') {
          state.display.drawer[drawer.name] = 'out'
          resizeMap('out')
        } else {
          state.display.drawer[drawer.name] = 'in'
          resizeMap('in')
        }
      } else {
        state.display.drawer[drawer.name] = drawer.position
        state.display.mapClass = drawer.position
        resizeMap(drawer.position)
      }
    }, 100)
  },
  setProcessingDataset (state, boolean) {
    state.display.processingDataset = boolean
  },
  resetBlocks (state) {
    // Turn all block layers Purple
    state.blocks.forEach(block => {
      const thisLayerId = 'Footprint-' + block.id
      state.mapObject.setPaintProperty(thisLayerId, 'fill-color', '#6e59c7')
    })
  },
  setFullscreen (state, boolean) {
    state.display.fullscreen = boolean
  },
  setMapClickable (state, boolean) {
    state.mapClickable = boolean
  },
  setRangeZones (state) {
    const classification = state.viz.classification
    const zones = state.viz.zones
    const threeZoneColors = ['rgb(194,82,60)', 'rgb(123,237,0)', 'rgb(11,44,122)']
    const fiveZoneColors = ['rgb(194,82,60)', 'rgb(240,180,17)', 'rgb(123,237,0)', 'rgb(27,168,124)', 'rgb(11,44,122)']
    const rangeZones = []
    const rangeDiff = state.viz.interpolatedMax - state.viz.interpolatedMin
    const rangeLength = rangeDiff / zones
    if (state.viz.interpolated) {
      // Equal Interval
      if (classification === 'equal-interval') {
        // 3-stop
        if (zones === 3) {
          for (let i = 0; i < 3; i++) {
            rangeZones.push({
              color: threeZoneColors[i],
              rangeStart: state.viz.interpolatedMin + (rangeLength * i),
              rangeEnd: state.viz.interpolatedMax - (rangeLength * (zones - (i + 1)))
            })
          }
        }
        // 5-stop
        if (zones === 5) {
          for (let i = 0; i < 5; i++) {
            rangeZones.push({
              color: fiveZoneColors[i],
              rangeStart: state.viz.interpolatedMin + (rangeLength * i),
              rangeEnd: state.viz.interpolatedMax - (rangeLength * (zones - (i + 1)))
            })
          }
        }
      }
      // JENKS
      if (classification === 'jenks') {
        // 3-stop
        if (zones === 3) {
          for (let i = 0; i < 3; i++) {
            rangeZones.push({
              color: threeZoneColors[i],
              rangeStart: state.viz.interpolatedMin + (rangeLength * i),
              rangeEnd: state.viz.interpolatedMax - (rangeLength * (zones - (i + 1)))
            })
          }
        }
        // 5-stop
        if (zones === 5) {
          for (let i = 0; i < 5; i++) {
            rangeZones.push({
              color: fiveZoneColors[i],
              rangeStart: state.viz.interpolatedMin + (rangeLength * i),
              rangeEnd: state.viz.interpolatedMax - (rangeLength * (zones - (i + 1)))
            })
          }
        }
      }
      state.rangeZones = rangeZones
    }
  },
  setFilterBy (state, value) {
    state.filterBy = value
  },
  setSortBy (state, value) {
    state.sortBy = value
  },
  setDatasetReady (state, mode) {
    state.display.datasetReady = mode
  },
  setEditMode (state, mode) {
    state.display.editMode = mode
  },
  toggleModal (state, modal) {
    if (state.display.modal[modal]) {
      state.display.modal[modal] = false
    } else {
      state.display.modal[modal] = true
    }
  },
  setAtlas (state, atlas) {
    state.atlas = atlas
    // Refresh the current role
    if (atlas.ownerId === state.currentUser.uid) {
      state.role = 'Administrator'
    } else if (atlas.permissions[state.currentUser.uid]) {
      state.role = atlas.permissions[state.currentUser.uid]
    } else {
      state.role = ''
    }
  },
  clearAtlas (state) {
    state.atlas = {}
  },
  setAtlases (state, atlases) {
    state.atlases = atlases
  },
  setDataset (state, dataset) {
    state.dataset = dataset
  },
  setDataPoint (state, dataPoint) {
    state.dataPoint = dataPoint
  },
  setCollector (state, collector) {
    state.collector = collector
  },
  setCollectors (state, collectors) {
    state.collectors = collectors
  },
  setDatasets (state, datasets) {
    state.datasets = datasets
  },
  setTempDataset (state, tempDataset) {
    state.tempDataset = tempDataset
  },
  // setTempMap (state, tempMap) {
  //   state.tempMap = tempMap
  // },
  // setMap (state, map) {
  //   state.map = map
  // },
  // setMaps (state, maps) {
  //   state.maps = maps
  // },
  setNotifications (state, notifications) {
    state.notifications = notifications
  },
  setMapObject (state, mapObject) {
    state.mapObject = mapObject
  },
  // clearMap (state) {
  //   state.map = {}
  // },
  setViz (state, vizSettings) {
    state.viz = vizSettings
  },
  // setShowMapSettings (state, boolean) {
  //   state.display.showMapSettings = boolean
  // },
  // toggleMapSettings (state, boolean) {
  //   state.display.showMapSettings = false
  //   setTimeout(() => {
  //     state.display.showMapSettings = boolean
  //   }, 100)
  // },
  setMapLoader (state, boolean) {
    state.loadingMap = boolean
  },
  setBlock (state, block) {
    state.block = block
  },
  setBlocks (state, blocks) {
    state.blocks = blocks
  },
  setCurrentUser (state, val) {
    state.currentUser = val
  },
  setUserProfile (state, val) {
    state.userProfile = val
  },
  setHiddenDatasets (state, val) {
    if (val) {
      // make sure not to add duplicates
      if (!state.hiddenDatasets.some(x => x.id === val.id)) {
        state.hiddenDatasets.unshift(val)
      }
    } else {
      state.hiddenDatasets = []
    }
  }
}
