<template>
  <div id="admin">
    <div
      v-if="totals"
      class="container pt-3"
    >
      <h1 class="text-center">The Mapping Service Admin Dashboard</h1>
      <Totals
        :usersTotal="totals.users"
        :atlasesTotal="totals.atlases"
        :datasetsTotal="totals.datasets"
      />
      <AtlasesMap
      />
      <b-btn
        class="mt-3"
        @click="downloadUsers()"
      >
        <i class="fas fa-file-download"></i> User Emails CSV
      </b-btn>
    </div><!-- /.container -->
  </div><!-- /#admin -->
</template>

<script>
import AtlasesMap from './AtlasesMap'
import Totals from './Totals'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      totals: false,
      dailyStats: []
    }
  },
  components: {
    AtlasesMap,
    Totals
  },
  created () {
    this.gatherStats()
  },
  methods: {
    downloadUsers () {
      function exportToCsv (filename, rows) {
        var processRow = function (row) {
          var finalVal = ''
          for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString()
            if (row[j] instanceof Date) {
              innerValue = row[j].toLocaleString()
            }
            var result = innerValue.replace(/"/g, '""')
            if (result.search(/("|,|\n)/g) >= 0) {
              result = '"' + result + '"'
            }
            if (j > 0) {
              finalVal += ','
              finalVal += result
            } else {
              finalVal += result
            }
          }
          return finalVal + '\n'
        }
        var csvFile = ''
        for (var i = 0; i < rows.length; i++) {
          csvFile += processRow(rows[i])
        }
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename)
        } else {
          var link = document.createElement('a')
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      }
      const userEmails = []
      fb.usersCollection.get().then(userDocs => {
        userDocs.forEach(item => {
          const csvItemRow = []
          csvItemRow.push(item.data().email || '')
          csvItemRow.push(item.data().firstName || '')
          csvItemRow.push(item.data().lastName || '')
          userEmails.push(csvItemRow)
        })
        exportToCsv('users.csv', userEmails)
      }).catch(err => {
        console.log(err.message)
      })
    },
    gatherStats () {
      fb.statisticsCollection.orderBy('createdOn', 'desc').limit(30).get().then(statsDocs => {
        statsDocs.forEach(item => {
          const stat = item.data()
          stat.id = item.id
          this.dailyStats.push(stat)
        })
        this.totals = this.dailyStats[0]
      }).catch(err => {
        console.log(err.message)
      })
    }
  }
}
</script>

<style scoped>
#admin {
  color: #eee;
}

h1 {
  font-size: 24px;
}
</style>
