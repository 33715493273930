<template>
  <div id="atlas">
    <AtlasDetails v-if="$store.state.drawer.atlasDetails.show" />
    <AtlasMap v-if="showMap" />
    <Atlases v-if="$store.state.drawer.atlases.show"/>
    <AtlasMenu />
    <BlockDetails v-if="$store.state.drawer.blockDetails.show" />
    <Collaborators v-if="$store.state.drawer.collaborators.show" />
    <Collectors v-if="$store.state.drawer.collectors.show" />
    <Collector v-if="$store.state.drawer.collector.show" />
    <CopyDataset v-if="$store.state.drawer.copyDataset.show" />
    <CreateDataset v-if="$store.state.drawer.createDataset.show" />
    <CreateAtlas v-if="$store.state.drawer.createAtlas.show" />
    <CreateBlockDrawer v-if="$store.state.drawer.createBlockDrawer.show" />
    <DataPointDetails v-if="$store.state.drawer.dataPointDetails.show" />
    <Datasets v-if="$store.state.drawer.datasets.show" />
    <DatasetDetails v-if="$store.state.drawer.datasetDetails.show" />
    <FirstAtlasModal v-if="$store.state.display.modal.firstAtlasModal" />
    <Key v-if="$store.state.showKey" />
    <PreviewRevision v-if="$store.state.drawer.previewRevision.show" />
    <Revisions v-if="$store.state.drawer.revisions.show" />
    <!-- <Feedback />
    <Notifications /> -->
    <!-- <Plugins /> -->
    <!-- <CreateDatasetDrawer v-if="$store.state.showCreateDatasetDrawer" /> -->
    <TermsModal
      v-if="showTerms"
      :updated="termsOfService.updated"
      :tosId="termsOfService.id"
      v-on:agreeToTerms="showTerms = false"
    />
    <UserDetails v-if="$store.state.drawer.userDetails.show" />
    <UserSettings v-if="$store.state.drawer.userSettings.show" />
  </div><!-- /#atlas -->
</template>

<script>
import BlockDetails from '../block/BlockDetails'
import Collaborators from './Collaborators'
import Collectors from '../collector/Collectors'
import Collector from '../collector/Collector'
import CreateDataset from '../dataset/CreateDataset'
import CreateAtlas from './CreateAtlas'
import CreateBlockDrawer from '../block/CreateBlockDrawer'
import CopyDataset from '../dataset/CopyDataset'
import DatasetDetails from '../dataset/DatasetDetails'
import Datasets from '../dataset/Datasets'
import DataPointDetails from '../collector/DataPointDetails'
import AtlasDetails from './AtlasDetails'
import AtlasMap from './AtlasMap'
import Atlases from './Atlases'
import AtlasMenu from './AtlasMenu'
// import Feedback from '../user/Feedback'
import FirstAtlasModal from './FirstAtlasModal'
import Key from './Key'
// import Notifications from '../user/Notifications'
// import Plugins from '../plugins/Plugins'
import PreviewRevision from '../collector/PreviewRevision'
import Revisions from '../collector/Revisions'
import TermsModal from '../user/TermsModal'
import UserDetails from '../user/UserDetails'
import UserSettings from '../user/UserSettings'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      showMap: false,
      showTerms: false,
      termsOfService: {
        updated: '9/30/2021',
        id: '9-30-2021' // Be sure to save the old version for future reference. Agreement currently written in TermsModal component
      }
    }
  },
  components: {
    BlockDetails,
    CreateDataset,
    CreateAtlas,
    CreateBlockDrawer,
    Collaborators,
    Collectors,
    Collector,
    CopyDataset,
    Datasets,
    DataPointDetails,
    DatasetDetails,
    AtlasDetails,
    AtlasMap,
    Atlases,
    AtlasMenu,
    // Feedback,
    FirstAtlasModal,
    Key,
    // Notifications,
    // Plugins,
    PreviewRevision,
    Revisions,
    TermsModal,
    UserDetails,
    UserSettings
  },
  created () {
    this.$store.commit('resetDrawers')
    const self = this
    // Make sure we have a userProfile before initiating the atlas
    function userProfileLoaded () {
      setTimeout(() => {
        if (Object.keys(self.$store.state.userProfile).length > 0) {
          self.initAtlas()
        } else {
          userProfileLoaded()
        }
      }, 100)
    }
    userProfileLoaded()
  },
  methods: {
    initAtlas () {
      if (this.$store.state.userProfile.tosId !== this.termsOfService.id) {
        this.showTerms = true
      }
      let atlasId = this.$route.params.id
      if (!atlasId || atlasId === 'undefined' || atlasId === 'false') {
        if (this.$store.state.userProfile.defaultAtlas !== 'undefined') {
          atlasId = this.$store.state.userProfile.defaultAtlas
          this.$router.push('/a/' + atlasId)
        }
      }
      this.$store.dispatch('fetchAtlases').then(() => {
        if (!atlasId || atlasId === 'undefined' || atlasId === 'false') {
          if (this.$store.state.atlases[0]) {
            atlasId = this.$store.state.atlases[0].id
            this.$router.push('/a/' + atlasId)
          } else {
            // There are no atlases yet... create one
            this.$store.commit('toggleModal', 'firstAtlasModal')
            // this.$store.commit('showDrawer', 'createAtlas')
          }
        }
        return this.$store.dispatch('fetchAtlas', atlasId)
      }).then(() => {
        return this.$store.dispatch('fetchDatasets', atlasId)
      }).then(() => {
        return this.$store.dispatch('fetchFolders', atlasId)
      }).then(() => {
        return this.$store.dispatch('fetchCollectors', atlasId)
      }).then(() => {
        this.showMap = true
        return true
      }).then(() => {
        const self = this
        setTimeout(function () {
          return self.checkRoutes()
        }, 5000)
      }).catch(err => {
        console.log(err)
      })
    },
    checkRoutes () {
      if (this.$route.params.layerType === 'dataset') {
        this.$store.dispatch('removeAllLayers')
        fb.datasetsCollection.doc(this.$route.params.layerId).get().then(snapshot => {
          const datasetDoc = snapshot.data()
          datasetDoc.id = snapshot.id
          this.$store.commit('setDataset', datasetDoc)
          const self = this
          setTimeout(function () {
            self.$store.dispatch('mapDataset', datasetDoc)
          }, 1000)
        })
      }
    }
  }
}
</script>

<style scoped>
#atlas {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>
