<template>
  <!-- Create Atlas Modal -->
  <div class="o-modal">
    <div class="o-modal-content big">
      <div class="o-modal-header">
        <p class="lead">Create First Atlas</p>
      </div><!-- /.o-modal-header -->
      <form @submit.prevent>
        <div class="o-modal-body">
          <p><small>An atlas is a collection of datasets/maps. Give your atlas a name and click on the map to select a default location for your atlas.</small></p>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="atlasName">Atlas Name</label>
                <input
                  v-model="atlas.name"
                  id="atlasName"
                  class="form-control"
                  placeholder="My Awesome Maps"
                />
              </div><!-- /.form-group -->
            </div><!-- /.col-md-3 -->
            <div class="col-md-9 p-0">

              <!-- ATLAS LOCATION PICKER -->
              <div class="map" id="atlasLocationMap" style="height:470px;"></div>

            </div><!-- /.col-md-9 -->
          </div><!-- /.row -->
        </div><!-- /.o-modal-body -->
        <div class="o-modal-footer">
          <div class="float-right w-100">
            <button
              @click="saveAtlas()"
              class="btn btn-dark mr-1 float-right"
            >Create Atlas</button>
            <b-btn
              @click="logout()"
              class="float-left"
            >
              <i class="far fa-sign-out-alt"></i> Log out
            </b-btn>
          </div><!-- /.btn-group -->
        </div><!-- /.o-modal-footer -->
      </form>
    </div><!-- /.o-modal-content -->
    <Confirm
      v-if="confirmDelete"
      text="DANGER! Are you sure you want to delete this atlas? This will also delete any datasets that belong to this atlas. This cannot be undone!"
      v-on:confirmed="deleteAtlas($event)"
    />
  </div><!-- /.o-modal -->
</template>

<script>
import Confirm from '../Confirm'
import firebase from 'firebase'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      confirmDelete: false
    }
  },
  components: {
    Confirm
  },
  mounted () {
    if (this.$store.state.display.editMode === false) {
      this.atlas.name = ''
      this.atlas.geolocation = null
    }
    this.initMap()
  },
  computed: {
    atlas: {
      get () {
        return this.$store.state.atlas
      },
      set (value) {
        this.$store.commit('setAtlas', value)
      }
    }
  },
  methods: {
    logout () {
      fb.auth.signOut().then(() => {
        this.$router.push('/login')
        location.reload()
      }).catch(err => {
        console.log(err)
      })
    },
    closeModal () {
      location.reload()
    },
    deleteAtlas (confirmed) {
      const atlasId = this.$store.state.atlas.id
      if (confirmed) {
        this.confirmDelete = false
        if (atlasId === this.$store.state.userProfile.defaultAtlas) {
          fb.usersCollection.doc(this.$store.state.currentUser.uid).update({
            defaultAtlas: false
          })
        }
        // Get all Blocks with this Atlas ID
        fb.blocksCollection.where('atlasId', '==', atlasId).get().then(blocks => {
          // Perform Block Delete process for each
          blocks.forEach(block => {
            fb.blocksCollection.doc(block.id).delete()
          })
          return true
        }).then(() => {
          // Get all Datasets  with this Atlas ID
          return fb.datasetsCollection.where('atlasId', '==', atlasId).get()
        }).then(datasets => {
          // Perform Dataset Delete process for each
          datasets.forEach(dataset => {
            // Delete this datasets files
            const rawPath = dataset.data().file.path
            const rawFileRef = fb.storage.ref(rawPath)
            rawFileRef.delete()
            const geoPath = dataset.data().file.geoJsonPath
            const geoFileRef = fb.storage.ref(geoPath)
            geoFileRef.delete()
            // Delete this datasets document
            fb.datasetsCollection.doc(dataset.id).delete()
          })
          return true
        }).then(() => {
          if (this.atlas.blocksGeoJsonPath) {
            // Delete the Atlas Blocks geoJson and atlas Document
            const blocksPath = this.atlas.blocksGeoJsonPath
            const blocksFileRef = fb.storage.ref(blocksPath)
            blocksFileRef.delete()
          }
          return fb.atlasesCollection.doc(atlasId).delete()
        }).then(() => {
          window.location.href = '/'
        }).catch(err => { console.log(err.message) })
      } else {
        this.confirmDelete = false
        return false
      }
    },
    initMap () {
      const self = this
      mapboxgl.accessToken = 'pk.eyJ1IjoidGhlbWFwcGluZ3NlcnZpY2UiLCJhIjoiY2t1aWJ2c3hsMWkwYzJ1cGhyNjRndWhzZiJ9.Dm5XwBzvVMaXNK0BpiQLHA' // eslint-disable-line
      // If in edit mode, use current atlas coordinates
      let center = [-95, 40]
      let zoom = 3
      let lat
      let lng
      if (this.$store.state.display.editMode === true && this.$store.state.atlas.geolocation) {
        if (this.$store.state.atlas.geolocation.latitude) {
          // Get Atlas Coordinates
          lat = this.$store.state.atlas.geolocation.latitude
          lng = this.$store.state.atlas.geolocation.longitude
          center = [lng, lat]
          zoom = 16
        }
      }
      /* eslint-disable */
      let map = new mapboxgl.Map({
        container: 'atlasLocationMap',
        style: 'mapbox://styles/themappingservice/ckuibhxr005nf18pk5yl9dnay',
        center: center,
        zoom: zoom
      })
      map.scrollZoom.disable()

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false
      })

      map.addControl(geocoder)

      geocoder.on('result', function(results) {
        // console.log(results)
      })

      map.addControl(new mapboxgl.NavigationControl())

      // CLICK TO ADD MARKER
      let selectMarker = null
      function addMarker(e) {
        if (selectMarker != null) {
          selectMarker.remove()
        }
        let markerOptions = e
        markerOptions.color = 'black'
        // markerOptions.draggable = true
        selectMarker = new mapboxgl.Marker(markerOptions)
          .setLngLat(e.lngLat)
          .addTo(map)
        self.atlas.geolocation = new firebase.firestore.GeoPoint(e.lngLat.lat, e.lngLat.lng)
      }
      map.on('click', addMarker)

      if (this.$store.state.display.editMode && this.$store.state.atlas.geolocation) {
        if (this.$store.state.atlas.geolocation.latitude) {
          addMarker({lngLat: {
            lng: lng,
            lat: lat
          }})
        }
      }

    },
    saveAtlas () {
      if (!this.atlas.geolocation) {
        alert('You must select a location for your atlas by searching and/or clicking on the map!')
        return false
      }
      let editMode = this.$store.state.display.editMode
      if (editMode == true) {
        fb.atlasesCollection.doc(this.$store.state.atlas.id).update({
          name: this.atlas.name || '',
          geolocation: this.atlas.geolocation || '',
          updatedOn: new Date()
        }).then(ref => {
          location.reload()
        }).catch(err => {
          console.log(err)
        })
      } else {
        // Create the atlas
        fb.atlasesCollection.add({
          name: this.atlas.name || '',
          geolocation: this.atlas.geolocation || '',
          createdOn: new Date(),
          updatedOn: new Date(),
          ownerId: this.$store.state.currentUser.uid,
          users: [],
          permissions: {}
        }).then(ref => {
          window.location.href = '/a/' + ref.id
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>
