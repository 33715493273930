<template>
  <div id="collector">
    <!-- Collector -->
    <b-card
      id="collector"
      class="blockDrawer large"
      :class="$store.state.drawer.collector.position"
    >
      <template v-slot:header v-if="$store.state.display.editMode">
        <a
          class="close clickable"
          @click="closeEditCollector()"
        >
          <i class="far fa-times"></i>
        </a>

        <h4>Edit Data Point</h4>
      </template>
      <template v-slot:header v-else>
        <a
          class="close clickable"
          @click="closeCollector()"
        >
          <i class="far fa-times"></i>
        </a>

        <h4>{{ $store.state.collector.name }}</h4>
      </template>

      <!-- <p class="lead" v-if="$store.state.collector.description" v-html="$store.state.collector.description"></p> -->

      <b-btn
        @click="toggleGeocoder()"
        variant="dark"
        size="sm"
        class="float-right"
      >
        <i class="fas fa-search-location"></i> Search Location
      </b-btn>

      <div v-if="!$store.state.display.editMode">
        <p v-if="!$store.state.userLocation.accuracy"><small><span class="bad">No GPS Signal.</span></small></p>
        <p v-if="$store.state.userLocation.accuracy > 5"><small>GPS Accuracy: {{ $store.state.userLocation.accuracy }}M (<span class="bad">Bad</span>)</small></p>
        <p v-if="$store.state.userLocation.accuracy <= 5 && $store.state.userLocation.accuracy > 3"><small>GPS Accuracy: {{ $store.state.userLocation.accuracy }}M (<span class="ok">Ok</span>)</small></p>
        <p v-if="$store.state.userLocation.accuracy <= 3"><small>GPS Accuracy: {{ $store.state.userLocation.accuracy }}M (<span class="good">Good</span>)</small></p>
      </div><!-- is editmode -->

      <div
        id="geocoder"
        v-if="showGeocoder"
      ></div>

      <form @submit.prevent>

        <!-- FIELDS -->
        <div
          v-for="(field, i) in $store.state.collector.fields"
          v-bind:key="i"
          class="form-group mt-4 mb-4"
        >
          <label><strong>{{ field.label }}</strong><span v-if="field.type == 'Number' && field.min && field.max && field.step"><strong>:</strong> {{ dataPoint[field.machine_name] }} <span v-if="field.suffix">{{ field.suffix }}</span></span></label>

          <input
            v-if="field.type == 'Text'"
            v-model="dataPoint[field.machine_name]"
            class="form-control"
          />

          <quill-editor
            v-if="field.type == 'Formatted Text'"
            v-model="dataPoint[field.machine_name]"
            :options="editorOptions"
          ></quill-editor>

          <input
            v-if="field.type == 'Number' && !field.step"
            v-model="dataPoint[field.machine_name]"
            class="form-control"
            type="number"
          />

          <div
            v-if="field.type == 'Number' && field.min && field.max && field.step"
          >
                <b-form-input
                  v-model="dataPoint[field.machine_name]"
                  type="range"
                  :min="field.min"
                  :max="field.max"
                  :step="field.step"
                ></b-form-input>

            <!-- <p class="text-center lead">{{ dataPoint[field.machine_name] }}</p> -->
          </div><!-- v-if -->

          <!-- <b-form-radio-group
            v-if="field.type == 'Select'"
            v-model="dataPoint[field.machine_name]"
            :options="field.options"
          ></b-form-radio-group> -->
          <!-- <b-form-select
            v-if="field.type == 'Select'"
            v-model="dataPoint[field.machine_name]"
            :options="field.options"
            size="lg"
          ></b-form-select> -->
          <div
            v-if="field.type == 'Select' && showSelects"
          >
            <span
              v-for="(option, i) in field.options"
              v-bind:key="i"
              class="mr-1"
            >
              <b-btn
                v-if="dataPoint[field.machine_name] === option"
                @click="selectOption(field.machine_name, option)"
                variant="dark"
                class="selected"
              >{{ option }}</b-btn>
              <b-btn
                v-else
                @click="selectOption(field.machine_name, option)"
                variant="dark"
              >{{ option }}</b-btn>
            </span><!-- /OPTIONS -->
          </div><!-- /IF Select -->

          <div
            v-if="field.type == 'Multiselect' && showSelects"
          >
            <b-form-select
              v-model="dataPoint[field.machine_name]"
              :options="field.options"
              multiple
              :select-size="4"
            ></b-form-select>
          </div><!-- /IF Multiselect -->

          <ImageUpload
            v-if="field.type == 'Image'"
            :mediaObject="dataPoint[field.machine_name]"
            v-on:mediaDelivery="imagesDelivered($event, field.machine_name)"
          />

        </div><!-- /.form-group -->
        <!-- VERSION CONTROL -->
        <div
          v-if="$store.state.display.editMode"
          class="form-group mt-4 mb-4"
        >
          <label><strong>Revision Message</strong><br><small>Use this optional field to log what you've changed in this version of this datapoint. This helps track changes over time.</small></label>
          <input
            v-model="revisionMessage"
            class="form-control"
          />
        </div><!-- /.form-group -->
        <div v-if="$store.state.display.editMode" class="float-right mb-5 pb-5">
          <button
            @click="submitDatapoint()"
            class="btn btn-dark mr-1"
          >Save</button>
          <button
            @click="closeEditCollector()"
            class="btn btn-light mr-1"
          >Cancel</button>
          <button
            @click="confirmDelete = true"
            class="btn btn-danger"
          >Delete</button>
        </div><!-- /.float-right -->
        <div v-else class="float-right mb-5 pb-5">
          <button
            @click="submitDatapoint()"
            class="btn btn-dark mr-1"
          >Submit</button>
          <button
            @click="closeCollector()"
            class="btn btn-light"
          >Cancel</button>
        </div><!-- /.float-right -->
      </form>
    </b-card>
    <Confirm
      v-if="confirmDelete"
      text="DANGER! Are you sure you want to delete this data point? THIS CANNOT BE UNDONE!!"
      v-on:confirmed="deleteDataPoint($event)"
    />
  </div><!-- /#createMapDrawer -->
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Confirm from '../Confirm'
import firebase from 'firebase'
import ImageUpload from './ImageUpload'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      confirmDelete: false,
      revisionMessage: '',
      showSelects: false,
      showGeocoder: false,
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, false] }],
            [{ align: [] }],
            ['link', 'clean']
          ]
        }
      }
    }
  },
  components: {
    Confirm,
    ImageUpload,
    quillEditor
  },
  computed: {
    dataPoint: {
      get () {
        return this.$store.state.dataPoint
      },
      set (value) {
        this.$store.commit('setDataPoint', value)
      }
    },
    collector: {
      get () {
        return this.$store.state.collector
      },
      set (value) {
        this.$store.commit('setCollector', value)
      }
    }
  },
  created () {
    if (!this.$store.state.display.editMode) {
      this.collector.fields.forEach(field => {
        if (field.type === 'Multiselect') {
          this.dataPoint[field.machine_name] = []
        }
      })
    } else {
      this.$store.dispatch('collect', this.collector)
    }
    this.showSelects = true
  },
  methods: {
    toggleGeocoder () {
      if (this.showGeocoder) {
        this.showGeocoder = false
      } else {
        this.showGeocoder = true
        const self = this
        setTimeout(() => {
          /* eslint-disable */
          const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false
          })
          document.getElementById('geocoder').appendChild(geocoder.onAdd(self.$store.state.mapObject))
          geocoder.on('result', function(results) {
            if (self.collector.selectMarker !== null) {
              self.collector.selectMarker.remove()
            }
            self.collector.selectMarker = new mapboxgl.Marker({ color: 'black' })
              .setLngLat(results.result.center)
              .addTo(self.$store.state.mapObject)
            self.collector.selectedLocation = new firebase.firestore.GeoPoint(results.result.center[1], results.result.center[0])
          })
          /* eslint-enable */
        }, 100)
      }
    },
    selectOption (field, option) {
      this.showSelects = false
      this.dataPoint[field] = option
      this.showSelects = true
    },
    imagesDelivered (e, field) {
      this.dataPoint[field] = e
      // if (this.$store.state.display.editMode) {
      //   fb.collectorsCollection.doc(this.collector.id).collection('dataPoints').doc(this.dataPoint.id).update(this.dataPoint)
      // }
    },
    deleteDataPoint (confirmed) {
      if (confirmed === true) {
        this.$store.commit('tuckDrawer', 'collector')
        this.confirmDelete = false
        this.$store.commit('setEditMode', false)
        this.collector.fields.forEach((field, i) => {
          if (this.dataPoint[field.machine_name] && field.type === 'Image') {
            this.dataPoint[field.machine_name].forEach((imageItem, i) => {
              // firebase.storage().ref(imageItem.path).delete()
              // firebase.storage().ref(imageItem.small).delete()
              // firebase.storage().ref(imageItem.medium).delete()
              // firebase.storage().ref(imageItem.large).delete()
            })
          }
        })
        fb.collectorsCollection.doc(this.collector.id).collection('dataPoints').doc(this.dataPoint.id).delete().then(() => {
          this.$store.commit('setAlert', { active: true, message: 'Data Point Deleted! Recompiling map now...', level: 'alert-dark' })
          // this.$store.commit('setDrawer', { name: 'collector', position: 'out' })
          return this.$store.dispatch('removeAllLayers')
        }).then(() => {
          return fb.collectorsCollection.doc(this.collector.id).update({
            updatedOn: new Date(),
            reCompile: true
          })
        }).then(() => {
          const dataset = this.$store.state.dataset
          return this.$store.dispatch('mapDataset', dataset)
        }).then(() => {
          let newData = true
          const self = this
          fb.datasetsCollection.doc(self.$store.state.dataset.id).onSnapshot(snapshot => {
            const dataset = self.$store.state.dataset
            const datasetDoc = snapshot.data()
            datasetDoc.id = snapshot.id
            const filePath = dataset.file.geoJsonPath || ''
            const newFilePath = datasetDoc.file.geoJsonPath
            // Only rerender if new geoJson file
            if (filePath !== newFilePath && newData === true) {
              newData = false
              self.$store.commit('setDataset', datasetDoc)
              setTimeout(() => {
                self.$store.commit('setAlert', { active: false })
                self.$store.dispatch('removeAllLayers')
                self.$store.dispatch('mapDataset', datasetDoc)
              }, 1000)
            }
            this.$store.commit('hideDrawer', 'collector')
          })
        })
      } else {
        this.confirmDelete = false
        return false
      }
    },
    submitDatapoint () {
      let formComplete = true
      if (this.$store.state.display.editMode) {
        this.dataPoint.updatedOn = new Date()
        this.dataPoint.updatedBy = fb.auth.currentUser.uid
        if (this.$store.state.collector.selectedLocation) {
          this.dataPoint.geolocation = this.$store.state.collector.selectedLocation
        }
        // CONVERT NUMBER FIELDS FROM STRINGS TO NUMBERS
        this.collector.fields.forEach((field, i) => {
          let badNumber = false
          if (field.type === 'Number') {
            if (isNaN(this.dataPoint[field.machine_name])) {
              badNumber = true
            } else {
              const oldVal = this.dataPoint[field.machine_name]
              const newVal = Number(oldVal)
              this.dataPoint[field.machine_name] = newVal
            }
          }
          if (field.required) {
            if (this.dataPoint[field.machine_name] === undefined || this.dataPoint[field.machine_name].length < 1 || badNumber) {
              // alert('Required field missing: ' + field.label)
              this.$store.commit('setAlert', { active: true, message: 'Required field missing: ' + field.label, level: 'alert-dark', timeout: 3000 })
              formComplete = false
            }
          }
        })
        if (formComplete) {
          // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
          fb.collectorsCollection.doc(this.collector.id).collection('dataPoints').doc(this.dataPoint.id).get().then(dataPointDoc => {
            const dataPointData = dataPointDoc.data()
            dataPointData.id = dataPointDoc.id
            return fb.collectorsCollection.doc(this.collector.id).collection('dataPoints').doc(this.dataPoint.id).collection('revisions').add(dataPointData)
          }).then(() => {
            this.dataPoint.revisionMessage = this.revisionMessage
            return fb.collectorsCollection.doc(this.collector.id).collection('dataPoints').doc(this.dataPoint.id).update(this.dataPoint)
          }).then(() => {
            // Mark the collector with a new updatedOn date. Since editing something old, recompile as well
            return fb.collectorsCollection.doc(this.collector.id).update({
              updatedOn: new Date(),
              reCompile: true
            })
          }).then(() => {
            this.$store.commit('setAlert', { active: true, message: 'Data Point Updated! Recompiling map now...', level: 'alert-dark' })
            // this.$store.commit('setDrawer', { name: 'collector', position: 'out' })
            this.$store.commit('tuckDrawer', 'collector')
            return this.$store.dispatch('removeAllLayers')
          }).then(() => {
            // setTimeout(() => {
            const dataset = this.$store.state.dataset
            return this.$store.dispatch('mapDataset', dataset)
            // }, 4000)
          }).then(() => {
            let newData = true
            const self = this
            fb.datasetsCollection.doc(self.$store.state.dataset.id).onSnapshot(snapshot => {
              const dataset = self.$store.state.dataset
              const datasetDoc = snapshot.data()
              datasetDoc.id = snapshot.id
              const filePath = dataset.file.geoJsonPath || ''
              const newFilePath = datasetDoc.file.geoJsonPath
              // Only rerender if new geoJson file
              if (filePath !== newFilePath && newData === true) {
                newData = false
                self.$store.commit('setDataset', datasetDoc)
                setTimeout(() => {
                  self.$store.commit('setAlert', { active: false })
                  self.$store.dispatch('removeAllLayers')
                  self.$store.dispatch('mapDataset', datasetDoc)
                }, 1000)
              }
              if (this.collector.selectMarker != null) {
                this.collector.selectMarker.remove()
              }
              this.$store.commit('hideDrawer', 'collector')
            })
          }).catch(err => {
            console.log(err)
          })
        }
      } else {
        // Create New Datapoint
        this.dataPoint.createdOn = new Date()
        this.dataPoint.updatedOn = new Date()
        this.dataPoint.createdBy = fb.auth.currentUser.uid
        this.dataPoint.updatedBy = fb.auth.currentUser.uid
        this.dataPoint.revisionMessage = 'Initial datapoint created.'
        if (this.$store.state.collector.selectedLocation) {
          this.dataPoint.geolocation = this.$store.state.collector.selectedLocation
        } else if (this.$store.state.userLocation.longitude && this.$store.state.userLocation.latitude) {
          this.dataPoint.geolocation = new firebase.firestore.GeoPoint(this.$store.state.userLocation.latitude, this.$store.state.userLocation.longitude)
          this.dataPoint.accuracy = this.$store.state.userLocation.accuracy
        } else if (!this.$store.state.userLocation.latitude) {
          // alert('Could not get user location! Please try again.')
          this.$store.commit('setAlert', { active: true, message: 'Could not get user location! Please try again.', level: 'alert-dark', timeout: 3000 })
          return false
        }
        // CONVERT NUMBER FIELDS FROM STRINGS TO NUMBERS
        this.$store.state.collector.fields.forEach((field, i) => {
          let badNumber = false
          if (field.type === 'Number') {
            if (isNaN(this.dataPoint[field.machine_name])) {
              badNumber = true
            } else {
              const oldVal = this.dataPoint[field.machine_name]
              const newVal = Number(oldVal)
              this.dataPoint[field.machine_name] = newVal
            }
          }
          if (field.required) {
            if (this.dataPoint[field.machine_name] === undefined || this.dataPoint[field.machine_name].length < 1 || badNumber) {
              formComplete = false
              // alert('Required field missing: ' + field.label)
              this.$store.commit('setAlert', { active: true, message: 'Required field missing: ' + field.label, level: 'alert-dark', timeout: 3000 })
            }
          }
        })
        if (formComplete) {
          this.$store.commit('tuckDrawer', 'collector')
          // this.closeCollector()
          fb.collectorsCollection.doc(this.$store.state.collector.id).collection('dataPoints').add(this.dataPoint).then(() => {
            // Mark the collector with a new updatedOn date
            return fb.collectorsCollection.doc(this.$store.state.collector.id).update({
              updatedOn: new Date()
            })
          }).then(() => {
            this.collector.collecting = false
            if (this.collector.selectMarker != null) {
              this.collector.selectMarker.remove()
            }
            this.$store.commit('hideDrawer', 'collector')
            setTimeout(() => {
              // Refresh and relaunch data collector form
              this.$store.dispatch('collect', this.$store.state.collector)
            }, 1000)
            this.$store.commit('setAlert', { active: true, message: 'Data collected!', level: 'alert-dark', timeout: 2000 })
            return fb.datasetsCollection.doc(this.collector.datasetId).get()
          }).then(doc => {
            const dataset = doc.data()
            dataset.id = doc.id
            return this.$store.commit('setDataset', dataset)
          }).catch(err => {
            console.log(err)
          })
        }
      }
    },
    closeEditCollector () {
      this.$store.commit('setEditMode', false)
      this.$store.commit('hideDrawer', 'collector')
      if (this.collector.selectMarker != null) {
        this.collector.selectMarker.remove()
      }
      setTimeout(() => {
        this.$store.commit('showDrawer', 'dataPointDetails')
      }, 300)
    },
    closeCollector () {
      this.collector.fields.forEach((field, i) => {
        if (field.type === 'Image') {
          if (this.dataPoint[field.machine_name]) {
            this.dataPoint[field.machine_name].forEach((imageItem, i) => {
              firebase.storage().ref(imageItem.path).delete()
              firebase.storage().ref(imageItem.small).delete()
              firebase.storage().ref(imageItem.medium).delete()
              firebase.storage().ref(imageItem.large).delete()
            })
          }
        }
      })
      this.collector.collecting = false
      if (this.collector.selectMarker != null) {
        this.collector.selectMarker.remove()
      }
      this.$store.commit('hideDrawer', 'collector')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'collectors')
      }, 300)
    }
  }
}
</script>

<style scoped>
@media(max-width: 800px) {
  form {
    margin-bottom: 500px;
  }
}

.bad {
  color: red;
}

.ok {
  color: orange;
}

.good {
  color: green;
}
</style>
