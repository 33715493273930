<template>
  <div id="atlasDetails">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.atlasDetails.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'atlasDetails')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">{{ $store.state.atlas.name }}</h4>
        <b-btn
          @click="editAtlas()"
          class="mb-2 mr-1"
          v-if="$store.state.role == 'Administrator'"
          variant="dark"
        >
          <i class="fas fa-cog"></i> Atlas Settings
        </b-btn>
        <b-btn
          @click="showCollaborators()"
          class="mb-2 mr-1"
          v-if="$store.state.role == 'Administrator'"
          variant="dark"
        >
          <i class="fas fa-users"></i> Collaborators
        </b-btn>
        <!-- <b-btn
          @click="createBlock()"
          class="mb-2 mr-1"
          v-if="$store.state.role == 'Administrator'"
          variant="dark"
        >
          <i class="fas fa-map-marked-alt"></i> Create Block
        </b-btn> -->
      </template>
    </b-card>
  </div><!-- /#atlasDetails -->
</template>

<script>
export default {
  computed: {
    atlas: {
      get () {
        return this.$store.state.atlas
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'atlas', value: value })
      }
    }
  },
  methods: {
    showCollaborators () {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'collaborators')
      }, 300)
    },
    createBlock () {
      this.$store.commit('setEditMode', false)
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createBlockDrawer')
      }, 300)
    },
    showTrustees () {
      this.$store.commit('hideDrawer', 'trailSystemDetails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'trustees')
      }, 300)
    },
    editAtlas () {
      this.$store.commit('setEditMode', true)
      this.$store.commit('hideDrawer', 'atlasDetails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createAtlas')
      }, 300)
    }
  }
}
</script>
