<template>
  <div id="datasetDetails">
    <!-- Dataset Details -->
    <b-card
      id="datasetDetailsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.datasetDetails.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="hideDatasetDetailsDrawer()"
        >
          <i class="far fa-times"></i>
        </a>
        <!-- Dataset Name -->
        <h4 v-if="$store.state.dataset.name" id="datasetTitle"><i v-if="$store.state.dataset.dynamic" class="fas fa-satellite-dish mr-1"></i>{{ $store.state.dataset.name }}</h4>
        <!-- Block Settings Button -->
        <b-btn
          v-if="$store.state.role == 'Administrator'"
          @click="editDatasetSettings()"
          id="datasetSettings"
          class="mb-2 mr-1"
          variant="dark"
        >
          <i class="fas fa-cog"></i> Settings
        </b-btn>
        <b-btn
          v-if="$store.state.role == 'Administrator'"
          @click="copyDataset()"
          class="mb-2 mr-1"
          variant="dark"
        >
          <i class="fal fa-copy"></i> Copy
        </b-btn>
        <b-btn
          v-if="$store.state.role == 'Administrator' && !$store.state.dataset.dynamic && !$store.state.dataset.file.raster && $store.state.datasetGeoJson.features.length < 100 && $store.state.dataset.vizSettings && $store.state.dataset.vizSettings.type != 'polygon' && $store.state.datasetGeoJson.features && $store.state.datasetGeoJson.features[0].geometry.type !== 'Polygon'"
          @click="confirmMakeDynamic = true"
          class="mb-2"
          variant="dark"
        >
          <i class="fas fa-satellite-dish"></i> Make Dynamic
        </b-btn>
        <b-btn
          v-if="$store.state.role == 'Editor'"
          class="mb-2"
          variant="dark"
        >
          Editor View: Changes will not save
        </b-btn>
      </template>

      <div class="card-top-nav">
        <ul class="nav nav-tabs">
          <li
            class="nav-item clickable"
            @click="detailsMode = 'settings'"
          >
            <span class="nav-link"
            :class="{active:detailsMode == 'settings'}"><i class="far fa-sliders-h-square"></i> Visualize</span>
          </li>
          <li
            v-if="this.$store.state.dataset.dynamic"
            class="nav-item clickable"
            @click="detailsMode = 'list'"
          >
            <a class="nav-link"
            :class="{active:detailsMode == 'list'}"><i class="fas fa-list-alt"></i> Data</a>
          </li>
          <li
            class="nav-item clickable hide-mobile"
            @click="detailsMode = 'downloads'"
          >
            <a class="nav-link"
            :class="{active:detailsMode == 'downloads'}"><i class="fas fa-cloud-download-alt"></i> Downloads</a>
          </li>
          <li
            class="nav-item clickable"
            @click="detailsMode = 'details'"
          >
            <a class="nav-link"
            :class="{active:detailsMode == 'details'}"><i class="fas fa-info"></i> Details</a>
          </li>
        </ul>
      </div><!-- /.card-top-nav -->

      <div class="mt-5">
        <DatasetList v-if="detailsMode == 'list'" />

        <div v-if="detailsMode == 'settings'">
          <p class="lead text-center" v-if="$store.state.dataset.file && $store.state.dataset.file.raster">This is a Raster Dataset and is view only</p>
          <DatasetVizSettings v-if="!$store.state.dataset.file.raster && $store.state.showDatasetVizSettings" />
        </div><!-- v-if settings -->

        <div v-if="detailsMode == 'details'">
          <p><strong>Created:</strong> {{ $store.state.dataset.createdOn | dateTime }}</p>
          <p><strong>Updated:</strong> {{ $store.state.dataset.updatedOn | dateTime }}</p>
          <div v-if="$store.state.dataset.headers.length">
            <p class="mb-1 mt-3"><strong>Headers</strong></p>
            <span
              v-for="(header, index) in $store.state.dataset.headers"
              v-bind:key="index"
              class="badge badge-pill badge-dark mr-1"
            >{{ header }}</span>
          </div><!-- v-if headers -->
          <!-- Dataset Description -->
          <p class="mt-4" v-if="$store.state.dataset.description.length > 0" v-html="$store.state.dataset.description"></p>
        </div><!-- v-if details -->

        <div v-if="detailsMode == 'downloads'">
          <p class="mb-1"><strong>Downloads</strong></p>
          <b-btn
            v-if="$store.state.dataset.file.originalFileName"
            @click="download('original')"
            class="hide-mobile btn-dark mr-1"
          >
            <i class="far fa-file-download"></i> Original
          </b-btn>
          <b-btn
            v-if="$store.state.dataset.file && !$store.state.dataset.file.raster"
            @click="download('geojson')"
            class="hide-mobile btn-dark mr-1"
          >
            <i class="far fa-file-download"></i> GeoJson
          </b-btn>
          <b-btn
            v-if="$store.state.dataset.file && !$store.state.dataset.file.raster"
            @click="download('csv')"
            class="hide-mobile btn-dark mr-1"
          >
            <i class="far fa-file-download"></i> CSV
          </b-btn>
          <b-btn
            @click="print()"
            class="hide-mobile btn-dark mr-1"
          >
            <i class="fas fa-print"></i> Print
          </b-btn>
        </div><!-- v-if downloads -->
      </div><!-- margin top -->

    </b-card>
    <!-- Info toggle -->
    <b-btn
      v-b-tooltip.hover.right="'Dataset Details'"
      @click="$store.commit('showDrawer', 'datasetDetails')"
      class="info_toggle"
      variant="dark"
    >
      <span class="desktop-toggle">
        <i class="fas fa-caret-left"></i> <i class="fas fa-chart-bar"></i>
      </span>
      <span class="mobile-toggle">
        <i class="fas fa-caret-up"></i> Details
      </span>
    </b-btn>
    <Confirm
      v-if="confirmMakeDynamic"
      text="Are you sure you want to make this dataset dynamic? This will generate a new collector and will allow data to be added, changed, and removed from this dataset. A copy of this dataset will be placed in your trash folder."
      v-on:confirmed="makeDynamic($event)"
    />
  </div><!-- /#datasetDetails -->
</template>

<script>
import Confirm from '../Confirm'
import DatasetList from './DatasetList'
import moment from 'moment'
import firebase from 'firebase'
import axios from 'axios'
import shpwrite from 'shp-write'
import DatasetVizSettings from './DatasetVizSettings'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      detailsMode: 'settings',
      confirmMakeDynamic: false
    }
  },
  components: {
    Confirm,
    DatasetList,
    DatasetVizSettings
  },
  methods: {
    makeDynamic (confirm) {
      this.confirmMakeDynamic = false
      if (confirm) {
        // Set map loader
        this.$store.commit('setMapLoader', true)
        // Hide dataset details drawer
        this.$store.commit('tuckDrawer', 'datasetDetails')
        // Gather fields
        const collectorFields = []
        let collectorId = ''
        this.$store.state.dataset.headers.forEach(header => {
          const collectorField = {}
          collectorField.label = header
          const snakeCase = string => {
            string = string.replace(/\W+/g, ' ').toLowerCase().split(' ').join('_')
            if (string.charAt(string.length - 1) === '_') {
              return string.substring(0, string.length - 1)
            }
            return string
          }
          if (isNaN(this.$store.state.datasetGeoJson.features[0].properties[header])) {
            collectorField.type = 'Text'
          } else {
            collectorField.type = 'Number'
          }
          collectorField.machine_name = snakeCase(header)
          collectorField.min = false
          collectorField.max = false
          collectorField.step = false
          collectorField.suffix = ''
          collectorField.required = false
          collectorFields.push(collectorField)
        })

        // Create Collector
        fb.collectorsCollection.add({
          createdOn: new Date(),
          updatedOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          updatedBy: this.$store.state.currentUser.uid,
          ownerId: this.$store.state.currentUser.uid,
          name: this.$store.state.dataset.name,
          description: this.$store.state.dataset.description || '',
          fields: collectorFields,
          atlasId: this.$store.state.atlas.id,
          trashed: false,
          reCompile: true
        }).then(collectorDoc => {
          // const collectorItem = []
          // collectorItem.id = collectorDoc.id
          // const datasetHeaders = []
          // this.collectorItem.fields.forEach(field => {
          //   datasetHeaders.push(field.label)
          // })
          // Create dynamic dataset
          collectorId = collectorDoc.id
          return fb.datasetsCollection.add({
            dynamic: true,
            collectorId: collectorDoc.id,
            createdOn: new Date(),
            updatedOn: new Date(),
            createdBy: this.$store.state.currentUser.uid,
            updatedBy: this.$store.state.currentUser.uid,
            name: this.$store.state.dataset.name || '',
            description: this.$store.state.dataset.description || '',
            headers: this.$store.state.dataset.headers || [],
            atlasId: this.$store.state.atlas.id,
            file: {},
            folderId: 'root'
          })
        }).then(datasetDoc => {
          // const datasetItem = datasetDoc.data()
          // datasetItem.id = datasetDoc.id
          return fb.collectorsCollection.doc(collectorId).update({
            datasetId: datasetDoc.id,
            updatedOn: new Date(),
            updatedBy: this.$store.state.currentUser.uid
          })
        }).then(() => {
          // Populate Collector
          this.$store.state.datasetGeoJson.features.forEach(feature => {
            const dataPointData = {
              accuracy: 1,
              createdBy: this.$store.state.currentUser.uid,
              createdOn: new Date(),
              geolocation: new firebase.firestore.GeoPoint(feature.geometry.coordinates[1], feature.geometry.coordinates[0]),
              updatedBy: this.$store.state.currentUser.uid,
              updatedOn: new Date()
            }
            collectorFields.forEach(field => {
              dataPointData[field.machine_name] = feature.properties[field.label] || ''
            })
            fb.collectorsCollection.doc(collectorId).collection('dataPoints').add(dataPointData)
          })
          // Place old dataset into trash
          fb.datasetsCollection.doc(this.$store.state.dataset.id).update({
            folderId: 'trash',
            updatedBy: this.$store.state.currentUser.uid,
            updatedOn: new Date()
          })
          // Compile new dataset
          // Launch Dataset
          // Remove map loader
          this.$store.commit('setMapLoader', false)
          this.$store.commit('setAlert', { active: true, message: 'Successfully converted dataset to dynamic!', level: 'alert-dark' })
        }).catch(err => {
          console.log(err)
        })
      }
    },
    print () {
      this.$store.commit('setDrawer', { name: 'mapDetails', position: 'out' })
      const self = this
      setTimeout(function () {
        self.$store.commit('setStateProperty', { property: 'printingClass', value: 'printing' })
        document.body.style.backgroundColor = 'white'
        const map = self.$store.state.mapObject
        setTimeout(function () {
          map.resize()
          setTimeout(function () {
            window.print()
            self.$store.commit('setStateProperty', { property: 'printingClass', value: 'null' })
            document.body.style.backgroundColor = 'black'
            self.$store.commit('setDrawer', { name: 'mapDetails', position: 'in' })
          }, 500)
        }, 500)
      }, 1000)
    },
    copyDataset () {
      this.$store.commit('hideDrawer', 'datasetDetails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'copyDataset')
      }, 300)
    },
    hideDatasetDetailsDrawer () {
      this.$store.commit('tuckDrawer', 'datasetDetails')
    },
    download (option) {
      let path
      let fileName
      let cleanDescription
      if (this.$store.state.dataset.file.originalFileName) {
        cleanDescription = this.$store.state.dataset.file.originalFileName
      } else {
        cleanDescription = this.$store.state.dataset.name.replace(/\s+/g, '_')
      }
      if (option === 'original') {
        path = this.$store.state.dataset.file.path
        fileName = cleanDescription
      }
      if (option === 'geojson' || option === 'csv') {
        path = this.$store.state.dataset.file.geoJsonPath
        if (option === 'geojson') {
          fileName = cleanDescription + '.geojson'
        } else if (option === 'csv') {
          fileName = cleanDescription + '.csv'
        }
      }
      if (option === 'shp') {
        path = this.$store.state.dataset.file.geoJsonPath
        fileName = cleanDescription
      }

      const storageRef = firebase.storage().ref(path)

      if (option === 'original' || option === 'geojson') {
        storageRef.getDownloadURL().then(url => {
          return fetch(url)
        }).then(response => {
          return response.blob()
        }).then(blob => {
          return URL.createObjectURL(blob)
        }).then(object => {
          const a = document.createElement('a')
          a.href = object
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }).catch(err => {
          console.log(err)
        })
      } else if (option === 'csv') {
        storageRef.getDownloadURL().then(url => {
          return axios.get(url)
        }).then(response => {
          const geoJsonData = response.data
          const items = []
          geoJsonData.features.forEach(feature => {
            const jsonObject = feature.properties
            if (jsonObject.createdOn) {
              const date = jsonObject.createdOn._seconds * 1000
              jsonObject.createdOn = moment(date).format()
            }
            jsonObject.longitude = feature.geometry.coordinates[0]
            jsonObject.latitude = feature.geometry.coordinates[1]
            items.push(jsonObject)
          })
          const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
          const header = Object.keys(items[0])
          // Check that all headers are represented
          this.$store.state.dataset.headers.forEach(headerItem => {
            if (!header.includes(headerItem)) {
              header.push(headerItem)
            }
          })
          let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
          csv.unshift(header.join(','))
          csv = csv.join('\r\n')
          const blob = new Blob([csv], { type: 'text/csv' })
          return URL.createObjectURL(blob)
        }).then(object => {
          const a = document.createElement('a')
          a.href = object
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }).catch(err => {
          console.log(err.message)
        })
      } else if (option === 'shp') {
        storageRef.getDownloadURL().then(url => {
          return axios.get(url)
        }).then(response => {
          const geoJsonData = response.data
          const downloadOptions = {
            folder: cleanDescription + '_' + option,
            types: {
              point: cleanDescription + '_points',
              polygon: cleanDescription + '_polygons',
              line: cleanDescription + '_lines'
            }
          }
          shpwrite.download(geoJsonData, downloadOptions)
        })
      }
    },
    editDatasetSettings () {
      // this.$store.commit('setDrawer', { name: 'datasetDetails', position: 'out' })
      this.$store.commit('tuckDrawer', 'datasetDetails')
      this.$store.commit('setEditMode', true)
      // this.$store.commit('setStateProperty', { property: 'showCreateDatasetDrawer', value: true })
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createDataset')
      }, 300)
      // const self = this
      // setTimeout(function () {
      //   // self.$store.commit('toggleModal', 'createDataset')
      //   self.$store.commit('setDrawer', { name: 'createDatasetDrawer', position: 'in' })
      // }, 300)
    }
  },
  filters: {
    dateTime (val) {
      if (val === {}) { return '-' }
      const date = val.toDate()
      return moment(date).format('MMMM Do YYYY, h:mm a')
    }
  }
}
</script>

<style scoped>
.download_link {
  color: #056839;
}

.download_link:hover {
  text-decoration: underline;
}

.card-top-nav {
  position: absolute;
  top: 100px;
  z-index: 50;
  background-color: white;
  width: 100%;
  left: 0;
  border: none;
}

.nav-tabs {
  border-bottom: 1px solid black;
}

.card-top-nav .nav-tabs .nav-link {
  border-radius: 0;
  background-color: black;
  color: white;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-left: none;
  border-bottom: 1px solid black;
}

.card-top-nav .nav-link.active {
  background-color: white;
  color: black;
  border-bottom: 1px solid white;
}

.info_toggle {
  position: fixed;
  bottom: 50%;
  left: 10px;
  transition: all 0.2s ease-in-out;
  z-index: 1
}

.mobile-toggle {
  display: none;
}
@media(max-width:800px) {
  .card-top-nav {
    top: 90px;
  }
  .mobile-toggle {
    display: block;
  }
  .desktop-toggle {
    display: none;
  }
  .info_toggle {
    left: calc(50% - 50px);
    bottom: 10px;
  }
}

</style>
