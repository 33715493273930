<template>
  <div id="listDrawer">
    <!-- Data Point Details -->
    <b-card
      id="dataPointDetailsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.listDrawer.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'listDrawer')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4>People</h4>
      </template>
      <p
        v-if="!showTable"
        class="lead text-center"
      ><i class="fas fa-sync fa-spin"></i> Loading...</p>

      <VueFuse
        v-if="showTable"
        :fuseOpts="fuseOptions"
        :list="tableItems"
        placeholder="Search Last Name"
        class="text-center fuse-form mb-2"
        @fuse-results="handleResults"
      />

      <b-table
        v-if="showTable"
        striped
        hover
        :items="listResults"
        :fields="tableFields"
        @row-clicked="launchDataPoint"
        @row-hovered="highlightPoint"
      ></b-table>
    </b-card>
  </div><!-- /#listDrawer -->
</template>

<script>
import VueFuse from 'vue-fuse'

export default {
  data () {
    return {
      showTable: false,
      tableItems: [],
      tableFields: [],
      fuseOptions: {
        keys: ['Last Name']
      },
      listResults: []
    }
  },
  computed: {
    mapObject: {
      get () {
        return this.$store.state.mapObject
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'mapObject', value: value })
      }
    },
    datasetGeoJson: {
      get () {
        return this.$store.state.datasetGeoJson
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'datasetGeoJson', value: value })
      }
    }
  },
  components: {
    VueFuse
  },
  mounted () {
    setTimeout(() => {
      this.initTableData()
    }, 300)
  },
  methods: {
    handleResults (r) {
      this.listResults = []
      r.forEach(result => {
        this.listResults.push(result.item)
      })
    },
    highlightPoint (e) {
      if (typeof this.mapObject.getLayer('selectedDataPoint') !== 'undefined') {
        this.mapObject.removeLayer('selectedDataPoint')
        this.mapObject.removeLayer('selectedDataPointHighlight')
        this.mapObject.removeSource('selectedDataPoint')
      }
      const feature = {
        type: 'Feature',
        geometry: {
          coordinates: e.coordinates,
          type: 'Point'
        }
      }
      // SOURCE FOR THE SELECTED POINT
      this.mapObject.addSource('selectedDataPoint', {
        type: 'geojson',
        data: feature
      })
      // DOT HIGHLIGHT
      this.mapObject.addLayer({
        id: 'selectedDataPointHighlight',
        type: 'circle',
        source: 'selectedDataPoint',
        paint: {
          'circle-radius': 8,
          'circle-color': 'white'
        }
      })
      // RED DOT
      this.mapObject.addLayer({
        id: 'selectedDataPoint',
        type: 'circle',
        source: 'selectedDataPoint',
        paint: {
          'circle-radius': 6,
          'circle-color': 'red'
        }
      })
    },
    launchDataPoint (e) {
      this.highlightPoint(e)
      let dataPoint = {}
      this.datasetGeoJson.features.forEach(feature => {
        if (feature.properties.dataPointId === e.dataPointId) {
          dataPoint = feature.properties
        }
      })
      // DYNAMIC
      this.$store.commit('setStateProperty', {
        property: 'dataset',
        value: {
          dynamic: true
        }
      })
      this.$store.commit('setStateProperty', {
        property: 'collector',
        value: {
          fields: this.datasetGeoJson.fields
        }
      })
      this.$store.commit('setStateProperty', { property: 'dataPoint', value: dataPoint })
      this.$store.commit('hideDrawer', 'listDrawer')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'dataPointDetails')
      }, 300)
    },
    initTableData () {
      console.log(this.datasetGeoJson.features[0])
      this.datasetGeoJson.fields.forEach(field => {
        if (field.type === 'Text' || field.type === 'Select' || field.type === 'Number') {
          this.tableFields.push({
            sortable: true,
            key: field.label
          })
        }
      })
      this.datasetGeoJson.features.forEach(feature => {
        const tableItem = {
          dataPointId: feature.properties.dataPointId,
          coordinates: feature.geometry.coordinates
        }
        this.datasetGeoJson.fields.forEach(field => {
          if (field.type === 'Text' || field.type === 'Select' || field.type === 'Number') {
            tableItem[field.label] = feature.properties[field.label]
          }
        })
        this.tableItems.push(tableItem)
      })
      this.listResults = JSON.parse(JSON.stringify(this.tableItems))
      this.showTable = true
    }
  }
}
</script>

<style scoped>
.blockDrawer {
  top: 56px;
  height: calc(100% - 56px);
}

.blockDrawer.large {
  width: 800px;
}
</style>
