<template>
  <div id="confirm">
    <div
      class="confirm"
    >
      <p>{{ text }}</p>
      <button
        class="btn btn-danger mr-1"
        @click="$emit('confirmed', true)"
      >
        Yes
      </button>
      <button
        class="btn btn-dark"
        @click="$emit('confirmed', false)"
      >
        No
      </button>
    </div><!-- /.confirm -->
  </div><!-- /#confirm -->
</template>

<script>
export default {
  props: ['text']
}
</script>

<style scoped>
#confirm {
  position: fixed;
  padding: 40px 20px;
  border: 1px solid black;
  z-index: 999;
  top: 20%;
  left: 80px;
  width: calc(100% - 160px);
  text-align: center;
  background-color: white;
}
</style>
