<template>
  <div id="dataPointDetails">
    <!-- Data Point Details -->
    <b-card
      id="dataPointDetailsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.dataPointDetails.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="hideDataPointDetails()"
        >
          <i class="far fa-times"></i>
        </a>
        <!-- Data Point Title -->
        <h4>Data Point</h4>
        <div v-if="$store.state.dataset.dynamic">
          <!-- Data Point Edit Button -->
          <b-btn
            v-if="$store.state.role == 'Administrator' || $store.state.role == 'Editor'"
            @click="editDataPoint()"
            class="mb-2"
            variant="dark"
          >
            <i class="fas fa-pencil-alt"></i> Edit Data Point
          </b-btn>
        </div><!-- if dynamic dataset -->
      </template>
      <div v-if="$store.state.dataset.dynamic">
        <!-- FIELDS -->
        <div
          v-for="(field, i) in $store.state.collector.fields"
          v-bind:key="i"
        >
          <div v-if="$store.state.dataPoint[field.label]">
            <!-- TEXT FIELDS -->
            <p
              v-if="field.type !== 'Image' && field.type !== 'Multiselect' && field.type !== 'Formatted Text' && $store.state.dataPoint[field.label].length > 0"
            ><strong>{{ field.label }}:</strong> {{ $store.state.dataPoint[field.label] }}</p>

            <!-- FORMATTED TEXT FIELDS -->
            <p
              v-if="field.type === 'Formatted Text' && $store.state.dataPoint[field.label].length > 0"
              class="mb-0"
            ><strong>{{ field.label }}:</strong></p>
            <div
              v-if="field.type === 'Formatted Text' && $store.state.dataPoint[field.label].length > 0"
              v-html="$store.state.dataPoint[field.label]"
            ></div>

            <!-- MULTISELECT FIELD -->
            <p
              v-if="field.type === 'Multiselect' && $store.state.dataPoint[field.label].length > 0"
            >
              <strong>{{ field.label }}:</strong><br>
              <b-badge
                pill
                variant="dark"
                v-for="(val, i) in $store.state.dataPoint[field.label]"
                v-bind:key="i"
                class="mr-1"
              >{{ val }}</b-badge>
            </p>

            <!-- IMAGE GALLERY -->
            <b-carousel
              v-if="field.type === 'Image' && slideshowsReady && !fullscreenSlides && $store.state.dataPoint[field.label].length > 0"
              :id="field.label"
              controls
              indicators
              class="mb-4"
              :interval="0"
            >
              <b-carousel-slide
                v-for="(image, i) in slideshows[field.label].slides"
                v-bind:key="i"
                :img-src="image.url"
                :text="image.caption"
              ></b-carousel-slide>
              <b-btn
                @click="fullscreenSlides = true"
                class="fullscreenButton"
                variant="dark"
              ><i class="fas fa-expand"></i></b-btn>
            </b-carousel>
            <!-- FULL SCREEN GALLERY -->
            <div
              class="fullscreenSlideshow"
              v-if="field.type === 'Image' && slideshowsReady && fullscreenSlides"
            >
              <b-carousel
                :id="field.label + '_fullscreen'"
                controls
                indicators
                :interval="0"
              >
                <b-carousel-slide
                  v-for="(image, i) in slideshows[field.label].slides"
                  v-bind:key="i"
                  :img-src="image.url"
                  :text="image.caption"
                >
                  <!-- <b-btn
                    @click="downloadImage(image)"
                    class="downloadImageBtn"
                    variant="dark"
                  ><i class="fas fa-download"></i> Download Image</b-btn> -->
                </b-carousel-slide>
              </b-carousel>
              <b-btn
                @click="fullscreenSlides = false"
                class="closeButton"
                variant="dark"
              ><i class="fas fa-times"></i> Close</b-btn>
            </div><!-- /FULLSCREEN SLIDESHOW -->
          </div><!-- /.check for field data -->
        </div><!-- /FIELDS -->
        <p v-if="$store.state.dataPoint.updatedOn != undefined"><strong>Updated:</strong> {{ $store.state.dataPoint.updatedOn | dateTime }}</p>
        <p v-if="$store.state.dataPoint.createdOn != undefined"><strong>Created:</strong> {{ $store.state.dataPoint.createdOn | dateTime }}</p>
      </div><!-- if dynamic -->
      <div v-else>
        <p
          v-for="(field, i) in $store.state.dataPoint.fields"
          v-bind:key="i"
        ><strong>{{ field }}:</strong> {{ $store.state.dataPoint[field] }}</p>
      </div><!-- if dynamic -->
    </b-card>
  </div><!-- /.dataPointDetails -->
</template>

<script>
import moment from 'moment'
import firebase from 'firebase'

export default {
  data () {
    return {
      slideshows: {},
      slideshowsReady: false,
      fullscreenSlides: false
    }
  },
  created () {
    this.getImageUrls()
  },
  mounted () {
    setTimeout(function () {
      // Set all links to open in new tab
      var links = document.links
      for (var i = 0; i < links.length; i++) {
        links[i].target = '_blank'
      }
    }, 1000)
  },
  methods: {
    downloadImage (image) {
      firebase.storage().ref(image.path).getDownloadURL().then(url => {
        window.open(url, '_blank')
      })
    },
    getImageUrl (image, field) {
      return firebase.storage().ref(image.large).getDownloadURL().then(url => {
        this.slideshows[field.label].slides.push({
          path: image.path,
          url: url,
          caption: image.caption
        })
        return true
      }).catch(err => {
        console.log(err)
        return false
      })
    },
    getImageUrls () {
      this.$store.state.collector.fields.forEach(field => {
        if (this.$store.state.dataPoint[field.label] && field.type === 'Multiselect') {
          if (!Array.isArray(this.$store.state.dataPoint[field.label])) {
            this.$store.state.dataPoint[field.label] = JSON.parse(this.$store.state.dataPoint[field.label])
          }
        }
        if (this.$store.state.dataPoint[field.label] && field.type === 'Image') {
          if (!Array.isArray(this.$store.state.dataPoint[field.label])) {
            this.$store.state.dataPoint[field.label] = JSON.parse(this.$store.state.dataPoint[field.label])
          }
          this.slideshows[field.label] = {
            slides: [],
            show: false
          }
          const imagePromises = []
          this.$store.state.dataPoint[field.label].forEach(image => {
            const prom = this.getImageUrl(image, field)
            imagePromises.push(prom)
          })
          Promise.all(imagePromises).then(() => {
            this.slideshowsReady = true
          })
        } else if (this.$store.state.dataPoint[field.label] && field.type === 'Formatted Text') {
          const content = this.$store.state.dataPoint[field.label]
          const fixParenthesis = content.replace(/�s/g, '\'s')
          const removeSpecial = fixParenthesis.replace(/�/g, '"')
          this.$store.state.dataPoint[field.label] = removeSpecial
        }
      })
    },
    editDataPoint () {
      // Set edit mode true
      this.$store.commit('setEditMode', true)
      this.$store.commit('hideDrawer', 'dataPointDetails')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'collector')
      }, 300)
    },
    hideDataPointDetails () {
      if (this.$store.state.mapObject.getLayer('selectedDataPoint')) {
        this.$store.state.mapObject.removeLayer('selectedDataPoint')
        this.$store.state.mapObject.removeLayer('selectedDataPointHighlight')
        this.$store.state.mapObject.removeSource('selectedDataPoint')
      }
      this.$store.commit('hideDrawer', 'dataPointDetails')
    }
  },
  filters: {
    dateTime (val) {
      let utcSeconds
      if (typeof val === 'object') {
        utcSeconds = val._seconds
      } else {
        var jsonVal = JSON.parse(val)
        utcSeconds = jsonVal._seconds
      }
      var d = new Date(0)
      d.setUTCSeconds(utcSeconds)
      return moment(d).fromNow()
      // val = JSON.parse(val)
      // console.log(val)
      // if (val === {}) { return '-' }
      // const date = val.toDate()
      // return moment(date).fromNow()
      // return moment(date).format('MMMM Do YYYY, h:mm a')
    }
  }
}
</script>

<style scoped>
.blockDrawer {
  top: 56px;
  height: calc(100% - 56px);
}
</style>
