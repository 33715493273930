<template>
  <div id="createBlockDrawer">
    <b-card
      id="createBlockDrawerCard"
      class="blockDrawer large"
      :class="$store.state.drawer.createBlockDrawer.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeCreateBlockDrawer()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 v-if="$store.state.display.editMode == false" id="blockTitle">Create Block</h4>
        <h4 v-if="$store.state.display.editMode" id="blockTitle">Update Block</h4>
      </template>
      <form @submit.prevent>
        <!-- BLOCK NAME -->
        <div class="form-group">
          <label for="blockName"><strong>Block Name</strong></label>
          <input
            v-model="block.name"
            id="blockName"
            class="form-control"
          />
        </div><!-- /.form-group -->

        <!-- Description -->
        <div class="form-group">
          <label><strong>Description</strong></label>
          <textarea
            v-model="block.description"
            class="form-control"
          />
        </div><!-- /.form-group -->

        <!-- FIELDS -->
        <div
          v-for="(field, i) in $store.state.atlas.blockFields"
          v-bind:key="i"
          class="form-group mt-4 mb-4"
        >
          <label><strong>{{ field.label }}</strong><span v-if="field.type == 'Number' && field.min && field.max && field.step"><strong>:</strong> {{ block[field.machine_name] }} <span v-if="field.suffix">{{ field.suffix }}</span></span></label>

          <input
            v-if="field.type == 'Text'"
            v-model="block[field.machine_name]"
            class="form-control"
          />

          <input
            v-if="field.type == 'Number' && !field.step"
            v-model="block[field.machine_name]"
            class="form-control"
            type="number"
          />

          <div
            v-if="field.type == 'Number' && field.min && field.max && field.step"
          >
                <b-form-input
                  v-model="block[field.machine_name]"
                  type="range"
                  :min="field.min"
                  :max="field.max"
                  :step="field.step"
                ></b-form-input>
          </div><!-- v-if -->

          <div
            v-if="field.type == 'Select' && showSelects"
          >
            <span
              v-for="(option, i) in field.options"
              v-bind:key="i"
              class="mr-1"
            >
              <b-btn
                v-if="block[field.machine_name] === option"
                @click="selectOption(field.machine_name, option)"
                variant="success"
              >{{ option }}</b-btn>
              <b-btn
                v-else
                @click="selectOption(field.machine_name, option)"
              >{{ option }}</b-btn>
            </span><!-- /OPTIONS -->
          </div><!-- /IF Select -->

          <ImageUpload
            v-if="field.type == 'Image'"
            :blockUpload="true"
            :mediaObject="block[field.machine_name]"
            v-on:mediaDelivery="imagesDelivered($event, field.machine_name)"
          />

        </div><!-- /.form-group -->

        <div class="form-group">
          <label>Import GIS File</label>
          <small class="form-text text-muted mb-2">You can use a zipped shape file or a GeoJson file to import this block's footprint. You may also simply draw the footprint on the map.</small>
          <input
            type="file"
            id="inputGISFile"
            name="inputGISFile"
            value="Import"
            class="mb-2"
            accept=".txt,.json,.geojson,.zip"
          />
        </div><!-- /.form-group -->

        <div class="float-right mb-5 pb-5" style="width:100%;">
          <button
            v-if="$store.state.display.editMode == false"
            @click="createBlock()"
            class="btn btn-dark mr-1"
          >
            Submit
          </button>
          <button
            v-if="$store.state.display.editMode"
            @click="createBlock()"
            class="btn btn-dark mr-1"
          >
            Save
          </button>
          <button
            v-if="$store.state.display.editMode"
            @click="confirmDelete = true"
            class="btn btn-danger mr-1"
          >
            Delete
          </button>
          <button
            @click="closeCreateBlockDrawer()"
            class="btn btn-light"
          >
            Cancel
          </button>
        </div><!-- /.float-right -->
      </form>
    </b-card>
    <Confirm
      v-if="confirmDelete"
      text="DANGER! Are you sure you want to delete this block? This cannot be undone!"
      v-on:confirmed="deleteBlock($event)"
    />
  </div><!-- /#createBlockDrawer -->
</template>

<script>
import Confirm from '../Confirm'
import ImageUpload from '../collector/ImageUpload'
import 'firebase/firestore'
import firebase from 'firebase'
import shp from 'shpjs'
const fb = require('../../firebaseConfig.js')
const turf = require('@turf/turf')

export default {
  data () {
    return {
      confirmDelete: false,
      loadingFromFile: false,
      showSelects: true
    }
  },
  components: {
    Confirm,
    ImageUpload
  },
  computed: {
    block: {
      get () {
        return this.$store.state.block
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'block', value: value })
      }
    },
    atlas: {
      get () {
        return this.$store.state.atlas
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'atlas', value: value })
      }
    },
    drawControlsObject: {
      get () {
        return this.$store.state.drawControlsObject
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'drawControlsObject', value: value })
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    selectOption (field, option) {
      this.showSelects = false
      this.block[field] = option
      this.showSelects = true
    },
    imagesDelivered (e, field) {
      this.block[field] = e
    },
    deleteBlock (confirm) {
      if (confirm) {
        this.confirmDelete = false
        this.atlas.blockFields.forEach((field, i) => {
          if (field.type === 'Image') {
            this.block[field.machine_name].forEach((imageItem, i) => {
              firebase.storage().ref(imageItem.path).delete()
              firebase.storage().ref(imageItem.small).delete()
              firebase.storage().ref(imageItem.medium).delete()
              firebase.storage().ref(imageItem.large).delete()
            })
          }
        })
        return fb.blocksCollection.doc(this.$store.state.block.id).delete().then(() => {
          return fb.atlasesCollection.doc(this.atlas.id).update({
            recompileBlocks: true
          })
        }).then(() => {
          this.closeCreateBlockDrawer()
        }).catch(err => {
          console.log(err.message)
        })
      } else {
        this.confirmDelete = false
        return false
      }
    },
    createBlock () {
      // Check that required fields are all set
      if (!this.block.footprint) {
        // alert('You must either draw this block on the map or upload a footprint GIS file before saving this block!')
        this.$store.commit('setAlert', { active: true, message: 'You must either draw this block on the map or upload a footprint GIS file before saving this block!', level: 'alert-dark', timeout: 5000 })
        return false
      }

      let formComplete = true
      this.atlas.blockFields.forEach((field, i) => {
        let badNumber = false
        if (field.type === 'Number') {
          if (isNaN(this.block[field.machine_name])) {
            badNumber = true
          } else {
            const oldVal = this.block[field.machine_name]
            const newVal = Number(oldVal)
            this.block[field.machine_name] = newVal
          }
        }
        if (field.required) {
          if (this.block[field.machine_name] === undefined || this.block[field.machine_name].length < 1 || badNumber) {
            this.$store.commit('setAlert', { active: true, message: 'Required field missing: ' + field.label, level: 'alert-dark', timeout: 5000 })
            formComplete = false
          }
        }
      })
      if (!formComplete) {
        return false
      }

      this.$store.commit('setMapLoader', true)
      if (this.$store.state.display.editMode === false) {
        // Loop through multi-polygon blocks and generate multiple blocks
        const parsedGISData = JSON.parse(this.block.footprint)
        if (parsedGISData.features.length > 100) {
          // Limit blocks to 100
          this.$store.commit('setAlert', { active: true, message: 'You cannot create more than 100 blocks at a time. Please reach out to support if you need help creating many blocks.', level: 'alert-dark' })
        } else {
          parsedGISData.features.forEach((feature, i) => {
            const thisFootprint = { type: 'FeatureCollection', features: [feature] }
            const thisFootprintString = JSON.stringify(thisFootprint)
            let thisName = this.block.name + '-' + i
            if (i === 0) {
              thisName = this.block.name
            }
            // Get this block area
            const thisArea = turf.area(thisFootprint)
            const blockObject = this.block
            blockObject.name = thisName || ''
            blockObject.description = this.block.description || ''
            blockObject.createdOn = new Date()
            blockObject.updatedOn = new Date()
            blockObject.createdBy = this.$store.state.currentUser.uid || ''
            blockObject.updatedBy = this.$store.state.currentUser.uid || ''
            blockObject.atlasId = this.atlas.id || ''
            blockObject.footprint = thisFootprintString || ''
            blockObject.area = thisArea || ''
            // Create the block
            fb.blocksCollection.add(blockObject).then(() => {
              return fb.atlasesCollection.doc(this.$store.state.atlas.id).update({
                recompileBlocks: true
              })
            }).then(ref => {
              const parsedGISDataLength = parsedGISData.features.length - 1
              if (i === parsedGISDataLength) {
                // this.$store.commit('toggleModal', 'createBlock')
                this.closeCreateBlockDrawer()
              }
            }).catch(err => {
              console.log(err)
            })
          })
        }
      }
      if (this.$store.state.display.editMode === true) {
        // Check to make sure there is no more than one polygon
        const parsedGISData = JSON.parse(this.$store.state.block.footprint)
        if (parsedGISData.features.length > 1) {
          alert('Warning: You cannot add new blocks while editing a block. To add new blocks to your atlas, select "Create Block" from your atlas map.')
          return false
        }
        const thisArea = turf.area(parsedGISData)
        const blockObject = this.block
        blockObject.name = this.block.name || ''
        blockObject.description = this.block.description || ''
        blockObject.updatedOn = new Date()
        blockObject.updatedBy = this.$store.state.currentUser.uid || ''
        blockObject.atlasId = this.atlas.id || ''
        blockObject.footprint = this.block.footprint || ''
        blockObject.area = thisArea || ''
        // Update the block
        fb.blocksCollection.doc(this.$store.state.block.id).update(blockObject).then(() => {
          return fb.atlasesCollection.doc(this.$store.state.atlas.id).update({
            recompileBlocks: true
          })
        }).then(ref => {
          // this.$store.dispatch('fetchBlock', this.$store.state.block.id)
          // this.$store.commit('toggleModal', 'createBlock')
          // // Tell local atlas object to recompile
          // this.atlas.recompileBlocks = true
          // this.$store.dispatch('initAtlasMap')
          this.closeCreateBlockDrawer()
        }).catch(err => {
          console.log(err)
        })
      }
    },
    init () {
      this.$store.dispatch('removeAllLayers')
      /* eslint-disable */
      if (this.$store.state.display.editMode === false && this.loadingFromFile === false) {
        this.block = {}
      }
      const map = this.$store.state.mapObject
      if (this.drawControlsObject) {
        map.removeControl(this.drawControlsObject)
      }
      // DRAW SETTINGS
      this.drawControlsObject = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        }
      })
      map.addControl(this.drawControlsObject)
      map.addLayer({
        'id': 'blocksGreyedLayer',
        'type': 'fill',
        'source': {
          'type': 'geojson',
          'data': this.$store.state.blocksGeoJson
        },
        'layout': {},
        'paint': {
          'fill-color': 'black',
          'fill-opacity': 0.4,
          'fill-outline-color': 'black'
        }
      })

      // If in edit mode, add the existing footprint to draw
      if (this.block.footprint) {
        const footprint = JSON.parse(this.block.footprint)
        this.drawControlsObject.add(footprint)
        map.fitBounds(turf.bbox(footprint), {
          padding: 50,
          animate: false
        })
      }
      const self = this
      // When A Feature is created, write it to this.block.footprint
      map.on('draw.create', function (e) {
        self.block.footprint = JSON.stringify(self.drawControlsObject.getAll())
      })
      map.on('draw.delete', function (e) {
        self.block.footprint = JSON.stringify(self.drawControlsObject.getAll())
      })
      map.on('draw.update', function (e) {
        self.block.footprint = JSON.stringify(self.drawControlsObject.getAll())
      })

      // Handle uploaded GeoJson
      setTimeout(function () {
        document.getElementsByName('inputGISFile')[0].addEventListener('change', onFileSelect)
      }, 100)

      function onFileSelect (event) {
        // What is file's extension?
        const fileName = event.target.files[0].name
        const lastDot = fileName.lastIndexOf('.')
        const fileExt = fileName.substring(lastDot + 1)
        const reader = new FileReader()

        if (fileExt === 'geojson') {
          reader.onload = onGeoJsonLoad
          reader.readAsText(event.target.files[0])
        }

        if (fileExt === 'zip') {
          reader.onload = onShpLoad
          reader.readAsArrayBuffer(event.target.files[0])
        }
      }

      function onGeoJsonLoad (event) {
        const obj = JSON.parse(event.target.result)
        if (obj.crs) {
          delete obj.crs
        }
        const finalGeoJson = {
          type: 'FeatureCollection',
          features: []
        }
        // Push all polygons to finalGeoJson
        obj.features.forEach(feature => {
          if (feature.geometry.type === 'Polygon') {
            finalGeoJson.features.push(feature)
          }
        })
        // Push any points to a polygon and push to the finalGeoJson
        const pointsPolygon = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: [[]]
          }
        }
        obj.features.forEach(point => {
          if (point.geometry.type === 'Point') {
            pointsPolygon.geometry.coordinates[0].push(point.geometry.coordinates)
          }
        })
        if (pointsPolygon.geometry.coordinates[0].length > 0) {
          // Push the first point again in order to complete the polygon
          pointsPolygon.geometry.coordinates[0].push(pointsPolygon.geometry.coordinates[0][0])
          finalGeoJson.features.push(pointsPolygon)
        }
        var objString = JSON.stringify(finalGeoJson)
        self.block.footprint = objString
        self.loadingFromFile = true
        self.init()
      }

      function onShpLoad (event) {
        shp(event.target.result).then(shpgeojson => {
          var objString = JSON.stringify(shpgeojson)
          self.block.footprint = objString
          self.loadingFromFile = true
          self.init()
        })
      }
    },
    closeCreateBlockDrawer () {
      const map = this.$store.state.mapObject
      map.removeControl(this.drawControlsObject)
      this.drawControlsObject = false
      // this.$store.commit('setDrawer', { name: 'createBlockDrawer', position: 'out' })
      // this.$store.commit('setStateProperty', { property: 'showCreateBlockDrawer', value: false })
      this.$store.commit('hideDrawer', 'createBlockDrawer')
      this.$store.dispatch('removeAllLayers')
      setTimeout(() => {
        this.$store.dispatch('initAtlasMap')
      }, 300)
    }
  }
}
</script>
