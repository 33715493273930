import firebase from 'firebase'
import 'firebase/firestore'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyBigCbSPYDSe_Z7XZwypurJ2uAt0mOWWTU',
  authDomain: 'the-mapping-service.firebaseapp.com',
  projectId: 'the-mapping-service',
  storageBucket: 'the-mapping-service.appspot.com',
  messagingSenderId: '407502800211',
  appId: '1:407502800211:web:7c38ecc4d738a7d74ccda6',
  measurementId: 'G-N92L4Z84XV'
}
firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const storage = firebase.storage()
const auth = firebase.auth()
const currentUser = auth.currentUser

// date issue fix according to firebase
const settings = {
}
db.settings(settings)

// firebase collections
const usersCollection = db.collection('users')
const datasetsCollection = db.collection('datasets')
const atlasesCollection = db.collection('atlases')
const cultivarsCollection = db.collection('cultivars')
const blocksCollection = db.collection('blocks')
const interpolationRequestsCollection = db.collection('interpolationRequests')
const notificationsCollection = db.collection('notifications')
const collectorsCollection = db.collection('collectors')
const compileRequestsCollection = db.collection('compileRequests')
const compileBlocksRequestsCollection = db.collection('compileBlocksRequests')
const editRequestsCollection = db.collection('editRequests')
const copyDatasetRequestsCollection = db.collection('copyDatasetRequests')
const foldersCollection = db.collection('folders')
const statisticsCollection = db.collection('statistics')

export {
  db,
  storage,
  auth,
  currentUser,
  usersCollection,
  datasetsCollection,
  atlasesCollection,
  cultivarsCollection,
  blocksCollection,
  interpolationRequestsCollection,
  notificationsCollection,
  collectorsCollection,
  compileRequestsCollection,
  compileBlocksRequestsCollection,
  editRequestsCollection,
  copyDatasetRequestsCollection,
  foldersCollection,
  statisticsCollection
}
