<template>
  <section id="userSettings">
    <b-card
      id="userSettingsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.userSettings.position"
    >
      <template v-slot:header>
        <a
          @click="closeUserSettings()"
          class="close clickable"
        >
          <i class="far fa-times"></i>
        </a>
        <h4>User Settings</h4>
      </template>

      <!-- <transition name="fade">
        <div v-if="showSuccess" class="alert alert-dark" style="position:fixed;top:20px;right:20px;width:200px;" role="alert">
          Profile updated!
        </div>
      </transition> -->
      <!-- User Profile -->
      <form @submit.prevent>
        <div class="row">
          <div class="col">
            <label for="firstName">First Name</label>
            <input v-model="profileData.firstName" type="text" placeholder="Jane" id="firstName" class="form-control" />
          </div><!-- /.col -->
          <div class="col">
            <label for="lastName">Last Name</label>
            <input v-model="profileData.lastName" type="text" placeholder="Goodall" id="lastName" class="form-control" />
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="form-group mt-2">
          <label for="company">Company</label>
          <input v-model="profileData.company" type="text" placeholder="Company" id="company" class="form-control" />
        </div><!-- /.form-group -->
        <b-form-checkbox
          v-model="profileData.emailNotifications"
          name="check-button"
          switch
        >
          Email notifications
        </b-form-checkbox>
        <b-form-group label="Measurement System" v-slot="{ ariaDescribedby }" class="mt-2">
          <b-form-radio v-model="profileData.measurementSystem" :aria-describedby="ariaDescribedby" name="measurement-system-radios" value="Metric">Metric</b-form-radio>
          <b-form-radio v-model="profileData.measurementSystem" :aria-describedby="ariaDescribedby" name="measurement-system-radios" value="Imperial">Imperial</b-form-radio>
        </b-form-group>
        <button
          @click="updateProfile()"
          class="btn btn-dark mt-4"
        >Update Profile</button>
      </form>

      <hr>
      <h5>Update Email Address</h5>
      <form @submit.prevent>
        <div class="form-group">
          <label>Email</label>
          <input class="form-control" v-model="newEmail" :placeholder="currentUser.email" />
        </div><!-- /.form-group -->
        <button class="btn btn-dark" @click="updateEmail()">Update Email</button>
      </form>
      <hr>
      <h5>Update Password</h5>
      <form @submit.prevent>
        <div class="form-group">
          <label>New Password</label>
          <input class="form-control" v-model="newPassword" type="password" placeholder="******" />
        </div><!-- /.form-group -->
        <button class="btn btn-dark" @click="updatePassword()">Update Password</button>
      </form>
      <hr>
      <div class="float-right">
        <button
          @click="closeUserSettings()"
          class="btn btn-light"
        >
          Cancel
        </button>
      </div><!-- /.btn-group -->
    </b-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      newPassword: '',
      newEmail: '',
      // showSuccess: false,
      profileData: {
        firstName: '',
        lastName: '',
        company: '',
        emailNotifications: true,
        measurementSystem: 'Imperial'
      }
    }
  },
  computed: {
    ...mapState(['userProfile', 'currentUser'])
  },
  created () {
    this.profileData = this.userProfile
  },
  methods: {
    closeUserSettings () {
      // this.$store.commit('toggleModal', 'settings')
      // this.$store.commit('setDrawer', { name: 'userDetails', position: 'in' })
      this.$store.commit('hideDrawer', 'userSettings')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'userDetails')
      }, 300)
    },
    updateEmail () {
      const self = this
      fb.auth.currentUser.updateEmail(this.newEmail).then(function () {
        fb.usersCollection.doc(self.currentUser.uid).update({
          email: self.newEmail
        })
        // alert('Successfully updated your email!')
        self.$store.commit('setAlert', { active: true, message: 'Successfully updated your email!', level: 'alert-dark', timeout: 3000 })
        self.newEmail = ''
      }).catch(function (err) {
        alert(err.message)
      })
    },
    updatePassword () {
      const self = this
      fb.auth.currentUser.updatePassword(this.newPassword).then(function () {
        // alert('Successfully updated your password!')
        self.$store.commit('setAlert', { active: true, message: 'Successfully updated your password!', level: 'alert-dark', timeout: 3000 })
        self.newPassword = ''
      }).catch(function (err) {
        // alert(err.message)
        self.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-dark', timeout: 3000 })
      })
    },
    updateProfile () {
      const self = this
      fb.usersCollection.doc(self.$store.state.currentUser.uid).update({
        firstName: self.profileData.firstName,
        lastName: self.profileData.lastName,
        company: self.profileData.company,
        emailNotifications: self.profileData.emailNotifications,
        measurementSystem: self.profileData.measurementSystem
      }).then(() => {
        self.$store.commit('setAlert', { active: true, message: 'Updated your profile!', level: 'alert-dark', timeout: 3000 })
      }).catch(err => {
        self.$store.commit('setAlert', { active: true, message: err.message, level: 'alert-dark', timeout: 3000 })
      })
      // this.$store.dispatch('updateProfile', {
      //   firstName: this.profileData.firstName,
      //   lastName: this.profileData.lastName,
      //   company: this.profileData.company,
      //   emailNotifications: this.profileData.emailNotifications
      // })
      // this.$store.commit('setAlert', { active: true, message: 'Updated your profile!', level: 'alert-dark', timeout: 3000 })
      // this.showSuccess = true
      // setTimeout(() => { this.showSuccess = false }, 3000)
    }
  }
}
</script>
