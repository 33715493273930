<template>
  <div id="atlasesMap"></div>
</template>

<script>
import firebase from 'firebase'

export default {
  mounted () {
    this.initMap()
  },
  methods: {
    initMap () {
      mapboxgl.accessToken = 'pk.eyJ1IjoidGhlbWFwcGluZ3NlcnZpY2UiLCJhIjoiY2t1aWJ2c3hsMWkwYzJ1cGhyNjRndWhzZiJ9.Dm5XwBzvVMaXNK0BpiQLHA' // eslint-disable-line
      let map = new mapboxgl.Map({ // eslint-disable-line
        container: 'atlasesMap',
        style: 'mapbox://styles/themappingservice/ckuibhxr005nf18pk5yl9dnay',
        center: [-79, 0],
        zoom: 1
      })
      map.on('load', function () {
        const atlasesGeoJsonStorageRef = firebase.storage().ref('statistics/atlases/atlases.geojson')
        atlasesGeoJsonStorageRef.getDownloadURL().then(atlasesGeoJsonUrl => {
          map.addSource('atlasesSource', {
            type: 'geojson',
            data: atlasesGeoJsonUrl
          })
          map.addLayer({
            id: 'atlasesLayer',
            type: 'circle',
            source: 'atlasesSource',
            layout: {},
            paint: {
              'circle-radius': 10,
              'circle-color': '#ffc107'
            }
          })
          // CLICK EVENTS
          map.on('click', 'atlasesLayer', function (e) {
            var coordinates = e.features[0].geometry.coordinates.slice()
            var description = '<a href="/a/' + e.features[0].properties.id + '" target="_blank" >' + e.features[0].properties.name + '</a>'

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
            }
            new mapboxgl.Popup().setLngLat(coordinates).setHTML(description).addTo(map) // eslint-disable-line
          })
          // MOUSE ENTER AND LEAVE POINTERS
          map.on('mouseenter', 'atlasesLayer', function () {
            map.getCanvas().style.cursor = 'pointer'
          })
          map.on('mouseleave', 'atlasesLayer', function () {
            map.getCanvas().style.cursor = ''
          })
        }) // axios response
      })
    }
  }
}
</script>

<style scoped>
#atlasesMap {
  width: 100%;
  height: 550px;
  border-radius: 20px;
  color: black;
}
</style>
