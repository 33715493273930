<template>
  <div id="createFolder">
    <div
      class="confirm"
    >
      <form @submit.prevent>
        <!-- NAME -->
        <div class="form-group">
          <label for="folderName">Folder Name</label>
          <input
            v-model="tempFolder.name"
            id="folderName"
            class="form-control"
          />
        </div><!-- /.form-group -->

        <!-- DESCRIPTION -->
        <div class="form-group">
          <label for="folderDescription">Folder Description</label>
          <textarea
            v-model="tempFolder.description"
            id="folderDescription"
            class="form-control"
          />
        </div><!-- /.form-group -->
        <button
          v-if="$store.state.editMode"
          class="btn btn-dark mr-1"
          @click="saveFolder()"
        >
          Save
        </button>
        <button
          v-if="!$store.state.editMode"
          class="btn btn-dark mr-1"
          @click="saveFolder()"
        >
          Create Folder
        </button>
        <button
          class="btn btn-light mr-1"
          @click="closeCreateFolder()"
        >
          Cancel
        </button>
        <button
          v-if="$store.state.editMode"
          class="btn btn-danger"
          @click="trashFolder()"
        >
          Trash
        </button>
      </form>
    </div><!-- /.confirm -->
  </div><!-- /#confirm -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      tempFolder: {
        name: '',
        description: ''
      }
    }
  },
  props: ['selectedFolder'],
  created () {
    if (!this.$store.state.editMode) {
      this.tempFolder.name = ''
      this.tempFolder.description = ''
    } else {
      this.tempFolder = this.selectedFolder
    }
  },
  computed: {
    folder: {
      get () {
        return this.$store.state.folder
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'folder', value: value })
      }
    }
  },
  methods: {
    closeCreateFolder () {
      this.$store.commit('setStateProperty', { property: 'editMode', value: false })
      this.$store.commit('setStateProperty', { property: 'createFolderPrompt', value: false })
    },
    saveFolder () {
      if (this.$store.state.editMode) {
        fb.foldersCollection.doc(this.tempFolder.id).update({
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid,
          name: this.tempFolder.name,
          description: this.tempFolder.description
        }).then(() => {
          this.closeCreateFolder()
        })
      } else {
        fb.foldersCollection.add({
          createdOn: new Date(),
          updatedOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          updatedBy: this.$store.state.currentUser.uid,
          atlasId: this.$store.state.atlas.id,
          name: this.tempFolder.name,
          description: this.tempFolder.description,
          folderId: this.$store.state.folder.id
        }).then(() => {
          this.$store.commit('setStateProperty', { property: 'createFolderPrompt', value: false })
        })
      }
    }
  }
}
</script>

<style scoped>
#createFolder {
  position: absolute;
  padding: 20px;
  z-index: 999;
  top: 120px;
  left: 80px;
  width: 300px;
  background-color: white;
  border: 1px solid black;
}
</style>
