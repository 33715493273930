<template>
  <div id="copyDatasetDrawer">
    <b-card
      id="copyDatasetDrawerCard"
      class="blockDrawer large"
      :class="$store.state.drawer.copyDataset.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeCopyDatasetDrawer('datasetDetails')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4><i class="fal fa-copy"></i> Copy Dataset</h4>
      </template>
      <form @submit.prevent>
        <!-- NEW NAME -->
        <div class="form-group">
          <label for="datasetNewName"><strong>New Dataset Name</strong></label>
          <input
            v-model="newName"
            id="datasetNewName"
            class="form-control"
          />
        </div><!-- /.form-group -->
        <div>
          <b-form-checkbox
            id="filter-checkbox"
            v-model="filter"
            name="filter-checkbox"
            :value="true"
            :unchecked-value="false"
            class="mb-2"
          >
            Filter data before copying
          </b-form-checkbox>

          <p v-if="filter === true" class="text-danger"><small>This will remove datapoints based on any filters you have set. Leave this unckecked if you want to copy the entire dataset.</small></p>
        </div><!-- Checkbox container -->
        <div>
          <!-- <b-form-checkbox
            id="trim-checkbox"
            v-model="trim"
            name="filter-checkbox"
            :value="true"
            :unchecked-value="false"
            class="mb-2"
            @input="trimSelect()"
          >
            Trim data using selected blocks
          </b-form-checkbox> -->

          <p v-if="trim === true" class="text-danger"><small>This will remove datapoints outside of blocks you select on the map. Leave this unckecked if you want to copy the entire dataset.</small></p>
          <p v-if="trim === true"><strong>Selected Blocks:</strong></p>
          <ul v-if="trim === true">
            <li
              class="text-danger"
              v-if="selectedBlocks.length < 1"
            >Please select at least one block from the map.</li>
            <li
              v-for="(block, index) in selectedBlocks"
              v-bind:key="index"
            >{{ block.name }}</li>
          </ul>
        </div><!-- Checkbox container -->
        <div class="float-right mt-3 mb-5" style="width:100%;">
          <button
            @click="copyDataset()"
            class="btn btn-dark mr-1"
          >
            Copy Dataset
          </button>
          <button
            @click="closeCopyDatasetDrawer('datasetDetails')"
            class="btn btn-light"
          >
            Cancel
          </button>
        </div><!-- /.float-right -->
      </form>
    </b-card>
  </div><!-- /#copyDataset -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      newName: '',
      filter: false,
      trim: false,
      selectedBlocksGeoJson: {
        type: 'FeatureCollection',
        features: []
      }
    }
  },
  created () {
    this.newName = 'Copy_' + this.$store.state.dataset.name
  },
  computed: {
    selectedBlocks: {
      get () {
        return this.$store.state.selectedBlocks
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'selectedBlocks', value: value })
      }
    }
  },
  methods: {
    copyDataset () {
      fb.copyDatasetRequestsCollection.add({
        createdOn: new Date(),
        createdBy: this.$store.state.currentUser.uid,
        atlasId: this.$store.state.atlas.id,
        datasetId: this.$store.state.dataset.id,
        filter: this.filter,
        trim: this.trim,
        trimBlocks: this.selectedBlocks,
        newName: this.newName
      }).then(() => {
        this.$store.commit('setAlert', { active: true, message: 'The Dataset is copying and will appear in your datasets drawer soon.', level: 'alert-dark', timeout: 3000 })
        // this.$store.commit('hideDrawer', 'copyDataset')
        // setTimeout(() => {
        //   this.$store.commit('showDrawer', 'datasets')
        // }, 300)
        this.closeCopyDatasetDrawer('datasets')
      }).catch(err => {
        console.log(err)
      })
    },
    closeCopyDatasetDrawer (nextDrawer) {
      // this.$store.dispatch('removeSelectBlocks')
      this.selectedBlocks = []
      if (this.$store.state.mapObject.getLayer('selectBlocksLayer')) {
        this.$store.state.mapObject.removeLayer('selectBlocksLayer')
        this.$store.state.mapObject.removeLayer('selectBlocksLayerOutline')
        this.$store.state.mapObject.removeSource('selectBlocksLayer')
        this.$store.state.mapObject.removeSource('selectBlocksLayerOutline')
      }
      if (this.$store.state.mapObject.getLayer('selectBlockHighlightLayer')) {
        this.$store.state.mapObject.removeLayer('selectBlockHighlightLayer')
        this.$store.state.mapObject.removeSource('selectBlockHighlightLayer')
      }
      // this.$store.commit('setDrawer', { name: 'datasetDetails', position: 'in' })
      // this.filter = false
      // this.trim = false
      // const self = this
      // setTimeout(function () {
      //   self.$store.commit('setStateProperty', { property: 'showCopyDatasetDrawer', value: false })
      // }, 300)
      this.$store.state.mapObject.off('click', 'selectBlocksLayer', this.highlightSelectedBlock)
      this.$store.commit('hideDrawer', 'copyDataset')
      setTimeout(() => {
        this.$store.commit('showDrawer', nextDrawer)
      }, 300)
    },
    highlightSelectedBlock (e) {
      const self = this
      self.selectedBlocksGeoJson.features.push({
        type: 'Feature',
        properties: e.features[0].properties,
        geometry: e.features[0].geometry
      })
      // REMOVE ANY SELECTED BLOCKS FROM MAP
      if (self.$store.state.mapObject.getLayer('selectBlockHighlightLayer')) {
        self.$store.state.mapObject.removeLayer('selectBlockHighlightLayer')
        self.$store.state.mapObject.removeSource('selectBlockHighlightLayer')
      }
      // CHECK IF BLOCK IS ALREADY SELECTED
      let blockAlreadySelected = false
      self.selectedBlocks.forEach(selectedBlock => {
        if (selectedBlock.id === e.features[0].properties.blockId) {
          blockAlreadySelected = true
        }
      })
      if (blockAlreadySelected) {
        // Deselect block
        for (let i = self.selectedBlocksGeoJson.features.length - 1; i >= 0; i--) {
          if (self.selectedBlocksGeoJson.features[i].properties.blockId === e.features[0].properties.blockId) {
            self.selectedBlocksGeoJson.features.splice(i, 1)
            const selectedBlocksNew = []
            for (let me = self.selectedBlocks.length - 1; me >= 0; me--) {
              if (self.selectedBlocks[me].id !== e.features[0].properties.blockId) {
                selectedBlocksNew.push(self.selectedBlocks[me])
              }
            }
            self.selectedBlocks = selectedBlocksNew
          }
        }
      } else {
        // Or add block
        fb.blocksCollection.doc(e.features[0].properties.blockId).get().then(doc => {
          const block = doc.data()
          block.id = doc.id
          self.selectedBlocks.push({
            footprint: block.footprint,
            id: block.id,
            name: block.name
          })
        })
      }
      // Re-add selected blocks
      self.$store.state.mapObject.addLayer({
        id: 'selectBlockHighlightLayer',
        type: 'fill',
        source: {
          type: 'geojson',
          data: self.selectedBlocksGeoJson
        },
        layout: {},
        paint: {
          'fill-color': '#ffc107', // eslint-disable-line
          'fill-opacity': 0.8, // eslint-disable-line
        }
      })
    },
    trimSelect () {
      if (this.trim === true) {
        this.$store.state.mapObject.addLayer({
          id: 'selectBlocksLayer',
          type: 'fill',
          source: {
            type: 'geojson',
            data: this.$store.state.blocksGeoJson
          },
          layout: {},
          paint: {
            'fill-color': '#6e59c7',
            'fill-opacity': 0.8
          }
        })
        this.$store.state.mapObject.addLayer({
          id: 'selectBlocksLayerOutline',
          type: 'line',
          source: {
            type: 'geojson',
            data: this.$store.state.blocksGeoJson
          },
          layout: {},
          paint: {
            'line-width': 2,
            'line-color': '#eee'
          }
        })
        const self = this
        this.$store.state.mapObject.on('mouseenter', 'selectBlocksLayer', function () {
          self.$store.state.mapObject.getCanvas().style.cursor = 'pointer'
        })
        this.$store.state.mapObject.on('mouseleave', 'selectBlocksLayer', function () {
          self.$store.state.mapObject.getCanvas().style.cursor = ''
        })
        // SELECT BLOCKS LAYER LISTENER CLICK
        this.$store.state.mapObject.on('click', 'selectBlocksLayer', this.highlightSelectedBlock)
      } else {
        this.selectedBlocks = []
        if (this.$store.state.mapObject.getLayer('selectBlocksLayer')) {
          this.$store.state.mapObject.removeLayer('selectBlocksLayer')
          this.$store.state.mapObject.removeLayer('selectBlocksLayerOutline')
          this.$store.state.mapObject.removeSource('selectBlocksLayer')
          this.$store.state.mapObject.removeSource('selectBlocksLayerOutline')
        }
        if (this.$store.state.mapObject.getLayer('selectBlockHighlightLayer')) {
          this.$store.state.mapObject.removeLayer('selectBlockHighlightLayer')
          this.$store.state.mapObject.removeSource('selectBlockHighlightLayer')
        }
        this.selectedBlocksGeoJson = {
          type: 'FeatureCollection',
          features: []
        }
        this.$store.state.mapObject.off('click', 'selectBlocksLayer', this.highlightSelectedBlock)
      }
    }
  }
}
</script>
