<template>
  <div id="atlasMap" :class="$store.state.display.mapClass">
    <div v-if="$store.state.display.showMap" id="map"></div>
    <!-- MAP SPINNER -->
    <transition name="fade">
      <div id="map-spinner" v-if="$store.state.loadingMap">
        <RingLoader
          :color="'white'"
        ></RingLoader>
      </div><!-- /#map-spinner -->
    </transition>
  </div><!-- /#atlasMap -->
</template>

<script>
import RingLoader from 'vue-spinner/src/GridLoader'

export default {
  data () {
    return {
      geolocationObject: false
    }
  },
  mounted () {
    this.$store.commit('setMapLoader', true)
    const viewportWidth = window.innerWidth
    if (viewportWidth < 800) {
      this.getUserLocation()
    } else {
    }
    setTimeout(() => {
      this.$store.dispatch('initAtlasMap')
    }, 1000)
  },
  components: {
    RingLoader
  },
  methods: {
    handleLocation (position) {
      this.$store.commit('setStateProperty', { property: 'userLocation', value: position.coords })
      if (position.coords.accuracy > 30) {
        const self = this
        navigator.geolocation.clearWatch(self.geolocationObject)
        setTimeout(function () {
          self.getUserLocation()
        }, 3000)
      }
    },
    errorGettingLocation (err) {
      console.log(err.message)
    },
    getUserLocation () {
      if (navigator.geolocation) {
        this.geolocationObject = navigator.geolocation.watchPosition(this.handleLocation, this.errorGettingLocation, {
          enableHighAccuracy: true
        })
      } else {
        console.log('No Geolocation')
      }
    }
  }
}
</script>

<style scoped>
#atlasMap {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  /* transition: all 0.2s ease-in-out; */
}

/* #atlasMap.in {
  width: calc(100% - 500px);
}
#atlasMap.smallIn {
  width: calc(100% - 300px);
} */

#map {
  position: absolute;
  width: 100%;
  height: 100%;
}

#map-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0,0,0,0.5);
}

.v-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
}
/*
.v-ring {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
} */

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
