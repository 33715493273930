<template>
  <div id="collaborators">
    <b-card
      class="blockDrawer large"
      :class="$store.state.drawer.collaborators.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeCollaborators()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">Atlas Collaborators</h4>
      </template>
      <p
        v-if="$store.state.atlas.ownerData"
      ><strong>Atlas Owner:</strong> {{ $store.state.atlas.ownerData.email }}</p>
      <!-- INVITATION BLOCK -->
      <form @submit.prevent class="form-inline">
        <div class="form-group">
          <input
            v-model="invitation.email"
            class="form-control mr-2"
            placeholder="Enter email address"/>
        </div><!-- /.form-group -->
        <button
          @click="invite()"
          type="submit"
          class="btn btn-dark"
        >Invite</button>
      </form>
      <table v-if="$store.state.atlas.collaboratorData && $store.state.atlas.users.length > 0" class="table table-striped table-hover mt-3">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(collaborator, index) in $store.state.atlas.collaboratorData"
            v-bind:key="index"
          >
            <td>{{ collaborator.email }}</td>
            <td>
              <b-form-select
                v-model="atlas.permissions[collaborator.id]"
                :options="permissionOptions"
                class="mb-3"
                size="sm"
              />
            </td>
            <td>
              <i
                class="fal fa-user-slash clickable"
                v-b-tooltip.hover
                title="Remove Collaborator"
                @click="confirmRemove = { confirm: true, index: index, userId: collaborator.id }"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="float-right">
        <button
          v-if="$store.state.atlas.collaboratorData && $store.state.atlas.users.length > 0"
          @click="savePermissions()"
          class="btn btn-dark mr-1"
        >Save Permissions</button>
        <button
          @click="closeCollaborators()"
          class="btn btn-light"
        >
          Cancel
        </button>
      </div><!-- /.btn-group -->
    </b-card>
    <Confirm
      v-if="confirmRemove.confirm"
      text="Are you sure you want to remove this user from this atlas?"
      v-on:confirmed="removeCollaborator($event)"
    />
  </div><!-- /#collaborators -->
</template>

<script>
import Confirm from '../Confirm'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      confirmRemove: {
        confirm: false,
        index: 0,
        userId: ''
      },
      permissionOptions: ['Editor', 'Administrator'],
      invitation: {
        email: ''
      }
    }
  },
  components: {
    Confirm
  },
  computed: {
    atlas: {
      get () {
        return this.$store.state.atlas
      },
      set (value) {
        this.$store.commit('setAtlas', value)
      }
    }
  },
  methods: {
    closeCollaborators () {
      this.$store.commit('hideDrawer', 'collaborators')
      const self = this
      setTimeout(function () {
        self.$store.commit('showDrawer', 'atlasDetails')
      }, 300)
    },
    savePermissions () {
      const self = this
      fb.atlasesCollection.doc(self.atlas.id).update({
        permissions: self.atlas.permissions,
        updatedOn: new Date()
      }).then(e => {
        this.closeCollaborators()
      }).catch(err => {
        console.log(err)
      })
    },
    removeCollaborator (confirmed) {
      const index = this.confirmRemove.index
      const userId = this.confirmRemove.userId
      const self = this
      if (confirmed) {
        self.confirmRemove.confirm = false
        // Remove user from this atlas.users
        self.atlas.users.splice(index, 1)
        // Remove user from this atlas.permissions
        delete self.atlas.permissions[userId]
        fb.atlasesCollection.doc(self.atlas.id).update({
          users: self.atlas.users,
          permissions: self.atlas.permissions,
          updatedOn: new Date()
        }).then(doc => {
          self.$store.dispatch('fetchAtlas', self.atlas.id)
        })
      } else {
        self.confirmRemove.confirm = false
        return false
      }
    },
    invite () {
      const self = this
      fb.usersCollection.where('email', '==', self.invitation.email).get().then(querySnapshot => {
        if (querySnapshot.docs.length < 1) {
          // alert('This email address does not exist in our system!')
          this.$store.commit('setAlert', { active: true, message: 'This email address does not exist in our system!', level: 'alert-dark', timeout: 5000 })
        } else {
          const uid = querySnapshot.docs[0].id
          // Add user to atlas
          self.atlas.users.push({ id: uid })
          // Add permission record to atlas's permissions
          self.atlas.permissions[uid] = 'Editor'
          fb.atlasesCollection.doc(self.atlas.id).update({
            users: self.atlas.users,
            permissions: self.atlas.permissions,
            updatedOn: new Date()
          }).then(item => {
            self.$store.dispatch('fetchAtlas', self.atlas.id)
            const notificationMessage = 'Mapping Service user, ' + self.$store.state.userProfile.email + ', has added you as a collaborator on ' + self.$store.state.atlas.name + '.'
            const notificationUrl = 'https://themappingservice.com/a/' + self.$store.state.atlas.id
            const notificationSubject = 'Mapping Service Notification'
            fb.notificationsCollection.add({
              createdOn: new Date(),
              updatedOn: new Date(),
              createdBy: self.$store.state.currentUser.uid,
              atlasId: self.$store.state.atlas.id,
              subject: notificationSubject,
              message: notificationMessage,
              recipient: uid,
              url: notificationUrl,
              read: false
            })
            // alert('Successfully invited collaborator!')
            this.$store.commit('setAlert', { active: true, message: 'Successfully invited collaborator!', level: 'alert-dark', timeout: 5000 })
          })
        }
      })
    }
  }
}
</script>
