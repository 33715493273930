<template>
  <div id="hs2">
    <b-navbar id="hs2Nav" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand>CHQ Historic Structures Database</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <!-- <b-nav-item @click="showList()">List</b-nav-item> -->
            <b-nav-item-dropdown text="Illustrations" right>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fillustrations%2FBusinesses%201851.pdf?alt=media&token=672996b2-1153-418e-94a3-fe2547e77de5"
                target="_blank"
              >Businesses 1851</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fillustrations%2FHouses%201851.pdf?alt=media&token=dce1c0d7-1a76-40ac-950b-d9b522116ca9"
                target="_blank"
              >Houses 1851</b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- <b-nav-item
              href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2FHS2%20About.pdf?alt=media&token=050406fe-4b71-4c07-af6f-d2210c1a94fa"
              target="_blank"
            >About</b-nav-item> -->
            <b-nav-item
              href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2FAbout_Revised.pdf?alt=media&token=11f3dfe5-7c32-425c-a21a-52e8c930f05c"
              target="_blank"
            >About</b-nav-item>
            <!-- <b-nav-item
              href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2FHS2%20FAQs.pdf?alt=media&token=c33d3cb5-fb58-4fb2-9478-ec3bbfef909b"
              target="_blank"
            >FAQs</b-nav-item> -->
            <b-nav-item-dropdown text="Reference Maps" right>
              <b-dropdown-item
                href="https://www.loc.gov/resource/g3803c.la000480/"
                target="_blank"
              >1854 Chaut Co NY Map</b-dropdown-item>
              <b-dropdown-item
                href="https://www.loc.gov/resource/g3803e.la000494/"
                target="_blank"
              >1855 Erie Co NY Map</b-dropdown-item>
              <b-dropdown-item
                href="https://www.loc.gov/item/2012592169/"
                target="_blank"
              >1855 Erie Co PA Map</b-dropdown-item>
              <b-dropdown-item
                href="https://www.loc.gov/resource/g3803c.la000477a/"
                target="_blank"
              >1856 Catt Co NY Map</b-dropdown-item>
              <b-dropdown-item
                href="http://www.historicmapworks.com/Atlas/US/7147/Chautauqua+County+1867/"
                target="_blank"
              >1867 Atlas Chaut Co NY</b-dropdown-item>
              <b-dropdown-item
                href="http://www.historicmapworks.com/Atlas/US/9707/Chautauqua+County+1881/"
                target="_blank"
              >1881 Atlas Chaut Co NY</b-dropdown-item>
              <b-dropdown-item
                href="https://chautauqua.nygenweb.net/HISTORY/ChautauquaCountyWallMap1916/ChautauquaMap1916.html"
                target="_blank"
              >1916 Chaut Co NY Map</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fmaps%2FNathans%20Sketch%20of%20Towns.png?alt=media&token=066b426a-4ff8-4107-82ac-89f93977dc38"
                target="_blank"
              >Sketch of Towns</b-dropdown-item>
              <b-dropdown-item
                href="https://chqugrr.com/"
                target="_blank"
              >Chaut Co NY UGRR Map</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown text="Greek Revival Posters" right>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalArkwright.pdf?alt=media&token=abef71d4-f772-4cf3-aba6-5323d8d8e282"
                target="_blank"
              >Arkwright</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalBusti.pdf?alt=media&token=33e8bbb1-da78-49dc-b44a-1e6c694b78e7"
                target="_blank"
              >Busti</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalCarroll.pdf?alt=media&token=4e5a7ea5-08d9-49a2-86e7-c20c046bc410"
                target="_blank"
              >Carroll</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalCharlotte.pdf?alt=media&token=9ea2b583-a4f4-43f4-9f43-d236a114bd76"
                target="_blank"
              >Charlotte</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalChautauqua.pdf?alt=media&token=302542a9-70ff-417e-b14a-bd9c519ef785"
                target="_blank"
              >Chautauqua</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalCherryCreek.pdf?alt=media&token=284e5e65-3591-402a-99cb-7dcc315ca0bf"
                target="_blank"
              >Cherry Creek</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalClymer.pdf?alt=media&token=6eb82413-3dc4-4706-afd2-ee6ab370b134"
                target="_blank"
              >Clymer</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalDunkirkCity.pdf?alt=media&token=eaad5c92-20d3-470c-aa48-ac69b44b5e72"
                target="_blank"
              >Dunkirk</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalEllery.pdf?alt=media&token=145f4815-c24b-43e3-91b5-f825a1cb4cf6"
                target="_blank"
              >Ellery</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalEllicott.pdf?alt=media&token=08fc9de9-52c6-4cbd-b979-75ae679d9214"
                target="_blank"
              >Ellicott</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalEllington.pdf?alt=media&token=8a79bcb6-58e5-40d6-8699-4b2f9298854d"
                target="_blank"
              >Ellington</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalFrenchCreek.pdf?alt=media&token=0b6d3696-7452-46ba-871d-35b7f764eb7e"
                target="_blank"
              >French Creek</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalGerry.pdf?alt=media&token=fe6ffd69-37d4-4a9a-aed6-824afe22c9f1"
                target="_blank"
              >Gerry</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalHanover.pdf?alt=media&token=bd008cbb-3470-40ad-a35b-a3f400711120"
                target="_blank"
              >Hanover</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalHarmony.pdf?alt=media&token=e6d9e054-0589-4687-86ec-caab06a4b724"
                target="_blank"
              >Harmony</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalJamestown.pdf?alt=media&token=cf1cd582-182b-4092-bb5f-d4ea039e32df"
                target="_blank"
              >Jamestown</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalKiantone.pdf?alt=media&token=fe412ed8-0bda-45d1-9a44-2ad10e8cbe46"
                target="_blank"
              >Kiantone</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalMina.pdf?alt=media&token=d0a16298-789f-427f-9330-09fda10978b0"
                target="_blank"
              >Mina</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalNorthHarmony.pdf?alt=media&token=895f0883-8f96-40d5-a335-45e445e5cd25"
                target="_blank"
              >North Harmony</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalPoland.pdf?alt=media&token=ec99bfff-922f-41d5-9016-dc3da10ed23e"
                target="_blank"
              >Poland</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalPomfret.pdf?alt=media&token=35c039d2-a236-4407-980c-d48f665d2907"
                target="_blank"
              >Pomfret</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalPortland.pdf?alt=media&token=0ea09c6c-c33d-4158-bf1e-51b190da8738"
                target="_blank"
              >Portland</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalRipley.pdf?alt=media&token=18234e22-bcc0-4530-9068-a57f35fd0883"
                target="_blank"
              >Ripley</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalSheridan.pdf?alt=media&token=bfb41328-cbf7-4dcb-8992-c4b235d79d46"
                target="_blank"
              >Sheridan</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalSherman.pdf?alt=media&token=037a44e2-9e7b-4255-9745-11915e5c9d98"
                target="_blank"
              >Sherman</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalStockton.pdf?alt=media&token=1f16867a-3a81-4192-a4d8-b7520b5de4a3"
                target="_blank"
              >Stockton</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalVillenova.pdf?alt=media&token=ddadfac3-eee2-49fe-902b-f974eafb81e3"
                target="_blank"
              >Villenova</b-dropdown-item>
              <b-dropdown-item
                href="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fhs2%2Fposters%2FGreekRevivalWestfield.pdf?alt=media&token=da554e5c-c491-4081-baf3-2b94f0742f29"
                target="_blank"
              >Westfield</b-dropdown-item>
            </b-nav-item-dropdown>

            <!-- <b-nav-item @click="showAbout()"><i class="fas fa-info-circle"></i> About</b-nav-item> -->
            <b-nav-item @click="showList()"><i class="fas fa-user-friends"></i> People</b-nav-item>
            <b-nav-item href="/gologin">Login</b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div id="filters">
      <div v-if="collapsed">
        <span class="float-right clickable mr-2"><i @click="collapsed = false" class="fas fa-angle-up collapse-button"></i></span>
        <h2 @click="collapsed = false" class="clickable mb-0 text-center"><strong>Filtered Views</strong></h2>
      </div><!-- /v-if=collapsed -->
      <div v-else>
        <span class="float-right clickable mr-2"><i @click="collapsed = true" class="fas fa-angle-down collapse-button"></i></span>
        <h2 class="mb-0 text-center"><strong>Filtered Views</strong></h2>
        <p @click="filter('Greek Revival')" :class="{ 'text-success': filters.includes('Greek Revival') }">Greek Revival</p>
        <p @click="filter('Inn or Hotel')" :class="{ 'text-success': filters.includes('Inn or Hotel') }">Inn or Hotel</p>
        <p @click="filter('Log Cabin')" :class="{ 'text-success': filters.includes('Log Cabin') }">Log Cabin</p>
        <p @click="filter('Rural Church')" :class="{ 'text-success': filters.includes('Rural Church') }">Church</p>
        <p @click="filter('Rural Schoolhouse')" :class="{ 'text-success': filters.includes('Rural Schoolhouse') }">Schoolhouse</p>
        <p @click="filter('all')">All</p>
      </div><!-- /v-else -->
    </div><!-- /#filters -->
    <div id="map"></div><!-- /#map -->
    <div id="map-spinner" v-if="loading">
      <RingLoader
        :color="'white'"
      ></RingLoader>
    </div><!-- /#map-spinner -->
    <DataPointDetails v-if="$store.state.drawer.dataPointDetails.show" />
    <ListDrawer v-if="$store.state.drawer.listDrawer.show" />
    <DatasetDetails v-if="$store.state.drawer.datasetDetails.show" />
    <!-- <ListDrawer v-if="$store.state.drawer.listDrawer.show" /> -->
  </div><!-- /#hs2 -->
</template>

<script>
import RingLoader from 'vue-spinner/src/GridLoader'
import DataPointDetails from './DataPointDetails'
import DatasetDetails from './DatasetDetails'
import ListDrawer from './ListDrawer'
import axios from 'axios'

export default {
  data () {
    return {
      endpoint: 'https://us-central1-the-mapping-service.cloudfunctions.net/api/dataset/OVbugsjVtjAjY1QH7X9R',
      mapboxToken: 'pk.eyJ1IjoidGhlbWFwcGluZ3NlcnZpY2UiLCJhIjoiY2t1aWJ2c3hsMWkwYzJ1cGhyNjRndWhzZiJ9.Dm5XwBzvVMaXNK0BpiQLHA',
      rawGeoJson: {},
      loading: true,
      collapsed: true,
      filters: []
    }
  },
  computed: {
    mapObject: {
      get () {
        return this.$store.state.mapObject
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'mapObject', value: value })
      }
    },
    datasetGeoJson: {
      get () {
        return this.$store.state.datasetGeoJson
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'datasetGeoJson', value: value })
      }
    }
  },
  components: {
    RingLoader,
    DataPointDetails,
    DatasetDetails,
    ListDrawer
  },
  mounted () {
    this.initData()
  },
  methods: {
    filter (value) {
      // filter down datasetGeoJson
      this.datasetGeoJson = {
        type: 'FeatureCollection',
        features: []
      }
      if (value === 'all') {
        this.filters = []
        this.datasetGeoJson.features = this.rawGeoJson.features
      } else if (this.filters.includes(value)) {
        this.filters = this.filters.filter(item => item !== value)
      } else {
        this.filters.push(value)
      }
      if (value !== 'all') {
        this.filters.forEach(filter => {
          this.rawGeoJson.features.forEach((feature, i) => {
            let pushFeature = false
            if (feature.properties['Special Interest Structures']) {
              feature.properties['Special Interest Structures'].forEach((type, i) => {
                if (type === filter) {
                  pushFeature = true
                }
              })
            }
            if (pushFeature) {
              this.datasetGeoJson.features.push(feature)
            }
          })
        })
      }
      this.renderViz()
    },
    showAbout () {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'datasetDetails')
      }, 300)
    },
    showList () {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'listDrawer')
      }, 300)
    },
    clickPoint (e) {
      // HIGHLIGHT THE DATAPOINT
      const features = this.mapObject.queryRenderedFeatures(e.point, { layers: ['unclustered-point'] })
      if (!features.length) { return }
      this.$store.commit('hideAllDrawers')
      if (typeof this.mapObject.getLayer('selectedDataPoint') !== 'undefined') {
        this.mapObject.removeLayer('selectedDataPoint')
        this.mapObject.removeLayer('selectedDataPointHighlight')
        this.mapObject.removeSource('selectedDataPoint')
      }
      const feature = features[0]
      // SOURCE FOR THE SELECTED POINT
      this.mapObject.addSource('selectedDataPoint', {
        type: 'geojson',
        data: feature.toJSON()
      })
      // DOT HIGHLIGHT
      this.mapObject.addLayer({
        id: 'selectedDataPointHighlight',
        type: 'circle',
        source: 'selectedDataPoint',
        paint: {
          'circle-radius': 8,
          'circle-color': 'white'
        }
      })
      // RED DOT
      this.mapObject.addLayer({
        id: 'selectedDataPoint',
        type: 'circle',
        source: 'selectedDataPoint',
        paint: {
          'circle-radius': 6,
          'circle-color': 'red'
        }
      })
      const dataPoint = features[0].properties
      if (this.rawGeoJson.fields) {
        // DYNAMIC
        this.$store.commit('setStateProperty', {
          property: 'dataset',
          value: {
            dynamic: true
          }
        })
        this.$store.commit('setStateProperty', {
          property: 'collector',
          value: {
            fields: this.rawGeoJson.fields
          }
        })
      } else {
        // STATIC
        dataPoint.fields = Object.keys(dataPoint)
      }
      setTimeout(() => {
        this.$store.commit('setStateProperty', { property: 'dataPoint', value: dataPoint })
        this.$store.commit('showDrawer', 'dataPointDetails')
      }, 300)
    },
    clickCluster (e) {
      const self = this
      const features = this.mapObject.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      })
      const clusterId = features[0].properties.cluster_id
      this.mapObject.getSource('hs2DataSource').getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return
          self.mapObject.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          })
        }
      )
    },
    renderViz () {
      const self = this
      // if (this.mapObject.getLayer('clusters')) {
      //   this.mapObject.removeLayer('clusters')
      //   this.mapObject.removeLayer('cluster-count')
      //   this.mapObject.removeLayer('unclustered-point')
      // }

      if (this.mapObject.getSource('hs2DataSource')) {
        this.mapObject.removeLayer('unclustered-point')
        this.mapObject.removeSource('hs2DataSource')
      }

      this.mapObject.addSource('hs2DataSource', {
        type: 'geojson',
        data: this.datasetGeoJson
        // cluster: true,
        // clusterMaxZoom: 14,
        // clusterRadius: 50
      })

      // this.mapObject.addLayer({
      //   id: 'clusters',
      //   type: 'circle',
      //   source: 'hs2DataSource',
      //   filter: ['has', 'point_count'],
      //   paint: {
      //     'circle-color': [
      //       'step',
      //       ['get', 'point_count'],
      //       '#51bbd6',
      //       100,
      //       '#f1f075',
      //       750,
      //       '#f28cb1'
      //     ],
      //     'circle-radius': [
      //       'step',
      //       ['get', 'point_count'],
      //       20,
      //       100,
      //       30,
      //       750,
      //       40
      //     ]
      //   }
      // })

      // this.mapObject.addLayer({
      //   id: 'cluster-count',
      //   type: 'symbol',
      //   source: 'hs2DataSource',
      //   filter: ['has', 'point_count'],
      //   layout: {
      //     'text-field': '{point_count_abbreviated}',
      //     'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      //     'text-size': 12
      //   }
      // })
      console.log(this.$store.state.dataset)

      // const featureCategories = this.$store.state.dataset.vizSettings.featureCategories
      /* eslint-disable */
      const featureCategories = [
        {
          "color": "#00ff00",
          "name": "Busti",
          "count": 399
        },
        {
          "color": "#00ff00",
          "name": "DunkirkCity",
          "count": 299
        },
        {
          "color": "#ffff00",
          "name": "North Harmony",
          "count": 270
        },
        {
          "color": "#ff00ff",
          "name": "Villenova",
          "count": 158
        },
        {
          "color": "#00ff00",
          "name": "Hanover",
          "count": 532
        },
        {
          "color": "#ff00ff",
          "name": "Ellington",
          "count": 221
        },
        {
          "color": "#ffff00",
          "name": "Sheridan",
          "count": 333
        },
        {
          "color": "#ff00ff",
          "name": "FrenchCreek",
          "count": 130
        },
        {
          "color": "#00ff00",
          "name": "Poland",
          "count": 241
        },
        {
          "color": "#ffff00",
          "name": "Jamestown",
          "count": 81
        },
        {
          "color": "#ff00ff",
          "name": "Pomfret",
          "count": 1020
        },
        {
          "color": "#00ff00",
          "name": "Clymer",
          "count": 204
        },
        {
          "color": "#ffff00",
          "name": "Portland",
          "count": 275
        },
        {
          "color": "#ff00ff",
          "name": "Sherman",
          "count": 245
        },
        {
          "color": "#00ff00",
          "name": "Charlotte",
          "count": 229
        },
        {
          "color": "#ffff00",
          "name": "Stockton",
          "count": 362
        },
        {
          "color": "#ff9900",
          "name": "Carroll",
          "count": 210
        },
        {
          "color": "#ffff00",
          "name": "Ripley",
          "count": 393
        },
        {
          "color": "#ff9900",
          "name": "Gerry",
          "count": 121
        },
        {
          "color": "#ff9900",
          "name": "Mina",
          "count": 164
        },
        {
          "color": "#00ff00",
          "name": "Westfield",
          "count": 381
        },
        {
          "color": "#ff9900",
          "name": "Chautauqua",
          "count": 68
        },
        {
          "color": "#ff9900",
          "name": "Harmony",
          "count": 353
        },
        {
          "color": "#00ff00",
          "name": "Dunkirk",
          "count": 9
        },
        {
          "color": "#ffff00",
          "name": "Cherry Creek",
          "count": 191
        },
        {
          "color": "#ff9900",
          "name": "Arkwright",
          "count": 116
        },
        {
          "color": "#ff00ff",
          "name": "Ellery",
          "count": 186
        },
        {
          "color": "#ff00ff",
          "name": "Kiantone",
          "count": 68
        },
        {
          "color": "#ffff00",
          "name": "Ellicott",
          "count": 23
        },
        {
          "color": "#00ff00",
          "name": "undefined",
          "count": 11
        }
      ]

      const color = [
        'match',
        [
          'get',
          'Township'
        ]
        // 'DunkirkCity',
        // '#77CCCC',
        // 'Villenova',
        // '#777711',
        // 'Hanover',
        // '#AA7744',
        // 'Ellington',
        // '#AAAA44',
        // 'FrenchCreek',
        // '#774411',
        // 'Poland',
        // '#ffff00',
        // 'Jamestown',
        // '#AA4455',
        // 'Clymer',
        // '#771155',
        // 'Pomfret',
        // '#DDDD77',
        // 'Portland',
        // '#117744',
        // 'Sherman',
        // '#DDAA77',
        // 'Charlotte',
        // '#CC99BB',
        // 'Busti',
        // '#ffff00',
        // 'Stockton',
        // '#117777',
        // 'Carroll',
        // '#77AADD',
        // 'Gerry',
        // '#88CCAA',
        // 'Mina',
        // '#DD7788',
        // 'Westfield',
        // '#114477',
        // 'Chautauqua',
        // '#AA4488',
        // 'Harmony',
        // '#771122',
        // 'Cherry Creek',
        // '#44AA77',
        // 'Ripley',
        // '#77CCCC',
        // 'Arkwright',
        // '#777711',
        // 'Kiantone',
        // '#AA7744',
        // 'North Harmony',
        // '#AAAA44',
        // 'Sheridan',
        // '#774411',
        // 'Ellery',
        // '#4477AA',
        // 'NorthHarmony',
        // '#AA4455',
        // 'Dunkirk',
        // '#771155',
        // 'Ellicott',
        // '#DDDD77',
        // 'villenova',
        // '#ffff00',
        // '#ccc'
      ]

      featureCategories.forEach(category => {
        color.push(category.name)
        color.push(category.color)
      })
      color.push('#ccc')

      this.mapObject.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'hs2DataSource',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': color,
          'circle-radius': 4
        }
      })

      // this.mapObject.on('click', 'clusters', self.clickCluster)

      this.mapObject.on('click', 'unclustered-point', self.clickPoint)

      // this.mapObject.on('mouseenter', 'clusters', function () {
      //   self.mapObject.getCanvas().style.cursor = 'pointer'
      // })

      // this.mapObject.on('mouseleave', 'clusters', function () {
      //   self.mapObject.getCanvas().style.cursor = ''
      // })

      this.mapObject.on('mouseenter', 'unclustered-point', function () {
        self.mapObject.getCanvas().style.cursor = 'pointer'
      })

      this.mapObject.on('mouseleave', 'unclustered-point', function () {
        self.mapObject.getCanvas().style.cursor = ''
      })

      this.loading = false
    },
    filterData () {
      console.log('Filter')
    },
    initData () {
      let collectorFields = []
      axios.get(this.endpoint).then(response => {
        this.$store.commit('setStateProperty', { property: 'dataset', value: response.data })
        collectorFields = response.data.fields
        return axios.get(response.data.file.geoJsonURL)
      }).then(geoResponse => {
        geoResponse.data.fields = collectorFields

        const filteredDataFeatures = []

        console.log(geoResponse.data)

        const activeTownships = [
          'Arkwright',
          'Busti',
          'Carroll',
          'Chautauqua',
          'Charlotte',
          'Cherry Creek',
          'Clymer',
          'DunkirkCity',
          'Dunkirk City',
          'Dunkirk',
          'Ellery',
          'Ellington',
          'Ellicott',
          'FrenchCreek',
          'Gerry',
          'Hanover',
          'Harmony',
          'Jamestown',
          'Kiantone',
          'Mina',
          'North Harmony',
          'Poland',
          'Pomfret',
          'Portland',
          'Ripley',
          'Sherman',
          'Sheridan',
          'Stockton',
          'Villenova',
          'Westfield'
        ]

        // const timestampMilliseconds = Date.UTC(2022, 6, 1) // JavaScript months are 0-based, so 6 = July
        // const timestampSeconds = timestampMilliseconds / 1000
        // console.log(timestampSeconds)

        geoResponse.data.features.forEach(feature => {
          // if (activeTownships.includes(feature.properties.Township) && feature.properties.updatedOn._seconds > timestampSeconds) {
          if (activeTownships.includes(feature.properties.Township)) {
            filteredDataFeatures.push(feature)
          }
        })

        console.log(filteredDataFeatures)

        geoResponse.data.features = filteredDataFeatures

        this.rawGeoJson = geoResponse.data
        this.datasetGeoJson = geoResponse.data
        this.initMap()
      })
    },
    initMap () {
      mapboxgl.accessToken = this.mapboxToken /* eslint-disable-line */
      this.mapObject = new mapboxgl.Map({ /* eslint-disable-line */
        container: 'map',
        style: 'mapbox://styles/themappingservice/clc6fhump000o14poolttr16q',
        center: [-79.4, 42.2590],
        zoom: 9
      })
      this.mapObject.on('load', () => {
        this.mapObject.addControl(new mapboxgl.NavigationControl()) /* eslint-disable-line */
        this.renderViz()
      })
    }
  }
}
</script>

<style scoped>
/* #hs2Nav {
  position: fixed;
  width: 100%;
  z-index: 10;
} */

#map {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
}

#map-spinner {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
  z-index: 5;
  background-color: rgba(0,0,0,0.5);
}

.v-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

#filters {
  position: absolute;
  bottom: 30px;
  right: 10px;
  z-index: 2;
  background-color: #333;
  padding: 2px 5px;
  border-radius: 4px;
  color: #eee;
  min-width: 180px;
}
#filters p {
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: sans-serif;
  margin: 4px;
  padding: 4px;
  border-radius: 4px;
  background-color: #555;
}
#filters p:hover {
  background-color: #333; color: #eee;
}
#filters h2 {
  font-size: 18px;
  margin: 4px;
  font-family: sans-serif;

}
</style>
