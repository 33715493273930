<template>
  <div id="stage">
    <div id="map"></div><!-- /#map -->

    <div id="yearsLabel" v-if="years">
      <h3>{{ years }}</h3>
    </div>

    <p style="line-height: 1;"><span class="clickable" @click="changeScene('prev')">Prev</span> <span class="clickable mr-5" @click="changeScene('next')">Next</span> <span class="clickable mr-5" @click="play()">PLAY</span> {{ sceneName }}</p>
  </div><!-- /#stage -->
</template>

<script>
import axios from 'axios'
import { setTimeout } from 'timers'
// import scenes from './scenes1.js'
// import scenes from './scenes2.js'
// import scenes from './scenes3.js'
import scenes from './scenes4.js'
// import scenes from './scenes5.js'

export default {
  data () {
    return {
      endpoint: 'https://us-central1-the-mapping-service.cloudfunctions.net/api/geojson/sTyJjtUItOa0awYD1zls',
      mapboxToken: 'pk.eyJ1IjoidGhlbWFwcGluZ3NlcnZpY2UiLCJhIjoiY2t1aWJ2c3hsMWkwYzJ1cGhyNjRndWhzZiJ9.Dm5XwBzvVMaXNK0BpiQLHA',
      rawGeoJson: {},
      scene: 0,
      sceneName: 'None',
      linesGeoJson: false,
      polygonsGeoJson: false,
      years: false
    }
  },
  computed: {
    mapObject: {
      get () {
        return this.$store.state.mapObject
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'mapObject', value: value })
      }
    },
    datasetGeoJson: {
      get () {
        return this.$store.state.datasetGeoJson
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'datasetGeoJson', value: value })
      }
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    fitBounds (bounds) {
      this.mapObject.fitBounds(bounds, {
        padding: 200,
        duration: 4000,
        maxZoom: 14
      })
    },
    play () {
      this.changeScene('next')
      setTimeout(() => {
        this.play()
      }, 30000)
    },
    changeScene (option) {
      if (option === 'next' && this.scene < scenes.length - 1) {
        this.scene++
      } else if (option === 'prev' && this.scene > 0) {
        this.scene--
      }
      this.loadScene()
    },
    loadScene () {
      this.years = false
      if (scenes[this.scene].years) {
        this.years = scenes[this.scene].years
      }

      this.sceneName = scenes[this.scene].name
      const sceneBounds = new mapboxgl.LngLatBounds() /* eslint-disable-line */
      if (scenes[this.scene].type === 'fly') {
        scenes[this.scene].bounds.features[0].geometry.coordinates[0].forEach(coords => {
          sceneBounds.extend(coords)
        })
        this.fitBounds(sceneBounds)
      } else if (scenes[this.scene].type === 'noData') {
        this.datasetGeoJson.features = []
        this.polygonsGeoJson = {
          type: 'FeatureCollection',
          features: []
        }
        this.linesGeoJson = {
          type: 'FeatureCollection',
          features: []
        }
      } else if (scenes[this.scene].type === 'allUGRRData') {
        this.rawGeoJson.features.forEach(feature => {
          if (feature.properties['Operative type'].includes('UGRR')) {
            this.datasetGeoJson.features.push(feature)
          }
          // sceneBounds.extend(feature.geometry.coordinates)
        })
        // this.fitBounds()
      } else if (scenes[this.scene].type === 'allPOCData') {
        this.rawGeoJson.features.forEach(feature => {
          if (feature.properties['Operative type'].includes('Persons of Color')) {
            this.datasetGeoJson.features.push(feature)
          }
          // sceneBounds.extend(feature.geometry.coordinates)
        })
        // this.fitBounds()
      } else if (scenes[this.scene].type === 'allData') {
        this.rawGeoJson.features.forEach(feature => {
          this.datasetGeoJson.features.push(feature)
          // sceneBounds.extend(feature.geometry.coordinates)
        })
        // this.fitBounds()
      } else if (scenes[this.scene].type === 'pointsCollection') {
        this.rawGeoJson.features.forEach(feature => {
          const featureId = feature.properties.dataPointId
          if (scenes[this.scene].dataPoints.includes(featureId)) {
            this.datasetGeoJson.features.push(feature)
            sceneBounds.extend(feature.geometry.coordinates)
          }
        })
        setTimeout(() => {
          this.fitBounds(sceneBounds)
        }, 2000)
      } else if (scenes[this.scene].type === 'flashLights') {
        // this.datasetGeoJson.features = scenes[this.scene].geoJson.features
        scenes[this.scene].geoJson.features.forEach(feature => {
          this.datasetGeoJson.features.push(feature)
          sceneBounds.extend(feature.geometry.coordinates)
        })
        setTimeout(() => {
          this.fitBounds(sceneBounds)
        }, 2000)
      } else if (scenes[this.scene].type === 'polygons') {
        this.polygonsGeoJson = scenes[this.scene].geoJson
        this.polygonsGeoJson.features.forEach(feature => {
          feature.geometry.coordinates[0].forEach(coordinatePair => {
            sceneBounds.extend(coordinatePair)
          })
        })
        setTimeout(() => {
          this.fitBounds(sceneBounds)
        }, 2000)
      } else if (scenes[this.scene].type === 'lines') {
        this.linesGeoJson = scenes[this.scene].geoJson
        this.linesGeoJson.features.forEach(feature => {
          feature.geometry.coordinates.forEach(coordinatePair => {
            sceneBounds.extend(coordinatePair)
          })
        })
        setTimeout(() => {
          this.fitBounds(sceneBounds)
        }, 2000)
      }
      this.renderViz()
    },
    initData () {
      axios.get(this.endpoint).then(response => {
        this.rawGeoJson = JSON.parse(JSON.stringify(response.data))
        this.datasetGeoJson = JSON.parse(JSON.stringify(response.data))
        console.log(response.data)
        this.initMap()
      })
    },
    initMap () {
      mapboxgl.accessToken = this.mapboxToken /* eslint-disable-line */
      this.mapObject = new mapboxgl.Map({ /* eslint-disable-line */
        container: 'map',
        style: 'mapbox://styles/themappingservice/ckuibhxr005nf18pk5yl9dnay',
        center: [-79.2746, 42.2590],
        zoom: 8,
        projection: 'globe'
      })
      this.mapObject.on('load', () => {
        // this.mapObject.addControl(new mapboxgl.NavigationControl()) /* eslint-disable-line */
        this.mapObject.setFog({
          color: 'rgb(186, 210, 235)', // Lower atmosphere
          'high-color': 'rgb(36, 92, 223)', // Upper atmosphere
          'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
          'space-color': 'rgb(11, 11, 25)', // Background color
          'star-intensity': 0.6 // Background star brightness (default 0.35 at low zoooms )
        })
        this.renderViz()
      })
    },
    renderViz () {
      if (this.mapObject.getLayer('points')) {
        this.mapObject.removeLayer('points')
      }
      if (this.mapObject.getLayer('polygons')) {
        this.mapObject.removeLayer('polygons')
      }
      if (this.mapObject.getLayer('lines')) {
        this.mapObject.removeLayer('lines')
      }

      if (this.mapObject.getSource('ugrrDataSource')) {
        this.mapObject.removeSource('ugrrDataSource')
      }
      if (this.mapObject.getSource('polygonsSource')) {
        this.mapObject.removeSource('polygonsSource')
      }
      if (this.mapObject.getSource('linesSource')) {
        this.mapObject.removeSource('linesSource')
      }

      this.mapObject.addSource('polygonsSource', {
        type: 'geojson',
        data: this.polygonsGeoJson
      })
      this.mapObject.addLayer({
        id: 'polygons',
        type: 'fill',
        source: 'polygonsSource',
        paint: {
          'fill-color': 'yellow',
          'fill-opacity': 0.5,
          'fill-outline-color': 'black'
        }
      })

      this.mapObject.addSource('linesSource', {
        type: 'geojson',
        data: this.linesGeoJson
      })
      this.mapObject.addLayer({
        id: 'lines',
        type: 'line',
        source: 'linesSource',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': 'yellow',
          'line-width': 10
        }
      })

      const size = 100

      const map = this.mapObject

      // This implements `StyleImageInterface`
      // to draw a pulsing dot icon on the map.
      const pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.width
          canvas.height = this.height
          this.context = canvas.getContext('2d')
        },

        // Call once before every frame where the icon will be used.
        render: function () {
          const duration = 2000
          const t = (performance.now() % duration) / duration

          const radius = (size / 2) * 0.3
          const outerRadius = (size / 2) * 0.7 * t + radius
          const context = this.context

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height)
          context.beginPath()
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          )
          context.fillStyle = `rgba(255, 252, 127, ${1 - t})`
          context.fill()

          // Draw the inner circle.
          context.beginPath()
          context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
          )
          context.fillStyle = 'rgba(255, 240, 0, 1)'
          context.strokeStyle = 'white'
          context.lineWidth = 2 + 4 * (1 - t)
          context.fill()
          context.stroke()

          // Update this image's data with data from the canvas.
          this.data = context.getImageData(
            0,
            0,
            this.width,
            this.height
          ).data

          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          map.triggerRepaint()

          // Return `true` to let the map know that the image was updated.
          return true
        }
      }
      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 })

      this.mapObject.addSource('ugrrDataSource', {
        type: 'geojson',
        data: this.datasetGeoJson
      })

      this.mapObject.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'ugrrDataSource',
        layout: {
          'icon-image': 'pulsing-dot',
          'icon-allow-overlap': true
        }
      })

      // this.mapObject.addLayer({
      //   id: 'points',
      //   type: 'circle',
      //   source: 'ugrrDataSource',
      //   paint: {
      //     'circle-color': 'yellow',
      //     'circle-radius': 12,
      //     'circle-stroke-width': 2,
      //     'circle-stroke-color': 'black'
      //   }
      // })
    }
  }
}
</script>

<style scoped>
#map {
  position: fixed;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#yearsLabel {
  position: fixed;
  z-index: 1;
  bottom: 40px;
  width: 100%;
  background-color: rgba(255,255,255,0.8);
  padding: 10px 20px 5px 20px;
  text-align: center;
}
</style>
