<template>
  <div id="addField">
    <div class="o-modal">
      <div class="o-modal-content">
        <div class="o-modal-header">
          <a
            @click="closeAddField()"
            class="close clickable"
          ><i class="far fa-times"></i></a>
          <p class="lead">Add a Field</p>
        </div><!-- /.o-modal-header -->
        <div class="o-modal-body">
          <form @submit.prevent>

            <!-- Label -->
            <div class="form-group">
              <label>Field Label</label>
              <input
                v-model="field.label"
                class="form-control"
              />
            </div><!-- /.form-group -->

            <!-- Select Field Type -->
            <div class="form-group" v-if="!editMode">
              <label>Field Type</label>
              <multiselect
                v-model="field.type"
                :options="fieldTypes"
                :allow-empty="false"
                :multiple="false"
              />
            </div><!-- /.form-group -->

            <!-- Select Options -->
            <div
              v-if="field.type === 'Select' || field.type === 'Multiselect'"
              class="form-group"
            >
              <label>Field Options</label>
              <multiselect
                v-model="field.options"
                :options="field.options"
                tag-placeholder="Add options here"
                placeholder="Add options here"
                :allow-empty="false"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              />
            </div><!-- /.form-group -->

            <!-- Minimum Number -->
            <div
              v-if="field.type === 'Number'"
              class="form-group"
            >
              <label>Min</label>
              <input
                v-model="field.min"
                class="form-control"
                type="number"
              />
            </div><!-- /.form-group -->

            <!-- Maximum Number -->
            <div
              v-if="field.type === 'Number'"
              class="form-group"
            >
              <label>Max</label>
              <input
                v-model="field.max"
                class="form-control"
                type="number"
              />
            </div><!-- /.form-group -->

            <!-- Step Setting -->
            <div
              v-if="field.type === 'Number'"
              class="form-group"
            >
              <label>Step</label>
              <input
                v-model="field.step"
                class="form-control"
                type="number"
              />
            </div><!-- /.form-group -->

            <!-- Suffix -->
            <div
              v-if="field.type === 'Number'"
              class="form-group"
            >
              <label>Suffix</label>
              <input
                v-model="field.suffix"
                class="form-control"
              />
            </div><!-- /.form-group -->

            <!-- Required Option -->
            <b-form-checkbox
              v-model="field.required"
              class="mt-2 mb-2"
            >
              Require Field
            </b-form-checkbox>

          </form>
        </div><!-- /.o-modal-body -->
        <div class="o-modal-footer">
          <div class="float-right">
            <button
              v-if="!editMode"
              @click="addField()"
              class="btn btn-dark"
            >
              Add Field
            </button>
            <button
              v-if="editMode"
              @click="addField()"
              class="btn btn-dark ml-1"
            >
              Update Field
            </button>
            <button
              v-if="editMode"
              @click="confirmRemove = true"
              class="btn btn-danger ml-1"
            >
              Remove Field
            </button>
          </div><!-- /.float-right -->
        </div><!-- /.o-modal-footer -->
      </div><!-- /.o-modal-content -->
    </div><!-- /. o-modal -->
    <Confirm
      v-if="confirmRemove"
      text="DANGER! Are you sure you want to remove this field? THIS CANNOT BE UNDONE!!"
      v-on:confirmed="removeField($event)"
    />
  </div><!-- /#addField -->
</template>

<script>
import Confirm from '../Confirm'
import Multiselect from 'vue-multiselect'

export default {
  data () {
    return {
      confirmRemove: false,
      showRadio: true,
      fieldTypes: [
        'Text',
        'Formatted Text',
        'Number',
        'Select',
        'Multiselect',
        'Image'
      ],
      field: {
        label: '',
        type: 'Text',
        options: [],
        min: false,
        max: false,
        step: false,
        suffix: '',
        required: true
      }
    }
  },
  components: {
    Multiselect,
    Confirm
  },
  props: [
    'editMode',
    'fieldIndex'
  ],
  computed: {
    collector: {
      get () {
        return this.$store.state.collector
      },
      set (value) {
        this.$store.commit('setCollector', value)
      }
    }
  },
  created () {
    if (this.editMode) {
      this.field = this.collector.fields[this.fieldIndex]
    }
    this.collector.fields.forEach(field => {
      if (field.type === 'Image') {
        this.fieldTypes.forEach((item, i) => {
          if (item === 'Image') {
            this.fieldTypes.splice(i, 1)
          }
        })
      }
    })
  },
  methods: {
    removeField (confirmed) {
      if (confirmed) {
        this.confirmRemove = false
        this.collector.fields.splice(this.fieldIndex, 1)
        this.$store.commit('toggleModal', 'addField')
      } else {
        this.confirmRemove = false
        return false
      }
    },
    addField () {
      // Validate that number fields with min and max also have step set
      if (this.field.type === 'Number' && (this.field.min || this.field.max) && !this.field.step) {
        // alert('You must enter min, max, and step. You may also leave all three of these settings blank.')
        this.$store.commit('setAlert', { active: true, message: 'You must enter min, max, and step. You may also leave all three of these settings blank.', level: 'alert-dark', timeout: 3000 })
        return false
      }
      if (this.editMode) {
        const fieldObj = {
          label: this.field.label,
          type: this.field.type,
          machine_name: this.field.machine_name,
          min: this.field.min,
          max: this.field.max,
          step: this.field.step,
          suffix: this.field.suffix,
          required: this.field.required
        }
        if (this.field.type === 'Select' || this.field.type === 'Multiselect') {
          fieldObj.options = this.field.options
        }
        this.collector.fields[this.fieldIndex] = fieldObj
        this.$store.commit('toggleModal', 'addField')
      } else {
        if (!this.field.label) {
          // alert('You must enter a label to create this field!')
          this.$store.commit('setAlert', { active: true, message: 'You must enter a label to create this field!', level: 'alert-dark', timeout: 3000 })
          return false
        } else if (this.field.label === 'ID' || this.field.label === 'id' || this.field.label === 'iD' || this.field.label === 'Id') {
          this.$store.commit('setAlert', { active: true, message: 'The label "ID" is reserved for the system. Please change the label on this field. (example: "ID Code")', level: 'alert-dark', timeout: 6000 })
          return false
        } else {
          const snakeCase = string => {
            string = string.replace(/\W+/g, ' ').toLowerCase().split(' ').join('_')
            if (string.charAt(string.length - 1) === '_') {
              return string.substring(0, string.length - 1)
            }
            return string
          }
          let machineName = snakeCase(this.field.label)
          const originalMachineName = machineName
          // Check for other machine names of the same
          let suffix = 1
          const self = this
          function checkUniqueName () { // eslint-disable-line
            self.collector.fields.forEach((item, i) => {
              if (item.machine_name === machineName) {
                machineName = originalMachineName + '_' + suffix
                suffix++
                checkUniqueName()
              }
            })
          }
          checkUniqueName()

          const fieldObj = {
            label: this.field.label,
            type: this.field.type,
            machine_name: machineName,
            min: this.field.min,
            max: this.field.max,
            step: this.field.step,
            suffix: this.field.suffix,
            required: this.field.required
          }
          if (this.field.type === 'Select' || this.field.type === 'Multiselect') {
            fieldObj.options = this.field.options
          }
          this.collector.fields.push(fieldObj)
          this.$store.commit('toggleModal', 'addField')
        }
      }
    },
    addTag (newTag) {
      this.field.options.push(newTag)
    },
    closeAddField () {
      this.$store.commit('toggleModal', 'addField')
    }
  }
}
</script>
