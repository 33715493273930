<template>
  <div id="datasets" :class="draggingClass">
    <!-- Datasets drawer -->
    <b-card
      id="datasetsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.datasets.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'datasets')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">Datasets</h4>
        <b-btn
          v-if="$store.state.role == 'Administrator' && $store.state.folder.id != 'trash'"
          @click="createDataset()"
          class="mb-2"
          variant="dark"
        >
          <i class="fas fa-file-upload"></i> Upload Data
        </b-btn>
      </template>

      <b-form class="d-flex w-100 justify-content-between" inline>
        <!-- Sort Option -->
        <b-form-select
          v-model="sortBy"
          :options="sortOptions"
          size="sm"
          class="mb-3 mr-2"
          style="width:150px;"
          @change="sortDatasets"
        ></b-form-select>
        <b-btn
          class="mr-2"
          size="sm"
          style="margin-top:-20px;"
          @click="$store.commit('setStateProperty', { property: 'createFolderPrompt', value: true })"
          variant="dark"
        ><i class="far fa-folder-plus"></i> Create Folder</b-btn>
      </b-form>
      <div
        class="mb-2"
      >
        <span
          v-for="(crumb, i) in breadcrumbs"
          v-bind:key="i"
        >
          <b-badge
            variant="dark"
            @click="launchFolder(crumb, true)"
            class="clickable"
          >{{ crumb.name }}</b-badge><span v-if="(i + 1) !== breadcrumbs.length"> / </span>
        </span>
      </div>
      <!-- FOLDERS -->
      <div class="list-group mb-2 folders">
        <div
          v-for="(folder, i) in $store.state.folders"
          v-bind:key="i"
          class="list-container"
        >
          <a
            @click="launchFolder(folder)"
            class="list-group-item list-group-item-action clickable mb-2 folder"
          >
            <p class="lead mb-1"><i class="fas fa-folder"></i> {{ folder.name }}</p>
            <p class="mb-1">{{ folder.description }}</p>
            <draggable
              v-bind="{ group: { put: 'files' } }"
              :id="folder.id"
            ></draggable>
          </a>
          <b-dropdown class="options" size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i style="color:#333;" class="fas fa-caret-down clickable"></i>
            </template>
            <b-dropdown-item @click="showMoveMenu('folder', folder)"><i class="fas fa-arrow-alt-to-right"></i> Move</b-dropdown-item>
            <b-dropdown-item @click="editFolder(folder)"><i class="fas fa-edit"></i> Edit</b-dropdown-item>
            <b-dropdown-item v-if="$store.state.folder.id !== 'trash'" @click="initTrashFolder(folder)"><span class="text-danger"><i class="fas fa-trash"></i> Trash</span></b-dropdown-item>
            <b-dropdown-item v-if="$store.state.folder.id === 'trash'" @click="restoreFolder(folder)"><span class="text-success"><i class="fas fa-trash-restore"></i> Restore</span></b-dropdown-item>
            <b-dropdown-item v-if="$store.state.folder.id === 'trash'" @click="initDeleteItem(folder, 'folder')"><span class="text-danger"><i class="fas fa-bomb"></i> Destroy Permanently</span></b-dropdown-item>
          </b-dropdown>
        </div>
      </div><!-- /.list-group -->
      <!-- DATASETS -->
      <div class="list-group sets">
        <draggable
          v-bind="{ group: { name:'files' } }"
          handle=".handle"
          @end="changeOrder($event)"
          @start="draggingClass = 'dragging'"
          :disabled="draggableDisabled"
        >
          <div
            v-for="(dataset, i) in $store.state.datasets"
            v-bind:key="i"
            class="list-container"
          >
            <a
              @click="launchDataset(dataset)"
              class="list-group-item list-group-item-action clickable handle mb-2"
            >
              <p class="lead mb-1"><i v-if="dataset.dynamic" class="fas fa-satellite-dish" v-b-tooltip.hover.top="'Dynamic Dataset'"></i><i v-if="dataset.file.raster" class="fas fa-border-none" v-b-tooltip.hover.top="'Raster Dataset'"></i> {{ dataset.name }}</p>
              <!-- <p class="mb-1">{{ dataset.description }}</p> -->
              <small>Updated: {{ dataset.updatedOn | formatDate }}</small>
            </a>
            <b-dropdown class="options" size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i style="color:#333;" class="fas fa-caret-down clickable"></i>
              </template>
              <b-dropdown-item @click="showMoveMenu('dataset', dataset)"><i class="fas fa-arrow-alt-to-right"></i> Move</b-dropdown-item>
              <b-dropdown-item v-if="$store.state.folder.id !== 'trash' && !dataset.dynamic" @click="initTrashDataset(dataset)"><span class="text-danger"><i class="fas fa-trash"></i> Trash</span></b-dropdown-item>
              <b-dropdown-item v-if="$store.state.folder.id !== 'trash' && dataset.dynamic" @click="initTrashDynamicDataset(dataset)"><span class="text-danger"><i class="fas fa-trash"></i> Trash</span></b-dropdown-item>
              <b-dropdown-item v-if="$store.state.folder.id === 'trash'" @click="restoreDataset(dataset)"><span class="text-success"><i class="fas fa-trash-restore"></i> Restore</span></b-dropdown-item>
              <b-dropdown-item v-if="$store.state.folder.id === 'trash'" @click="initDeleteItem(dataset, 'dataset')"><span class="text-danger"><i class="fas fa-bomb"></i> Destroy Permanently</span></b-dropdown-item>
            </b-dropdown>
          </div>
        </draggable>
      </div><!-- /.list-group -->
      <button
        class="btn btn-light mt-3 clickable w-100"
        @click="loadMoreDatasets()"
        v-if="$store.state.datasets.length > 9"
      >+ Load more</button><!-- /.btn -->
      <p
        class="lead"
        v-if="$store.state.datasets.length == 0 && $store.state.folders.length == 0"
      >No datasets yet!</p>
      <!-- TRASH -->
      <div class="list-group mt-5" v-if="$store.state.folder.id === 'root'">
        <div
          class="list-container text-danger"
        >
          <a
            @click="launchFolder({ id: 'trash', name: 'Trash' })"
            class="list-group-item list-group-item-action clickable mb-2 text-danger"
          >
            <p class="lead mb-1"><i class="fas fa-trash"></i> Trash</p>
          </a>
        </div>
      </div><!-- /.list-group -->
      <CreateFolder v-if="$store.state.createFolderPrompt" :selectedFolder='selectedFolder' />
      <MoveMenu :moveType="moveType" :moveItem="moveItem" v-if="$store.state.moveMenuOpen" />
      <Confirm
        v-if="confirmTrashFolder"
        text="Are you sure you want to trash this item? You can restore items from the trash."
        v-on:confirmed="trashFolder($event)"
      />
      <Confirm
        v-if="confirmTrashDataset"
        text="Are you sure you want to trash this dataset? You can restore datasets from the trash."
        v-on:confirmed="trashDataset($event)"
      />
      <Confirm
        v-if="confirmTrashDynamicDataset"
        text="Are you sure you want to trash this dynamic dataset? This will also deactivate this collector. You can restore items from the trash."
        v-on:confirmed="trashDataset($event)"
      />
      <Confirm
        v-if="confirmDeleteItem"
        text="DANGER!!! Are you sure you want to delete this item? This cannot be undone!"
        v-on:confirmed="deleteItem($event)"
      />
    </b-card>
  </div><!-- /#datasets -->
</template>

<script>
import Confirm from '../Confirm'
import CreateFolder from './CreateFolder'
import MoveMenu from './MoveMenu'
import moment from 'moment'
import draggable from 'vuedraggable'
import firebase from 'firebase'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      draggableDisabled: false,
      selectedFolder: '',
      itemToDelete: {},
      draggingClass: '',
      datasetToTrash: {},
      folderToTrash: {},
      confirmTrashDynamicDataset: false,
      confirmTrashDataset: false,
      confirmTrashFolder: false,
      confirmDeleteItem: false,
      moveType: '',
      moveItem: '',
      reorder: false,
      sortBy: 'Most Recent',
      sortOptions: [
        'Most Recent',
        'Oldest',
        'Name A-Z',
        'Name Z-A'
      ]
    }
  },
  components: {
    CreateFolder,
    MoveMenu,
    Confirm,
    draggable
  },
  computed: {
    mapObject: {
      get () {
        return this.$store.state.mapObject
      },
      set (value) {
        this.$store.commit('setMapObject', value)
      }
    },
    dataset: {
      get () {
        return this.$store.state.dataset
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'dataset', value: value })
      }
    },
    breadcrumbs: {
      get () {
        return this.$store.state.breadcrumbs
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'breadcrumbs', value: value })
      }
    }
  },
  created () {
    // DISABLE DRAGGABLE ON MOBILE
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    if (vw < 800) {
      this.draggableDisabled = true
    }
  },
  methods: {
    initDeleteItem (item, type) {
      this.itemToDelete = item
      this.itemToDelete.type = type
      this.confirmDeleteItem = true
    },
    deleteFolder (folderId) {
      const self = this
      // Delete folder
      fb.foldersCollection.doc(folderId).delete()
      // Delete all datasets in that folder
      fb.datasetsCollection.where('folderId', '==', folderId).get().then(docs => {
        docs.forEach(doc => {
          const datasetDoc = doc.data()
          datasetDoc.id = doc.id
          self.deleteDataset(datasetDoc)
        })
      })
      // Run this function again for each folder in that folder
      fb.foldersCollection.where('folderId', '==', folderId).get().then(docs => {
        docs.forEach(doc => {
          self.deleteFolder(doc.id)
        })
      })
    },
    deleteDataset (datasetItem) {
      const self = this
      if (datasetItem.file.path) {
        // Delete the files
        const mediaPath = datasetItem.file.path
        const fileRef = firebase.storage().ref(mediaPath)
        fileRef.delete()

        if (datasetItem.file.geoJsonPath) {
          const geoJsonMediaPath = datasetItem.file.geoJsonPath
          const geoJsonFileRef = firebase.storage().ref(geoJsonMediaPath)
          geoJsonFileRef.delete()
        }

        if (datasetItem.file.jpgPath) {
          // JPG File
          const jpgMediaPath = datasetItem.file.jpgPath
          const jpgFileRef = firebase.storage().ref(jpgMediaPath)
          jpgFileRef.delete()
        }
        // Delete the dataset document
        fb.datasetsCollection.doc(datasetItem.id).delete().then(() => {
          self.$store.commit('setAlert', { active: true, message: 'Dataset successfully deleted.', level: 'alert-dark', timeout: 3000 })
        })
      } else if (datasetItem.dynamic) {
        // Delete the file
        const geoJsonMediaPath = datasetItem.file.geoJsonPath
        const geoJsonFileRef = firebase.storage().ref(geoJsonMediaPath)
        geoJsonFileRef.delete()
        // Delete the data collector
        fb.collectorsCollection.doc(datasetItem.collectorId).delete().catch(err => { console.log(err) })
        // Delete the dataset doc
        fb.datasetsCollection.doc(datasetItem.id).delete().then(() => {
          self.$store.commit('setAlert', { active: true, message: 'Dataset successfully deleted.', level: 'alert-dark', timeout: 3000 })
        }).catch(err => { console.log(err) })
      } else {
        // Delete the dataset document
        fb.datasetsCollection.doc(datasetItem.id).delete().then(e => {
          self.$store.commit('setAlert', { active: true, message: 'Dataset successfully deleted.', level: 'alert-dark', timeout: 3000 })
        })
      }
    },
    deleteItem (confirmed) {
      this.confirmDeleteItem = false
      if (confirmed) {
        if (this.itemToDelete.type === 'dataset') {
          this.deleteDataset(this.itemToDelete)
        } else if (this.itemToDelete.type === 'folder') {
          this.deleteFolder(this.itemToDelete.id)
        } else {
          return false
        }
      }
    },
    initTrashDynamicDataset (dataset) {
      this.datasetToTrash = dataset
      this.confirmTrashDynamicDataset = true
    },
    initTrashDataset (dataset) {
      this.datasetToTrash = dataset
      this.confirmTrashDataset = true
    },
    initTrashFolder (folder) {
      this.folderToTrash = folder
      this.confirmTrashFolder = true
    },
    restoreFolder (folder) {
      fb.foldersCollection.doc(folder.id).update({
        folderId: 'root',
        updatedOn: new Date(),
        updatedBy: this.$store.state.currentUser.uid
      }).then(() => {
        this.$store.commit('setAlert', { active: true, message: 'Successfully restored!', level: 'alert-dark', timeout: 3000 })
      }).catch(err => {
        console.log(err.message)
      })
    },
    restoreDataset (dataset) {
      fb.datasetsCollection.doc(dataset.id).update({
        folderId: 'root',
        updatedOn: new Date(),
        updatedBy: this.$store.state.currentUser.uid
      }).then(() => {
        this.$store.commit('setAlert', { active: true, message: 'Successfully restored!', level: 'alert-dark', timeout: 3000 })
      }).catch(err => {
        console.log(err.message)
      })
      if (dataset.dynamic) {
        fb.collectorsCollection.doc(dataset.collectorId).update({
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid,
          trashed: false
        })
      }
    },
    trashDataset (confirmed) {
      this.confirmTrashDataset = false
      this.confirmTrashDynamicDataset = false
      if (confirmed) {
        fb.datasetsCollection.doc(this.datasetToTrash.id).update({
          folderId: 'trash',
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid
        }).then(() => {
          this.$store.commit('setAlert', { active: true, message: 'Successfully trashed!', level: 'alert-dark', timeout: 3000 })
        }).catch(err => {
          console.log(err.message)
        })
        if (this.datasetToTrash.dynamic) {
          fb.collectorsCollection.doc(this.datasetToTrash.collectorId).update({
            updatedOn: new Date(),
            updatedBy: this.$store.state.currentUser.uid,
            trashed: true
          })
        }
      } else {
        return false
      }
    },
    trashFolder (confirmed) {
      this.confirmTrashFolder = false
      if (confirmed) {
        fb.foldersCollection.doc(this.folderToTrash.id).update({
          folderId: 'trash',
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid
        }).then(() => {
          this.$store.commit('setAlert', { active: true, message: 'Successfully trashed!', level: 'alert-dark', timeout: 3000 })
        }).catch(err => {
          console.log(err.message)
        })
      } else {
        return false
      }
    },
    editFolder (folder) {
      this.selectedFolder = folder
      this.$store.commit('setStateProperty', { property: 'editMode', value: true })
      this.$store.commit('setStateProperty', { property: 'createFolderPrompt', value: true })
    },
    showMoveMenu (type, item) {
      this.moveType = type
      this.moveItem = item
      this.$store.commit('setStateProperty', { property: 'moveMenuOpen', value: true })
    },
    changeOrder (e) {
      this.draggingClass = ''
      const oldIndex = e.oldIndex
      const datasetId = this.$store.state.datasets[oldIndex].id
      const folderId = e.to.id
      if (folderId) {
        fb.datasetsCollection.doc(datasetId).update({
          folderId: folderId,
          updatedOn: new Date(),
          updatedBy: this.$store.state.currentUser.uid
        }).then(() => {
          this.launchFolder(this.$store.state.folder, true)
        })
      }
    },
    launchFolder (folder, fromCrumb) {
      if (this.breadcrumbs.length < 1) {
        this.breadcrumbs = [{ name: 'Root', id: 'root' }]
      }
      if (fromCrumb) {
        const newBreadcrumbs = []
        this.breadcrumbs.some(crumb => {
          if (crumb.id !== folder.id) {
            newBreadcrumbs.push(crumb)
          } else {
            newBreadcrumbs.push(crumb)
            return true
          }
        })
        this.breadcrumbs = newBreadcrumbs
      } else {
        this.breadcrumbs.push(folder)
      }
      this.$store.commit('setStateProperty', { property: 'folder', value: folder })
      this.$store.dispatch('fetchDatasets', this.$store.state.atlas.id).then(() => {
        this.$store.dispatch('fetchFolders', this.$store.state.atlas.id)
      })
    },
    loadMoreDatasets () {
      this.$store.commit('setStateProperty', { property: 'paginate', value: true })
      this.$store.dispatch('fetchDatasets', this.$store.state.atlas.id)
    },
    launchDataset (dataset) {
      this.$store.dispatch('removeAllLayers')
      if (dataset.dynamic) {
        let newData = true
        fb.datasetsCollection.doc(dataset.id).onSnapshot(snapshot => {
          const datasetDoc = snapshot.data()
          datasetDoc.id = snapshot.id
          const filePath = dataset.file.geoJsonPath || ''
          const newFilePath = datasetDoc.file.geoJsonPath
          // Only rerender if new geoJson file
          if (filePath !== newFilePath && newData === true) {
            newData = false
            this.$store.commit('setDataset', datasetDoc)
            const self = this
            setTimeout(function () {
              self.$store.commit('setAlert', { active: false })
              self.$store.dispatch('removeAllLayers')
              self.$store.dispatch('mapDataset', datasetDoc)
            }, 1000)
          }
        })
      }
      this.$store.dispatch('mapDataset', dataset)
    },
    sortDatasets () {
      this.$store.commit('setStateProperty', { property: 'datasets', value: [] })
      this.$store.commit('setSortBy', this.sortBy)
      this.$store.dispatch('fetchDatasets', this.$store.state.atlas.id)
    },
    createDataset () {
      this.$store.commit('setEditMode', false)
      this.$store.commit('hideDrawer', 'datasets')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'createDataset')
      }, 300)
    }
  },
  filters: {
    formatDate (val) {
      if (!val) { return '-' }
      const date = val.toDate()
      return moment(date).format('MM/DD/YY')
    }
  }
}
</script>

<style scoped>
.dragging .folder {
  box-shadow: 1px 3px 8px rgba(5,104,57,0.8);
}
.dragging .sets {
  opacity: 0.4;
}

.folder {
  background-color: #eee;
}
.list-container {
  position: relative;
}
.options {
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: 9;
}

#datasetsCard {
  overflow-y: scroll;
}

.badge.clickable:hover {
  box-shadow: 1px 2px 4px rgba(0,0,0,0.5);
}
</style>
