<template>
  <div id="termsModal">
    <!-- Terms and Conditions Modal -->
    <div
      class="o-modal"
    >
      <div class="o-modal-content">
        <div class="o-modal-header">
          <p class="lead">Terms</p>
        </div><!-- /.o-modal-header -->
        <div class="o-modal-body">
          <p><strong>Updated: {{ updated }}</strong></p>
          <!-- AGREEMENT -->
          <p>The Mapping Service is a beta application and is being actively developed. Please acknowledge the following before using The Mapping Service.</p>
          <h2>Data Accuracy</h2>
          <p>The methods and processes used within The Mapping Service are experimental. We do not guarantee the accuracy of predictions or visualizations of data within The Mapping Service. Please use independent judgement when assessing the usefulness of maps and data processed in The Mapping Service.</p>
          <h2>Data Privacy</h2>
          <p>Data privacy is important to us. However, while unlikely, please understand that data could be viewed or stolen while using this platform. We are not responsible for stolen or publicly accessed data within The Mapping Service.</p>
          <h2>Data Redundancy</h2>
          <p>Data redundancy is important to us. However, while unlikely, please understand that data can be lost, altered, or deleted while using this platform. We are not responsible for any lost, altered, or deleted data within The Mapping Service. Be sure to back up your data.</p>
          <h2>Platform pricing</h2>
          <p>The Mapping Platform is currently free to use. However, we may decide to charge usage fees in the future to access The Mapping Platform. We retain the right to limit access to The Mapping Platform accounts for this or any other reason.</p>
          <!-- END AGREEMENT -->
        </div><!-- /.o-modal-body -->
        <div class="o-modal-footer">
          <div class="float-right">
            <button
              @click="agreeToTerms()"
              class="btn btn-dark"
            >I Agree To These Terms</button>
          </div><!-- /.float-right -->
        </div><!-- /.o-modal-footer -->
      </div><!-- /.o-modal-content -->
    </div><!-- /.o-modal -->
  </div><!-- /#termsModal -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  props: [
    'updated',
    'tosId'
  ],
  methods: {
    agreeToTerms () {
      fb.usersCollection.doc(this.$store.state.currentUser.uid).update({
        tosId: this.tosId
      }).then(e => {
        this.$emit('agreeToTerms')
      })
    }
  }
}
</script>
