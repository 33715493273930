<template>
  <div id="login" class="p-2">
    <div class="login-form mt-5 p-4">
      <h1>The Mapping Service</h1>
      <div :class="{ 'signup-form': !showLoginForm && !showForgotPassword }">

        <!-- Login Form -->
        <form
          v-if="showLoginForm"
          @submit.prevent
        >
          <div class="form-group">
            <label for="email1">Email</label>
            <input
              v-model.trim="loginForm.email"
              type="text"
              placeholder="lordvader@empire.gov"
              id="email1"
              class="form-control"
            />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="password1">Password</label>
            <input
              v-model.trim="loginForm.password"
              type="password"
              placeholder="******"
              id="password1"
              class="form-control"
            />
          </div><!-- /.form-group -->
          <button
            @click="login()"
            class="btn btn-dark w-100 mb-2"
          ><i
            class="fas fa-sync fa-spin"
            v-if="performingRequest"
          ></i> Log In</button>
          <div class="extras">
            <button
              class="btn btn-sm btn-light mr-1"
              @click="toggleForm()"
            >Create an Account</button>
            <button
              class="btn btn-sm btn-light mr-1"
              @click="togglePasswordReset()"
            >Forgot Password</button>
          </div><!-- /.extras -->
        </form>

        <!-- Signup Form -->
        <form
          v-if="!showLoginForm && !showForgotPassword"
          @submit.prevent
        >
          <h2>Create an Account</h2>
          <div class="form-group">
            <label for="email2">Email</label>
            <input
              v-model.trim="signupForm.email"
              type="text"
              placeholder="you@email.com"
              id="email2"
              class="form-control"
            />
          </div><!-- /.form-group -->
          <div class="form-group">
            <label for="password2">Password</label>
            <input
              v-model.trim="signupForm.password"
              type="password"
              placeholder="min 6 characters"
              id="password2"
              class="form-control"
            />
          </div><!-- /.form-group -->
          <button
            @click="signup()"
            class="btn btn-dark w-100 mb-2"
          ><i
            class="fas fa-sync fa-spin"
            v-if="performingRequest"
          ></i> Sign Up</button>
          <div class="extras">
            <button
              class="btn btn-sm btn-light"
              @click="toggleForm()"
            >Back to Log In</button>
          </div><!-- /.extras -->
        </form>

        <!-- Forgot Password -->
        <form
          v-if="showForgotPassword"
          @submit.prevent class="password-reset"
        >
          <div
            v-if="!passwordResetSuccess"
          >
            <h2>Reset Password</h2>
            <p>We will send you an email to reset your password</p>
            <div class="form-group">
              <label for="email3">Email</label>
              <input
                v-model.trim="passwordForm.email"
                type="text"
                placeholder="you@email.com"
                id="email3"
                class="form-control"
              />
            </div><!-- /.form-control -->
            <button
              @click="resetPassword()"
              class="btn btn-dark w-100 mb-2"
            ><i
              class="fas fa-sync fa-spin"
              v-if="performingRequest"
            ></i> Submit</button>
            <div class="extras">
              <button
                @click="togglePasswordReset()"
                class="btn btn-sm btn-light"
              >Back to Log In</button>
            </div><!-- /.extras -->
          </div><!-- /v-if=!passwordResetSuccess -->
          <div v-else>
            <h2>Email Sent</h2>
            <p>check your email for a link to reset your password</p>
            <button
              @click="togglePasswordReset()"
              class="btn btn-light"
            >Back to login</button>
          </div><!-- /v-else -->
        </form>

        <!-- Error Message -->
        <div
          v-if="errorMsg !== ''"
          class="mt-2"
        >
          <p class="text-danger">{{ errorMsg }}</p>
        </div><!-- error-msg -->

      </div><!-- /.signup-form -->
    </div><!-- /.container -->
  </div><!-- /#login -->
</template>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        email: '',
        password: ''
      },
      passwordForm: {
        email: ''
      },
      showLoginForm: true,
      showForgotPassword: false,
      passwordResetSuccess: false,
      performingRequest: false,
      errorMsg: ''
    }
  },
  created () {
    if (this.$route.params.mode === 'create') {
      this.showLoginForm = false
    }
    if (this.$route.params.mode === 'reset') {
      this.showLoginForm = false
      this.showForgotPassword = true
    }
  },
  methods: {
    toggleForm () {
      this.errorMsg = ''
      this.showLoginForm = !this.showLoginForm
    },
    togglePasswordReset () {
      if (this.showForgotPassword) {
        this.showLoginForm = true
        this.showForgotPassword = false
        this.passwordResetSuccess = false
      } else {
        this.showLoginForm = false
        this.showForgotPassword = true
      }
    },
    login () {
      this.performingRequest = true
      fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(user => {
        this.$store.commit('setCurrentUser', user.user)
        this.$store.dispatch('fetchUserProfile')
        this.performingRequest = false
        this.$router.push('/a')
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.errorMsg = err.message
      })
    },
    signup () {
      this.performingRequest = true
      fb.auth.createUserWithEmailAndPassword(this.signupForm.email, this.signupForm.password).then(user => {
        this.$store.commit('setCurrentUser', user.user)
        // create user obj
        fb.usersCollection.doc(user.user.uid).set({
          createdOn: new Date(),
          email: this.signupForm.email,
          emailNotifications: true
        }).then(() => {
          this.$store.dispatch('fetchUserProfile')
          this.performingRequest = false
          this.$router.push('/a')
        }).catch(err => {
          console.log(err)
          this.performingRequest = false
          this.errorMsg = err.message
        })
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.errorMsg = err.message
      })
    },
    resetPassword () {
      this.performingRequest = true
      fb.auth.sendPasswordResetEmail(this.passwordForm.email).then(() => {
        this.performingRequest = false
        this.passwordResetSuccess = true
        this.passwordForm.email = ''
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.errorMsg = err.message
      })
    }
  }
}
</script>

<style scoped>
.login-form {
  color: black;
  max-width: 500px;
  margin: auto;
  padding: 30px;
  border: solid black 1px;
}
</style>
