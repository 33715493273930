<template>
  <div id="revisions">
    <!-- Revisions drawer -->
    <b-card
      id="revisionsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.revisions.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="closeRevisions()"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">Revisions</h4>
      </template>
      <!-- REVISIONS -->
      <div class="list-group">
        <div class="list-container">
          <div class="list-group-item mb-2">
            <small><strong>CURRENT VERSION</strong><br>Updated: {{ this.$store.state.dataPoint.updatedOn | formatDate }}</small>
            <p class="mb-1">{{ this.$store.state.dataPoint.revisionMessage }}</p>
          </div><!-- /.list-group-item -->
        </div>
        <div
          v-for="(revision, i) in $store.state.revisions"
          v-bind:key="i"
          class="list-container"
        >
          <div class="list-group-item mb-2">
            <small>[{{ i + 1 }}] Updated: {{ revision.updatedOn | formatDate }}</small>
            <p class="mb-1">{{ revision.revisionMessage }}</p>
            <b-btn
              variant="dark"
              size="sm"
              @click="preview(revision)"
            >
              <i class="fas fa-eye"></i> View
            </b-btn>
            <b-btn
              variant="dark"
              size="sm ml-1"
              @click="confirmRevert(revision)"
            >
              <i class="fas fa-history"></i> Revert
            </b-btn>
          </div><!-- /.list-group-item -->
        </div><!-- /.list-container -->
      </div><!-- /.list-group -->
    </b-card>
    <Confirm
      v-if="promptConfirmRevert"
      text="Are you sure you want to revert to this version of the datapoint? A new revision will also be created using the current datapoint."
      v-on:confirmed="revert($event)"
    />
  </div><!-- /#revisions -->
</template>

<script>
import Confirm from '../Confirm'
import moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  data () {
    return {
      promptConfirmRevert: false
    }
  },
  computed: {
    revision: {
      get () {
        return this.$store.state.revision
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'revision', value: value })
      }
    },
    revisions: {
      get () {
        return this.$store.state.revisions
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'revisions', value: value })
      }
    },
    dataPoint: {
      get () {
        return this.$store.state.dataPoint
      },
      set (value) {
        this.$store.commit('setDataPoint', value)
      }
    },
    collector: {
      get () {
        return this.$store.state.collector
      },
      set (value) {
        this.$store.commit('setCollector', value)
      }
    }
  },
  components: {
    Confirm
  },
  created () {
    console.log('Loaded Revisions Drawer')
    this.revisions = []
    fb.collectorsCollection.doc(this.$store.state.collector.id).collection('dataPoints').doc(this.$store.state.dataPoint.id).collection('revisions').orderBy('updatedOn', 'desc').limit(100).get().then(revisionsDocs => {
      revisionsDocs.forEach(revisionDoc => {
        const revisionData = revisionDoc.data()
        revisionData.id = revisionDoc.id
        this.revisions.push(revisionData)
      })
    }).catch(err => {
      console.log(err.message)
    })
  },
  methods: {
    closeRevisions () {
      this.$store.commit('hideDrawer', 'revisions')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'dataPointDetails')
      }, 300)
    },
    preview (revision) {
      this.revision = revision
      this.$store.commit('hideDrawer', 'revisions')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'previewRevision')
      }, 300)
    },
    confirmRevert (revision) {
      this.revision = revision
      this.promptConfirmRevert = true
    },
    revert (confirmed) {
      this.promptConfirmRevert = false
      if (confirmed) {
        console.log('Revert')
        console.log(this.revision)
        console.log(this.collector)
        console.log(this.dataPoint)
        // Create a revision with the current doc
        fb.collectorsCollection.doc(this.collector.id).collection('dataPoints').doc(this.dataPoint.id).get().then(dataPointDoc => {
          const dataPointData = dataPointDoc.data()
          dataPointData.id = dataPointDoc.id
          return fb.collectorsCollection.doc(this.collector.id).collection('dataPoints').doc(this.dataPoint.id).collection('revisions').add(dataPointData)
        }).then(() => {
          this.revision.updatedOn = new Date()
          this.revision.updatedBy = this.$store.state.currentUser.uid
          this.revision.revisionMessage = 'Reverted to previous version: ' + this.revision.revisionMessage
          return fb.collectorsCollection.doc(this.collector.id).collection('dataPoints').doc(this.dataPoint.id).update(this.revision)
        }).then(() => {
          // Mark the collector with a new updatedOn date. Since editing something old, recompile as well
          return fb.collectorsCollection.doc(this.collector.id).update({
            updatedOn: new Date(),
            reCompile: true
          })
        }).then(() => {
          this.dataPoint = this.revision
          this.$store.commit('setAlert', { active: true, message: 'Data Point Updated! Recompiling map now...', level: 'alert-dark' })
          this.$store.commit('hideDrawer', 'revisions')
          return this.$store.dispatch('removeAllLayers')
        }).then(() => {
          // setTimeout(() => {
          const dataset = this.$store.state.dataset
          return this.$store.dispatch('mapDataset', dataset)
          // }, 4000)
        }).then(() => {
          let newData = true
          const self = this
          fb.datasetsCollection.doc(self.$store.state.dataset.id).onSnapshot(snapshot => {
            const dataset = self.$store.state.dataset
            const datasetDoc = snapshot.data()
            datasetDoc.id = snapshot.id
            const filePath = dataset.file.geoJsonPath || ''
            const newFilePath = datasetDoc.file.geoJsonPath
            // Only rerender if new geoJson file
            if (filePath !== newFilePath && newData === true) {
              newData = false
              self.$store.commit('setDataset', datasetDoc)
              setTimeout(() => {
                self.$store.commit('setAlert', { active: false })
                self.$store.dispatch('removeAllLayers')
                self.$store.dispatch('mapDataset', datasetDoc)
              }, 1000)
            }
            // this.$store.commit('hideDrawer', 'collector')
          })
        }).catch(err => {
          console.log(err)
        })
      } else {
        console.log('Cancelled')
      }
    }
  },
  filters: {
    formatDate (val) {
      if (!val) { return '-' }
      const date = val.toDate()
      return moment(date).fromNow()
      // return moment(date).format('MM/DD/YY')
    }
  }
}
</script>
