<template>
  <div id="collectors">
    <!-- Collectors drawer -->
    <b-card
      id="collectorsCard"
      class="blockDrawer large"
      :class="$store.state.drawer.collectors.position"
    >
      <template v-slot:header>
        <a
          class="close clickable"
          @click="$store.commit('hideDrawer', 'collectors')"
        >
          <i class="far fa-times"></i>
        </a>
        <h4 class="card-title">Data Collectors</h4>
        <b-btn
          v-if="$store.state.role == 'Administrator' || $store.state.role == 'Editor'"
          @click="createCollector()"
          class="mb-2 mr-1"
          variant="dark"
        >
          <i class="far fa-plus"></i> New Collector
        </b-btn>
        <b-btn
          v-if="$store.state.role == 'Administrator' || $store.state.role == 'Editor'"
          @click="toggleEditCollectors()"
          class="mb-2"
          variant="dark"
        >
          <i class="far fa-wrench"></i> Edit Collectors
        </b-btn>
      </template>

      <!-- COLLECTORS -->
      <div v-if="$store.state.collectors.length > 0" class="row ml-0">
        <div
          v-for="(collector, i) in $store.state.collectors"
          v-bind:key="i"
          class="col-6 p-2"
        >
          <div class="card h-100 p-2 tile clickable">
            <div
              class="h-100"
              @click="collect(collector)"
            >
              <p
                class="mb-1 clickable"
              ><strong>{{ collector.name }}</strong></p>
              <!-- <p
                class="mb-1 clickable"
              >{{ collector.description }}</p> -->
            </div>
            <button
              v-if="editCollectors"
              @click="editCollector(collector)"
              class="btn btn-light mt-2"
            >
              <i class="fas fa-cog clickable"></i> Edit
            </button>
          </div><!-- -->
        </div><!-- /.col-6 -->
      </div><!-- /.list-group -->
    </b-card>
    <!-- CREATE COLLECTOR MODAL -->
    <CreateCollector
      v-if="$store.state.display.modal.createCollector == true"
    />
    <!-- COLLECTOR FORM -->
    <!-- <Collector /> -->
  </div><!-- /#collectors -->
</template>

<script>
import CreateCollector from './CreateCollector'
// import Collector from './Collector'
// import firebase from 'firebase'

export default {
  data () {
    return {
      editCollectors: false
    }
  },
  components: {
    CreateCollector
    // Collector
  },
  computed: {
    collector: {
      get () {
        return this.$store.state.collector
      },
      set (value) {
        this.$store.commit('setCollector', value)
      }
    }
  },
  methods: {
    // closeCollectors () {
    //   // this.editCollectors = false
    //   // this.$store.commit('setDrawer', { name: 'collectors', position: 'out' })
    //   this.$store.commit('hideDrawer', 'collectors')
    // },
    toggleEditCollectors () {
      if (this.editCollectors === true) {
        this.editCollectors = false
      } else {
        this.editCollectors = true
      }
    },
    editCollector (collector) {
      this.collector = collector
      this.$store.commit('setEditMode', true)
      this.$store.commit('toggleModal', 'createCollector')
    },
    createCollector () {
      this.collector = { fields: [] }
      this.$store.commit('setEditMode', false)
      this.$store.commit('toggleModal', 'createCollector')
    },
    collect (collector) {
      this.$store.commit('setEditMode', false)
      this.$store.commit('hideDrawer', 'collectors')
      this.$store.dispatch('removeAllLayers')
      this.$store.dispatch('collect', collector)
      // this.$store.commit('setStateProperty', { property: 'dataPoint', value: {} })
      // this.collector = collector
      // this.collector.collecting = true
      // const map = this.$store.state.mapObject
      // // CLICK TO ADD MARKER
      // this.collector.selectMarker = null
      // map.on('click', e => {
      //   if (this.collector.collecting) {
      //     if (this.collector.selectMarker != null) {
      //       this.collector.selectMarker.remove()
      //     }
      //     const markerOptions = e
      //     markerOptions.color = 'orange'
      //     // markerOptions.draggable = true
      //     this.collector.selectMarker = new mapboxgl.Marker(markerOptions) // eslint-disable-line
      //       .setLngLat(e.lngLat)
      //       .addTo(map)
      //
      //     this.collector.selectedLocation = new firebase.firestore.GeoPoint(e.lngLat.lat, e.lngLat.lng)
      //   }
      // })
      // this.$store.commit('setDrawer', { name: 'collector', position: 'in' })
      // setTimeout(function () {
      //   const geolocateButton = document.getElementsByClassName('mapboxgl-ctrl-geolocate')
      //   geolocateButton[0].click()
      // }, 300)
    }
  }
}
</script>

<style scoped>
/* #collectorsToggle {
  position: fixed;
  z-index: 1;
  right: 10px;
  bottom: 50px;
} */

#datasetsCard {
  overflow-y: scroll;
}

.badge.clickable:hover {
  box-shadow: 1px 2px 4px rgba(0,0,0,0.5);
}
</style>
